import React, { ReactText, SyntheticEvent } from 'react';
import { RangeSlider } from '@driverup/ui';
import { FiDollarSign as DollarSignIcon } from 'react-icons/fi';
import { Header } from './Header';
import { formatNumber } from '@driverup/util';

type RangeSelectorSectionProps = {
  inputValue: number;
  headerText: ReactText;
  min: number;
  max: number;
  onChangeRangeSlider?(e: SyntheticEvent): void;
  step?: number;
  displayRangeSlider?: boolean;
};

export const RangeSelectorSection = ({
  inputValue,
  headerText,
  min,
  max,
  onChangeRangeSlider,
  step = 0,
  displayRangeSlider = true,
}: RangeSelectorSectionProps): React.ReactElement => {
  const inputValueFormatted = formatNumber(inputValue);
  return (
    <div className="flex flex-col sm:flex-row">
      <div className="w-full">
        <div className="flex flex-col sm:flex-row justify-between">
          <div>
            <Header>{headerText}</Header>
          </div>
          <div className="p-2 shadow-sm rounded text-gray-600 lg:w-1/4">
            <div className="flex justify-center items-center">
              <DollarSignIcon className="text-base" />
              <span>{inputValueFormatted}</span>
            </div>
          </div>
        </div>
        {displayRangeSlider && (
          <div className="mt-2">
            <RangeSlider
              min={min}
              max={max}
              step={step}
              onChange={onChangeRangeSlider}
              value={inputValue}
            />
          </div>
        )}
      </div>
    </div>
  );
};
