import React, { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface PillProps {
  children: ReactNode;
  className?: string;
}

export const Pill = ({ children, className }: PillProps) => (
  <span
    className={`mr-1 mb-1 inline-block bg-primary-100 rounded-full px-3 py-1 text-xs text-primary-500 ${className}`}
  >
    {children}
  </span>
);

export default Pill;
