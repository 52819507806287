import { publicRequest } from '../amplify-api';
import * as queries from '../queries';
import * as Types from '../types';

export async function getDealerByID(id) {
  const res: Types.GetDealerQuery = await publicRequest({
    query: queries.getDealer,
    variables: {
      id,
    },
  });

  return res.getDealer;
}
