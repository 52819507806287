import React, { ReactNode, ReactText, SyntheticEvent } from 'react';
import { FaChevronDown as DownIcon } from 'react-icons/fa';
import { isEmpty } from '@driverup/util';
// import classNames from "classnames";

// TODO: Use Downshift useSelect https://github.com/downshift-js/downshift/tree/master/src/hooks/useSelect

export type OptionData = {
  value: ReactText;
  text?: string;
};
type SelectProps = {
  variant?: string;
  value?: ReactText;
  label?: string;
  optionData?: OptionData[];
  children?: ReactNode;
  onChange?(e: SyntheticEvent): void;
};

export const Select = ({
  variant,
  label,
  children,
  value,
  optionData,
  onChange,
}: SelectProps) => {
  const renderOptions = optionData?.map(({ value, text }) => (
    <option key={value} value={value}>
      {text ? text : value}
    </option>
  ));

  return (
    <div className="w-full">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        {label}
      </label>
      <div className="relative">
        <select
          value={value}
          onChange={onChange}
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          id="grid-state"
        >
          {!isEmpty(renderOptions) ? renderOptions : children}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
          <DownIcon className="w-3" />
        </div>
      </div>
    </div>
  );
};

export default Select;
