import React, { useState } from 'react';
import { Input, Button } from '@driverup/ui';
import { FiLock as LockIcon } from 'react-icons/fi';

function FormGroup({
  placeholder,
  id = placeholder,
  labelText,
  note,
  ...inputProps
}) {
  return (
    <div className="mb-6">
      <label
        htmlFor={id}
        className="uppercase text-sm text-gray-700 tracking-wider"
      >
        {labelText}
      </label>
      <Input placeholder={placeholder} {...inputProps} />
      {note && <div className="mt-1 text-xs text-gray-600">{note}</div>}
    </div>
  );
}

const View = ({ onSubmit }) => {
  // For Defi demo API, dob is the only important item.
  const [dob, setDob] = useState('');
  const handleDobChange = (e) => setDob(e?.target?.value);

  return (
    <div>
      <div className="md:w-1/3 mx-auto shadow-lg rounded-lg p-6">
        <h2 className="font-bold text-2xl text-gray-800 mb-6">
          Credit Application Form
        </h2>
        <FormGroup placeholder="Enter first name" labelText="First name" />
        <FormGroup placeholder="Enter last name" labelText="Last name" />
        <FormGroup
          placeholder="YYYY-MM-DD"
          labelText="Date of birth"
          value={dob}
          onChange={handleDobChange}
        />
        <FormGroup placeholder="(   ) ___-____ " labelText="Mobile number" />
        <FormGroup
          labelText="Social security number"
          placeholder="___-__-____"
          note={
            <div className="flex">
              <LockIcon className="text-lg mr-2" />
              <span>
                Don't worry, the site is secured. We take privacy seriously.
              </span>
            </div>
          }
        />
        <Button onClick={onSubmit}>Submit Application</Button>
      </div>
    </div>
  );
};

export default View;
