import { useState } from 'react';

type useDynamoPaginationReturnValue = {
  nextToken: string | undefined;
  nextNextToken: string | undefined;
  previousTokens: string[];
  handleQuerySuccess(data: any): void;
  fetchNextPage(): void;
  fetchPreviousPage(): void;
};

const useDynamoPagination = (): useDynamoPaginationReturnValue => {
  // State to hold pagination
  //  From: https://dev.to/onlybakam/implementing-pagination-with-aws-appsync-5d08
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [nextNextToken, setNextNextToken] = useState<string | undefined>();
  const [previousTokens, setPreviousTokens] = useState<string[]>([]);

  const handleQuerySuccess = (data) => {
    setNextNextToken(data.nextToken);
  };

  const fetchNextPage = () => {
    setPreviousTokens((prev) => [...prev, nextToken]);
    setNextToken(nextNextToken);
    setNextNextToken(undefined);
  };

  const fetchPreviousPage = () => {
    const next = previousTokens[previousTokens.length - 1];
    setPreviousTokens((prev) => [...prev.slice(0, prev.length - 1)]);
    setNextNextToken(undefined);
    setNextToken(next);
  };

  return {
    nextToken,
    nextNextToken,
    previousTokens,
    handleQuerySuccess,
    fetchNextPage,
    fetchPreviousPage,
  };
};

export { useDynamoPagination };
