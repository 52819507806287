import React, { useState } from 'react';
import { lengthOfLoanData, Car } from '@driverup/constants';
import { formatCar, calculateLoanMonthlyPayment } from '@driverup/util';
import { Calendar } from '@driverup/ui';
import { FiLock as LockIcon } from 'react-icons/fi';
import NumberFormat from 'react-number-format';
import {
  useOutsideClick,
  Input,
  Button,
  Box,
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
// import { useHistory } from 'react-router';
import { Configure, connectStateResults } from 'react-instantsearch-dom';

type FormGroupProps = {
  reactFormHookConfig?: any; // React hook form
  placeholder?: string;
  isRequired?: boolean;
  id?: string;
  labelText?: string;
  name: keyof FormValues;
  helperText?: React.ReactNode;
  value?: string;
  defaultValue?: string;
  type?: 'input' | 'currency' | 'datePicker' | 'select' | 'ssn' | 'phone';
  onChange?: (e: React.SyntheticEvent) => void;
};

function FormGroup({
  placeholder,
  isRequired = false,
  labelText,
  // name = labelText,
  name,
  // id = name,
  helperText,
  type = 'input',
  reactFormHookConfig,
  value,
  defaultValue,
}: FormGroupProps) {
  const {
    register,
    errors,
    car,
    rate = 0.0125,
    getValues,
    control,
  } = reactFormHookConfig;
  const validationObj =
    name === 'monthlyIncome'
      ? {
          required: 'This is required',
          validate: (monthlyIncome) => {
            const cashDown = getValues('cashDown');
            const term = getValues('term');
            const monthlyPayment = calculateLoanMonthlyPayment({
              principal: car.priceInt - cashDown,
              interestRatePerPeriod: rate,
              numberOfPeriods: term,
            });
            const ratio = monthlyPayment / monthlyIncome;
            return ratio < 0.18
              ? true
              : "Sorry but this monthly income amount isn't quite enough needed for the vehicle you selected.  Would you like to select a lower priced vehicle, or increase your down payment?";
          },
        }
      : {
          required: 'This is required',
          maxLength: { value: 50, message: 'Must be less than 50 letters' },
        };
  const errorMessage = errors?.[name]?.message;
  const variant = 'filled';

  return (
    <FormControl isInvalid={!!errorMessage} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{labelText}</FormLabel>
      {type === 'select' && (
        <MySelect
          variant={variant}
          name={name}
          reactFormHookConfig={reactFormHookConfig}
          defaultValue={defaultValue}
        />
      )}
      {type === 'input' && (
        <Input
          variant={variant}
          placeholder={placeholder}
          name={name}
          defaultValue={defaultValue}
          {...register(name, validationObj)}
        />
      )}
      {type === 'currency' && (
        <Controller
          name={name}
          defaultValue={defaultValue}
          rules={validationObj}
          control={control}
          render={({ field }) => {
            return (
              <NumberFormat
                defaultValue={defaultValue}
                customInput={Input}
                decimalScale={0}
                allowLeadingZeros={false}
                allowNegative={false}
                isNumericString={true}
                prefix={'$'}
                thousandSeparator={true}
                placeholder={placeholder}
                variant={variant}
                onValueChange={(c) => {
                  field.onChange(c.value);
                }}
              />
            );
          }}
        />
      )}{' '}
      {type === 'phone' && (
        <Controller
          name={name}
          defaultValue={defaultValue}
          rules={validationObj}
          control={control}
          render={({ field }) => {
            return (
              <NumberFormat
                defaultValue={defaultValue}
                customInput={Input}
                format="(###) ###-####"
                placeholder={placeholder}
                variant={variant}
                onValueChange={(c) => {
                  field.onChange(c.value);
                }}
              />
            );
          }}
        />
      )}
      {type === 'ssn' && (
        <Controller
          name={name}
          defaultValue={defaultValue}
          rules={validationObj}
          control={control}
          render={({ field }) => {
            return (
              <NumberFormat
                defaultValue={defaultValue}
                customInput={Input}
                format="###-##-####"
                placeholder={placeholder}
                variant={variant}
                onValueChange={(c) => {
                  field.onChange(c.value);
                }}
              />
            );
          }}
        />
      )}
      {type === 'datePicker' && (
        <MyDatePicker
          variant={variant}
          name={name}
          reactFormHookConfig={reactFormHookConfig}
        />
      )}
      <FormHelperText>{helperText}</FormHelperText>
      <FormErrorMessage>{errors?.[name]?.message}</FormErrorMessage>
    </FormControl>
  );
}

function FormSectionHeader({ text }: { text: string }) {
  return (
    <p className="uppercase text-gray-700 font-bold text-sm mt-6 mb-4">
      {text}
    </p>
  );
}

function MySelect({ variant, name, defaultValue, reactFormHookConfig }) {
  const [term, setTerm] = React.useState(defaultValue);
  const { register } = reactFormHookConfig;
  const validationObj = {
    required: 'This is required',
  };
  const handleTermChange = (e) => {
    setTerm(e.target.value);
  };

  return (
    <Select
      placeholder="Select term"
      value={term}
      {...register(name, validationObj)}
      variant={variant}
      onChange={handleTermChange}
    >
      {lengthOfLoanData.map((data) => (
        <option key={data.value} value={data.value}>
          {data.text}
        </option>
      ))}
    </Select>
  );
}

function MyDatePicker({ variant, name, type = 'date', reactFormHookConfig }) {
  const ref = React.useRef();
  const { control } = reactFormHookConfig;
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<Date>();
  useOutsideClick({
    ref: ref,
    handler: () => setOpen(false),
  });

  const isDateType = type === 'date';
  const dateFormat = isDateType ? 'yyyy-MM-dd' : 'MM-dd-yyyy';

  const formattedDateStr = selectedDate ? format(selectedDate, dateFormat) : '';

  function handleSelect(date: Date) {
    setSelectedDate(date); // native Date object
    setOpen(false);
  }

  return (
    <Box position="relative" ref={ref}>
      <Input
        type={type}
        name={name}
        value={formattedDateStr}
        placeholder={'mm-dd-yyyy'}
        variant={variant}
        onKeyDown={(e) => {
          e.preventDefault(); // Prevent user from typing into "date" input field
        }}
        onChange={(e) => {
          e.preventDefault(); // Prevent user from typing into "text" input field
        }}
        onClick={(e) => {
          e.preventDefault(); // Prevent browser default date popping up
          setOpen(true);
        }}
      />

      {/* https://react-hook-form.com/api/usecontroller/controller */}
      <Controller
        control={control}
        rules={{ required: 'This is required' }}
        name={name}
        render={({ field: { onChange } }) =>
          isOpen && (
            <Box position="absolute" zIndex="1" shadow="lg">
              <Calendar
                date={selectedDate}
                onChange={(e: Date) => {
                  const isoStr = e.toISOString();
                  handleSelect(e);
                  onChange(isoStr);
                }}
                maxDate={new Date()}
              />
            </Box>
          )
        }
      />
    </Box>
  );
}

type LoanApplicationProps = {
  onSubmit(loanAppPayload: any): void;
  carId: string;
  term: string;
  cashDown: string;
  searchResults: any;
  match: any;
  searching: any;
};

type FormValues = {
  dob: string; // e.g. 1986-02-25T02:13:59.062Z
  ssn: string;
  term: string;
  mobileNumber: string; // For DU (not Defi)
  firstName: string;
  lastName: string;
  cashDown: string;
  city: string;
  state: string;
  zip: string;
  carId: string;
  addressLine1: string;
  employerName: string;
  employmentPosition: string;
  monthlyIncome: string;
};

/**
 * Takes in '123 N Park Dr' and returns
 * streetNumber - 123
 * streetName - N Park Dr
 * @param addressLine1
 * @returns
 */
function getAddressParts(
  addressLine1
): { streetName: string; streetNumber: string } {
  if (addressLine1) {
    const delimeter = ' ';
    const addressLine1Array = addressLine1.trim().split(delimeter);
    const streetNumber = addressLine1Array[0];
    const streetName = addressLine1Array.slice(1).join(delimeter);

    return { streetName, streetNumber };
  }
}

function getCarFromAlgoliaHits({ carId, hits }) {
  return hits?.find(({ objectID }) => objectID === carId);
}

//////////////////////////////////////////////////////////
//
//    Main Component
//
//////////////////////////////////////////////////////////

const LoanApplicationView: React.FC<LoanApplicationProps> = ({
  onSubmit,
  carId,
  term = '84',
  cashDown = '0',
  searchResults,
  match,
  searching,
}) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    getValues,
    // watch,
  } = useForm<FormValues>();
  // console.log(watch());

  const hits = searchResults?.hits;
  const carRaw = getCarFromAlgoliaHits({ carId, hits });
  const car: Car = formatCar(carRaw);

  const reactFormHookConfig = {
    register,
    errors,
    control,
    car,
    getValues,
  };

  return (
    <>
      <Configure filters={`objectID:${carId}`} />
      <form
        onSubmit={handleSubmit((data) => {
          const { addressLine1, state, city, zip, ...rest } = data;
          const { streetName, streetNumber } =
            getAddressParts(addressLine1) ?? {};

          const payload = {
            input: {
              ...rest,
              carId,
              address: {
                addressLine1,
                streetNumber,
                streetName,
                state,
                city,
                zip,
              },
            },
          };
          onSubmit(payload);
        })}
      >
        <div className="md:w-1/2 lg:w-1/3 mx-auto shadow-lg rounded-lg p-6 mb-16">
          <h2 className="font-bold text-2xl text-gray-800 mb-6">Application</h2>

          <FormSectionHeader text="Buyer Info" />

          <FormGroup
            placeholder="Enter first name"
            labelText="First name"
            name="firstName"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            placeholder="Enter last name"
            labelText="Last name"
            name="lastName"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            type="datePicker"
            labelText="Date of birth"
            name="dob"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            type="phone"
            placeholder="(   ) ___-____ "
            labelText="Mobile number"
            name="mobileNumber"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            type="ssn"
            placeholder="___-__-____"
            labelText="Social security number"
            name="ssn"
            helperText={
              <div className="flex">
                <LockIcon className="text-lg mr-2" />
                <span>
                  Don't worry, the site is secured. We take privacy seriously.
                </span>
              </div>
            }
            reactFormHookConfig={reactFormHookConfig}
          />

          <FormSectionHeader text="Buyer Address" />

          <FormGroup
            placeholder="e.g. 61674 N Parkridge"
            labelText="Address"
            name="addressLine1"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            placeholder="e.g. Sturgis"
            labelText="City"
            name="city"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            placeholder="e.g. MI"
            labelText="State"
            name="state"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            placeholder="e.g. 49091-9328"
            labelText="Zip"
            name="zip"
            reactFormHookConfig={reactFormHookConfig}
          />

          <FormSectionHeader text="Buyer Income" />

          <FormGroup
            placeholder="Employer name"
            labelText="Employer"
            name="employerName"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            placeholder="Your position"
            labelText="Position"
            name="employmentPosition"
            reactFormHookConfig={reactFormHookConfig}
          />
          <FormGroup
            type="currency"
            placeholder="$5000"
            labelText="Monthly Income"
            name="monthlyIncome"
            reactFormHookConfig={reactFormHookConfig}
          />

          <FormSectionHeader text="Finance Info" />

          <FormGroup
            type="select"
            placeholder="e.g. 60"
            labelText="Term"
            name="term"
            defaultValue={term}
            reactFormHookConfig={reactFormHookConfig}
          />

          <FormGroup
            type="currency"
            labelText="Cash Down"
            name="cashDown"
            reactFormHookConfig={reactFormHookConfig}
            defaultValue={cashDown}
          />

          <Button colorScheme="blue" type="submit" mt="4">
            Submit Application
          </Button>
        </div>
      </form>
    </>
  );
};

export default connectStateResults(LoanApplicationView);
