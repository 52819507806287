import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@driverup/constants';
import classNames from 'classnames';
import LoginButton from '../LoginButton/LoginButton';
import UserNavbarButton from './components/UserNavbarButton/UserNavbarButton';
import { useDealerApp } from '../hooks/useDealerApp';
import { useAuth } from '../Auth';
import DriverupLogo from '../driverup-logo/DriverupLogo';
import CloseButton from '../close-button/CloseButton';
import { FiMenu as HamburgerMenuIcon } from 'react-icons/fi';
import { userRoutes } from './constants';

// Constants
type RouteType = {
  text: string;
  to: string;
};
type NavBarRouteType = {
  buyer: RouteType[];
  dealer: RouteType[];
};
const navBarRoutes: NavBarRouteType = {
  dealer: [
    {
      text: 'Buyer Site',
      to: ROUTES.HOME,
    },
  ],
  buyer: [
    {
      text: 'Find Car',
      to: ROUTES.CARS,
    },
    // {
    //   text: 'Get Pre-Qualified',
    //   to: ROUTES.PRE_QUALIFY_APPLICATION,
    // },
  ],
};

function MyNavLink({
  to = '',
  children,
  isActive = false,
  onClick = () => {},
  ...rest
}) {
  const baseCss = 'py-2 px-6 flex hover:text-blue-500 text-base';
  const styles = classNames(baseCss);
  return (
    <NavLink
      to={to}
      className={styles}
      activeClassName="text-primary-500"
      onClick={onClick}
      {...rest}
    >
      {children}
    </NavLink>
  );
}

type NavLinksProps = {
  routes: RouteType[];
};
function NavLinks({ routes }: NavLinksProps) {
  return (
    <React.Fragment>
      {routes.map((route) => {
        return (
          <MyNavLink key={route.to} to={route.to}>
            {route.text}
          </MyNavLink>
        );
      })}
    </React.Fragment>
  );
}

function DesktopNavLinks() {
  const { isAuthenticated } = useAuth();
  const { isDealerApp } = useDealerApp();

  return (
    <nav className="text-black text-lg hidden md:flex items-center">
      {isDealerApp ? (
        <NavLinks routes={navBarRoutes.dealer} />
      ) : (
        <NavLinks routes={navBarRoutes.buyer} />
      )}
      {isAuthenticated ? <UserNavbarButton /> : <LoginButton />}
    </nav>
  );
}

function HamburgerButton({ onClick }) {
  return (
    <button onClick={onClick} className="md:hidden flex flex-col">
      <HamburgerMenuIcon className="w-6 h-6" />
    </button>
  );
}

type MobileNavlinksProps = {
  onClick?(): void;
} & NavLinksProps;
function MobileNavLinks({ routes, onClick }: MobileNavlinksProps) {
  return (
    <React.Fragment>
      {routes.map((route) => {
        return (
          <NavLink
            key={route.to}
            to={route.to}
            onClick={onClick}
            activeClassName="text-primary-500 bg-gray-100"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100"
            role="menuitem"
          >
            {route.text}
          </NavLink>
        );
      })}
    </React.Fragment>
  );
}

function MenuContent({
  userRoutes,
  navBarRoutes,
  email,
  handleClose,
  isAuthenticated,
}) {
  return (
    <React.Fragment>
      {isAuthenticated && (
        <div className="border-b">
          <div className="text-sm p-3 text-gray-500 truncate">{email}</div>
          <MobileNavLinks routes={userRoutes} onClick={handleClose} />
        </div>
      )}
      <MobileNavLinks routes={navBarRoutes} onClick={handleClose} />
    </React.Fragment>
  );
}

function MobileNavLinksToggle() {
  const { isAuthenticated, handleLogout, handleLogin, user } = useAuth();
  // console.log('In NavBar.tsx ', { isAuthenticated });
  const { isDealerApp } = useDealerApp();
  const [open, setOpen] = React.useState(false);
  const email = user?.email;
  const authButtonCss =
    'block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-100 hover:bg-gray-200';

  function handleClose() {
    setOpen(false);
  }
  function handleToggle() {
    setOpen(!open);
  }

  return (
    <React.Fragment>
      <HamburgerButton onClick={handleToggle} />
      {open && (
        <div className="z-10 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md  bg-white overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <DriverupLogo onClick={handleClose} />
              <div className="-mr-2">
                <CloseButton onClick={handleClose} />
              </div>
            </div>
            <div
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <div className="px-2 pt-2 pb-3 space-y-1" role="none">
                {isDealerApp ? (
                  <MenuContent
                    isAuthenticated={isAuthenticated}
                    email={email}
                    handleClose={handleClose}
                    userRoutes={userRoutes.dealer}
                    navBarRoutes={navBarRoutes.dealer}
                  />
                ) : (
                  <MenuContent
                    isAuthenticated={isAuthenticated}
                    email={email}
                    handleClose={handleClose}
                    userRoutes={userRoutes.buyer}
                    navBarRoutes={navBarRoutes.buyer}
                  />
                )}
              </div>
              <div role="none">
                {isAuthenticated ? (
                  <button
                    className={authButtonCss}
                    role="menuitem"
                    onClick={handleLogout}
                  >
                    Log out
                  </button>
                ) : (
                  <button
                    className={authButtonCss}
                    role="menuitem"
                    onClick={handleLogin}
                  >
                    Log in
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export function NavBar(): ReactElement {
  return (
    <header className="h-24 sm:h-32 flex items-center">
      <div className="container mx-auto px-6 sm:px-12 flex items-center justify-between">
        <DriverupLogo />
        <div className="flex items-center">
          <DesktopNavLinks />

          <MobileNavLinksToggle />
        </div>
      </div>
    </header>
  );
}

export default NavBar;
