import React from 'react';
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
} from '@aws-amplify/ui-react';

export function AmplifyLoginPage(): JSX.Element {
  return (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          { type: 'email', required: true, inputProps: { autofocus: true } },
          { type: 'password', required: true },
        ]}
      />
    </AmplifyAuthenticator>
  );
}
