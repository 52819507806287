import { createSlice } from '@reduxjs/toolkit';

export const USER_FEATURE_KEY = 'user';

/*
 * Update these interfaces according to your requirements.
 */
export interface UserState {
  email?: string;
  username?: string;
  isAuthenticated?: boolean;
  loadingStatus?: 'not loaded' | 'loading' | 'loaded' | 'error';
}

export const initialUserState: UserState = {
  isAuthenticated: false,
  email: null,
  username: null,
  loadingStatus: 'not loaded',
};

export const userSlice = createSlice({
  name: USER_FEATURE_KEY,
  initialState: initialUserState,
  reducers: {
    add(state, action) {
      const payload = action.payload as UserState;
      state.isAuthenticated = payload.isAuthenticated;
      state.username = payload.username;
      state.email = payload.email;
      state.loadingStatus = 'loaded';
      // return { ...payload };
    },
    reset() {
      return { ...initialUserState };
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;

export const getUserState = (rootState: unknown): UserState =>
  rootState[USER_FEATURE_KEY];
