import React from 'react';
import { useAuth } from '../Auth';

const LoginButton: React.FC = () => {
  const { handleLogin } = useAuth();

  return (
    <div>
      <button
        onClick={handleLogin}
        className="text-blue-600 py-2 px-4 bg-primary-100 font-semibold rounded focus:outline-none"
      >
        Login
      </button>
    </div>
  );
};

export default LoginButton;
