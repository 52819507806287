import React, { ReactElement } from 'react';
import { FiLogOut as LogoutIcon } from 'react-icons/fi';
import classNames from 'classnames';
import { useAuth } from '../../../../../Auth';

type LogoutButtonProps = {
  role?: string;
  className?: string;
};

// IntrinsicAttributes & { children?: ReactNode; }

const LogoutButton = (props: LogoutButtonProps): ReactElement => {
  const { handleLogout } = useAuth();
  const { className, ...rest } = props;
  const css = classNames(className, 'inline-flex items-center border-t w-full');

  return (
    <div {...rest} className={css} onClick={handleLogout}>
      <LogoutIcon className="mr-2" /> Log Out
    </div>
  );
};

export default LogoutButton;
