import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as RRCCarousel } from 'react-responsive-carousel';
import './override-styles.css';

export const Carousel = ({ images }) => {
  return (
    <div>
      <RRCCarousel
        showIndicators={false}
        useKeyboardArrows={true}
        showArrows={true}
      >
        {images?.map((image) => (
          <div key={image}>
            <img src={image} loading="lazy" alt="car" className="rounded-lg" />
          </div>
        ))}
      </RRCCarousel>
    </div>
  );
};

export default Carousel;
