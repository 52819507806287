import React, { useState } from 'react';
import View from './View';
// import { useHistory } from 'react-router-dom';
import { Modal } from '@driverup/ui';
import { ROUTES } from '@driverup/constants';

export const CreditApplication = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  // const history = useHistory();

  return (
    <div>
      {showModal && (
        <Modal
          content={
            'Thank you for submitting your application. We will contact you once processed.'
          }
          primaryButtonText={'Browse Vehicles'}
          primaryToUrl={ROUTES.CARS}
        />
      )}
      <View
        onSubmit={() => {
          setShowModal(true);
        }}
      />
    </div>
  );
};

export default CreditApplication;
