import React from 'react';
import { Button, Carousel, PriceEstimator } from '@driverup/ui';
import { formatCar, isEmpty, shouldUseLocalMockData } from '@driverup/util';
import { ROUTES, Car, lengthOfLoanData } from '@driverup/constants';
import { Configure, connectStateResults } from 'react-instantsearch-dom';
import classNames from 'classnames';

import {
  FiCheckCircle as FeatureIcon,
  FiMapPin as PinIcon,
  FiMail as MailIcon,
  FiPhone as PhoneIcon,
} from 'react-icons/fi';
// import { useHistory } from 'react-router';
import { useHistory } from 'react-router-dom';
import { getDealerByID } from '@du/services';
import { useQuery } from 'react-query';
import { Spinner } from '@chakra-ui/spinner';
import { Center } from '@chakra-ui/layout';

// TODO: Remove mr-2 from last MainFeature el
function MainFeature({ featureKey, featureValue }) {
  return (
    <div className="flex bg-primary-100 rounded-md w-full justify-bretween p-4 mb-2">
      <div className="font-semibold text-primary-800">{featureKey}</div>
      <div className="text-gray-600">{featureValue}</div>
    </div>
  );
}

function Header({ children }) {
  return (
    <h2 className="text-3xl text-center text-gray-800 font-semibold mb-4">
      {children}
    </h2>
  );
}

function CarName({ text }) {
  return (
    <p className="text-gray-800 font-semibold uppercase text-3xl tracking-wide truncate">
      {text}
    </p>
  );
}

function OtherFeatures({ features = [] }) {
  return (
    <div className="flex flex-wrap">
      {features?.map((feature) => (
        <div
          key={feature}
          className="flex w-full sm:w-1/2 lg:w-1/3 items-center p-1"
        >
          <FeatureIcon className="text-gray-600 mr-2" />
          <span className="text-gray-600">{feature}</span>
        </div>
      ))}
    </div>
  );
}

function getCarFromAlgoliaHits({ carId, hits }) {
  return hits?.find(({ objectID }) => objectID === carId);
}

function Section({ children }) {
  return <section className="mb-10">{children}</section>;
}

function SmallHeader({ children }) {
  return (
    <h3 className="uppercase text-gray-600 text-center text-xs font-semibold tracking-wider mb-2">
      {children}
    </h3>
  );
}

interface DealerInfoProps {
  dealerId: string;
}
function DealerInfo({ dealerId }: DealerInfoProps) {
  const flexContainerCss = 'flex items-center text-gray-700 text-sm';
  const iconCss = 'mr-2';
  const query = useQuery(
    'dealers',
    () => getDealerByID(dealerId),
    { enabled: !!dealerId }
    // { retry: false, refetchOnWindowFocus: false, enabled: true } // TODO: Set enabled to true
    // { retry: false, enabled: false } // TODO: Set enabled to true
  );
  const { isLoading, isError, isSuccess, data, refetch, isFetched } = query;

  const dealer = data;
  let renderItem;

  if (isLoading) {
    renderItem = (
      <Center>
        <Spinner />
      </Center>
    );
  } else if (isError) {
    renderItem = (
      <div className="text-center text-sm">Failed to load dealer info</div>
    );
  } else if (!isFetched) {
    <div className="text-center text-sm">Loading dealer info</div>;
  } else {
    renderItem = dealer?.name ? (
      <div>
        <h4 className="text-lg font-semibold">{dealer?.name}</h4>
        <div className={classNames(flexContainerCss)}>
          <PinIcon className={iconCss} />{' '}
          {`${dealer?.address?.city}, ${dealer?.address?.state}`}
        </div>
        <div className={classNames(flexContainerCss, 'cursor-pointer')}>
          <PhoneIcon className={iconCss} />
          <a href={`tel:${dealer?.phoneNumber}`}>{dealer?.phoneNumber}</a>
        </div>
        <div className={classNames(flexContainerCss, 'cursor-pointer')}>
          <MailIcon className={iconCss} />
          <span>{dealer?.email}</span>
        </div>
      </div>
    ) : (
      <div className="text-center text-sm">Dealer info unavailable</div>
    );
  }

  return (
    <div className="mt-10 text-left">
      <SmallHeader>Dealer Info</SmallHeader>
      {renderItem}
    </div>
  );
}

// match comes from react-router-dom
//  https://reactrouter.com/core/api/match
// searchResults and searching come from Algolia instantsearch via the
//  ConnectStateResults
//  (https://www.algolia.com/doc/api-reference/widgets/state-results/react/)
//
function CarDetailsView({ searchResults, match, searching }) {
  const history = useHistory();
  const carId = match?.params?.id;
  const hits = shouldUseLocalMockData()
    ? require('@driverup/constants').hits
    : searchResults?.hits; // TODO: REMOVE DEV CHECK!
  // hits = shouldUseLocalMockData() ? require('@driverup/constants').hits : hits; // TODO: REMOVE DEV CHECK!
  const carRaw = getCarFromAlgoliaHits({ carId, hits });
  const car: Car = formatCar(carRaw);
  const handleNavigationToLoanApplicationPage = (
    term = lengthOfLoanData[0].value,
    cashdown = 0
  ) =>
    history.push(
      `${ROUTES.PRE_QUALIFY_APPLICATION}/${carId}?term=${term}&cashdown=${cashdown}`
    );

  const {
    makeAndYear,
    model,
    trim,
    priceWithCurrency,
    miles,
    photos,
    bodyType,
    intColor,
    extColor,
    vin,
    engine,
    transmission,
    driveType,
    fuelType,
    otherFeatures,
  } = car || {};

  return (
    <div className="container mx-auto px-2">
      {/* Read about <Configure /> here: https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/#configure 
      It triggers a query based on a custom attribute; here we are triggering a query based on a single object ID gotten from the URL param*/}
      <Configure filters={`objectID:${carId}`} />
      <Section>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-9/12 lg:mr-10">
            {searching ? (
              <Center>
                <Spinner size="xl" />
              </Center>
            ) : (
              <Carousel images={photos} />
            )}
          </div>
          <div className="w-full lg:w-3/12">
            <div>
              <div>
                <CarName text={makeAndYear} />
                <CarName text={model} />
              </div>
              <div className="mb-5">
                <div className="text-gray-600">
                  {trim} | {miles}
                </div>
              </div>
              <p className="text-gray-800 text-2xl font-semibold mb-1 tracking-wide text-center">
                {priceWithCurrency}
              </p>
              <Button
                size="large"
                className="w-full max-w-xs mx-auto"
                onClick={() => handleNavigationToLoanApplicationPage()}
              >
                Get Approved
              </Button>
            </div>
            <DealerInfo dealerId={car?.dealerId} />
          </div>
        </div>
      </Section>
      <Section>
        <Header>Car Details</Header>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 md:mr-2">
            <SmallHeader>Basics</SmallHeader>
            <MainFeature featureKey={'Body Type'} featureValue={bodyType} />
            <MainFeature featureKey={'Interior'} featureValue={intColor} />
            <MainFeature featureKey={'Exterior'} featureValue={extColor} />
            <MainFeature featureKey={'VIN'} featureValue={vin} />
          </div>
          <div className="w-full md:w-1/2">
            <SmallHeader>Performance</SmallHeader>
            <MainFeature featureKey={'Engine'} featureValue={engine} />
            <MainFeature
              featureKey={'Transmission'}
              featureValue={transmission}
            />
            <MainFeature featureKey={'Drive Type'} featureValue={driveType} />
            <MainFeature featureKey={'Fuel Type'} featureValue={fuelType} />
          </div>
        </div>
      </Section>
      {!isEmpty(otherFeatures) && (
        <Section>
          <Header>Features</Header>
          <OtherFeatures features={otherFeatures} />
        </Section>
      )}
      <Section>
        <Header>Estimate Financing</Header>
        <PriceEstimator
          car={car}
          onGetStartedClick={handleNavigationToLoanApplicationPage}
        />
      </Section>
    </div>
  );
}

export const CarDetails = connectStateResults(CarDetailsView);
