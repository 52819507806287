import { formatMoney } from '@driverup/util';
import React, { ReactElement } from 'react';

type CurrencyProps = {
  value: number | string;
  locale?: string;
  currency?: string;
  minimumFractionDigits?: number;
};

const Currency = ({
  value,
  locale = 'en-US',
  currency = 'USD',
  minimumFractionDigits = 2,
}: CurrencyProps): ReactElement => {
  const formatted = formatMoney(value, {
    locale,
    currency,
    minimumFractionDigits,
  });
  return <span>{formatted}</span>;
};

export default Currency;
