import React, { ReactElement } from 'react';
// import PropTypes from 'prop-types';
import Downshift from 'downshift';
import {
  FiUser as UserIcon,
  FiChevronDown as ChevronDownIcon,
} from 'react-icons/fi';
import LogoutButton from './components/LogoutButton/LogoutButton';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../Auth';
import { useDealerApp } from '../../../hooks/useDealerApp';
import { RouteType, userRoutes } from '../../constants';

const dropDownItemCss =
  'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer';

function DropDownLinks({ routes }) {
  return routes?.map((route: RouteType) => {
    return (
      <Link
        key={route.to}
        to={route.to}
        className={dropDownItemCss}
        role="menuitem"
      >
        {route.text}
      </Link>
    );
  });
}

export function UserNavbarButton(): ReactElement {
  const { user } = useAuth();
  const { isDealerApp } = useDealerApp();
  const [open, setOpen] = React.useState(false);

  return (
    <Downshift
      isOpen={open}
      onOuterClick={() => {
        setOpen(false);
      }}
    >
      {() => (
        <div
          className="relative"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {/* Drop down Icon/Trigger */}
          <div className="inline-flex items-center cursor-pointer">
            <UserIcon className="w-4" />
            <div className="mx-1">Me</div>
            {/* <img
              src={user?.picture}
              alt="user"
              className="w-8 mx-1 rounded-full"
            /> */}
            <ChevronDownIcon className="w-4" />
          </div>

          {/* Drop down content */}
          {open && (
            <div
              className="z-10 absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <div className="text-sm pl-4 py-1 text-gray-500 truncate">
                {user?.email}
              </div>
              {isDealerApp ? (
                <DropDownLinks routes={userRoutes.dealer} />
              ) : (
                <DropDownLinks routes={userRoutes.buyer} />
              )}
              <LogoutButton className={dropDownItemCss} role="menuitem" />
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
}

// UserNavbarButton.propTypes = {};

export default UserNavbarButton;
