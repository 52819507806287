import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BUTTON, getValues } from './util';
import { isEmpty } from '@driverup/util';
import { Link } from 'react-router-dom';

interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  variant?: string;
  size?: 'large' | 'normal';
  className?: string;
  to?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = BUTTON.VARIANT.PRIMARY,
  size = BUTTON.SIZE.NORMAL,
  className,
  to, // a url path to navigate to
  ...rest
}) => {
  let textCss: string;
  let bgCss: string;
  let hoverBg: string;
  let sizeCss = 'px-4 py-2';
  const hoverShadow = 'hover:shadow-md';
  const baseCss =
    'uppercase rounded font-semibold tracking-wide text-sm focus:outline-none block text-center';
  const isButton = isEmpty(to);

  if (size === BUTTON.SIZE.LARGE) {
    sizeCss = 'px-5 py-3';
  }

  if (variant === 'primary') {
    bgCss = 'bg-primary-500';
    hoverBg = 'hover:bg-primary-600';
    textCss = 'text-white';
  } else if (variant === 'link') {
    bgCss = 'bg-white';
    textCss = 'text-primary-500';
  } else if (variant === 'secondary') {
    bgCss = 'bg-primary-100';
    textCss = 'text-primary-500';
  } else {
    bgCss = 'bg-primary-100';
  }

  // const slideUpCss = "transform hover:-translate-y-1";

  const styles = classNames(
    baseCss,
    sizeCss,
    bgCss,
    hoverBg,
    textCss,
    hoverShadow,
    className
  );
  return isButton ? (
    <button className={styles} {...rest}>
      {children}
    </button>
  ) : (
    <Link className={styles} to={to} {...rest}>
      {children}
    </Link>
  );
};

Button.propTypes = {
  /** Button type (look and feel) */
  variant: PropTypes.oneOf(getValues(BUTTON.VARIANT)),
  /** Button size */
  size: PropTypes.oneOf(getValues(BUTTON.SIZE)),
};

export default Button;
