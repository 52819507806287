import React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const CustomCurrentRefinements = ({ items, refine }) => (
  <ul>
    {items.map((item) => (
      <li key={item.label}>
        {item.items ? (
          <React.Fragment>
            {item.label}
            <ul>
              {item.items.map((nested) => (
                <li key={nested.label}>
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      refine(nested.value);
                    }}
                  >
                    {nested.label} X
                  </button>
                </li>
              ))}
            </ul>
          </React.Fragment>
        ) : (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {item.label}
          </a>
        )}
      </li>
    ))}
  </ul>
);

export const CurrentRefinements = connectCurrentRefinements(
  CustomCurrentRefinements
);

export default CurrentRefinements;
