import React, { ReactText } from 'react';
import classNames from 'classnames';

type TableRowProps = {
  left: ReactText;
  right: ReactText;
  last?: boolean;
};
export const TableRow = ({ left, right, last }: TableRowProps) => {
  const rightCss = classNames('text-gray-700 text-right', {
    'font-bold text-2xl': last,
  });
  return (
    <tr>
      <td className="uppercase py-2 text-sm font-semibold text-primary-800">
        {left}
      </td>
      <td className={rightCss}>{right}</td>
    </tr>
  );
};
