import { Car } from '@driverup/constants';
import { formatNumber } from '..';

// const USE_LOCAL_MOCK_DATA = true;
const USE_LOCAL_MOCK_DATA = false;

export const isDev = () => process.env.NODE_ENV !== 'production';
export const shouldUseLocalMockData = () => isDev() && USE_LOCAL_MOCK_DATA;

// console.log('process.env.USE_LOCAL_MOCK_DATA', process.env.USE_LOCAL_MOCK_DATA);
// Source: https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
export const isEmpty = (obj): boolean => {
  if (obj === null || obj === undefined || obj === '') {
    return true;
  }

  return (
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length
  );
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

/**
 * Takes car object from Algolia, and formats to suit UI needs
 * @param {object} algoliaCar - from Algolia
 */
export function formatCar(algoliaCar): Car {
  if (isEmpty(algoliaCar)) {
    return null;
  }

  const {
    objectID,
    miles,
    price,
    make,
    year,
    image,
    hiresPhotos = [],
    optionalFeatures,
    model,
    ...rest
  } = algoliaCar;

  const priceStr = formatNumber(price);
  let photos = [];
  let otherFeatures = optionalFeatures?.split(',');

  // if (isEmpty(hiresPhotos?.[0])) {
  if (isEmpty(hiresPhotos?.[0])) {
    const carUnavailablePhotoUrl = '/assets/car-unavailable.png';
    // 'https://files.slack.com/files-pri/T01H664DW22-F022T99LHSQ/image.png';
    // 'https://www.miamichevy.com/resources/components/missing/photo_unavailable_640.jpg?width=320';
    photos = [carUnavailablePhotoUrl];
  } else {
    photos = [...photos, ...hiresPhotos];
  }

  if (isEmpty(otherFeatures) || isEmpty(otherFeatures?.[0])) {
    otherFeatures = null;
  }

  return {
    id: objectID,
    miles: `${formatNumber(miles)} miles`,
    priceInt: price,
    price: priceStr,
    priceWithCurrency: `$${priceStr}`,
    image,
    photos,
    makeAndYear: `${year} ${make}`,
    otherFeatures,
    model,
    ...rest,
  };
}

// CARS SELECTOR
/**
 * Takes car array from Algolia, and formats to suit UI needs
 * @param {array} cars - from Algolia
 */
export function formatCars(cars): Car[] {
  return cars?.map(formatCar);
}
