import React from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState, userActions, UserState } from '@driverup/buyer/state';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@driverup/constants';

interface AmplifyCognitoUser {
  username: string;
  attributes: {
    email: string;
    email_verified: boolean;
  };
}

type UseAuthReturnValue = {
  handleLogout(): void;
  handleLogin(): void;
  isAuthenticated: boolean;
  user: UserState;
};

// function formatAmplifyUserForReduxStore(user: AmplifyCognitoUser) {

// }

export function useAuth(): UseAuthReturnValue {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => getUserState(state));
  const isUserIdle = user.loadingStatus === 'not loaded';
  const isAuthenticated = user.isAuthenticated;

  const handleLogout = () => {
    (async () => {
      try {
        await Auth.signOut();
        dispatch(userActions.reset());
      } catch (error) {
        console.log('error signing out: ', error);
      }
    })();
  };

  const handleLogin = () => {
    history.push(ROUTES.LOGIN, { from: window.location.pathname });
  };

  React.useEffect(() => {
    // This effect is reached when a user attempts a "sign up" flow. If already logged in,
    // the user is just set in Redux for other components to know.
    return onAuthUIStateChange((nextAuthState, authData) => {
      // Set Auth user in Redux so other components are aware
      const user = authData as AmplifyCognitoUser;
      const isAuthenticated = nextAuthState === AuthState.SignedIn && !!user;

      const userFormatted: UserState = {
        email: user?.attributes?.email,
        username: user?.username,
        isAuthenticated,
      };
      dispatch(userActions.add(userFormatted));
    });
  }, [dispatch]);

  /**
   * If a page refresh is performed, and the user isn't on a ProtectedRoute which triggers the onAuthUIStateChange
   * (e.g. Landing page), the NavBar will always think the user is NOT authenticated. To force fetch a user
   * on such components, we check to see if a user is idle i.e. hasn't been loaded to Redux, and if so, we
   * manually fetch the user and set in Redux store.
   */
  React.useEffect(() => {
    if (isUserIdle) {
      (async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const userFormatted: UserState = {
            email: user?.attributes?.email,
            username: user?.username,
            isAuthenticated: !!user,
          };
          dispatch(userActions.add(userFormatted));
        } catch (e) {
          // console.log({ e });
        }
      })();
    }
  }, [dispatch, isUserIdle]);

  return {
    handleLogin,
    handleLogout,
    isAuthenticated,
    user,
  };
}
