import { ROUTES } from '@driverup/constants';

export type RouteType = {
  text: string;
  to: string;
};
type UserRouteType = {
  buyer: RouteType[];
  dealer: RouteType[];
};

export const userRoutes: UserRouteType = {
  dealer: [
    {
      text: 'My Profile',
      to: ROUTES.DEALER.USER_PROFILE,
    },
    {
      text: 'My Buyers',
      to: ROUTES.DEALER.APPROVED_BUYERS,
    },
  ],
  buyer: [
    // {
    //   text: 'My Profile',
    //   to: ROUTES.USER_PROFILE,
    // },
    {
      text: 'My Transactions',
      to: ROUTES.TRANSACTIONS,
    },
  ],
};
