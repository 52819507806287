import { API, graphqlOperation } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api-graphql';

interface RequestInput {
  query: string;
  variables?: any;
}

interface GraphqlRequestInput extends RequestInput {
  reqType: 'public' | 'private';
}

export async function amplifyGraphqlRequest({
  query,
  variables,
  reqType,
}: GraphqlRequestInput) {
  const res: any = await API.graphql({
    query,
    authMode:
      reqType === 'public'
        ? GRAPHQL_AUTH_MODE.AWS_IAM
        : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    variables,
  });
  return res?.data;
}

export async function publicRequest({ query, variables }: RequestInput) {
  return await amplifyGraphqlRequest({
    query,
    variables,
    reqType: 'public',
  });
}

export async function privateRequest({ query, variables }: RequestInput) {
  return await amplifyGraphqlRequest({
    query,
    variables,
    reqType: 'private',
  });
}
