export const BUTTON = {
    VARIANT: {
      PRIMARY: "primary",
      SECONDARY: "secondary",
      LINK: "link"
    },
    SIZE: {
      LARGE: "large",
      NORMAL: "normal"
    }
  };
  
  /** Get Object values */
  export function getValues(obj) {
    return Object.values(obj);
  }
  