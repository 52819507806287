/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ddd4f2c1-d07a-4ea3-a970-36ffc3a016a7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KKj3eerf3",
    "aws_user_pools_web_client_id": "1tf1fanbib8jbl7m7s417d7v99",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://7zreotqyfrgslbe2zx2rvybsxe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-6xukexyognh3lfok2sphtwyoce",
    "aws_cloud_logic_custom": [
        {
            "name": "lighticowebhooks",
            "endpoint": "https://glclg77ev5.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
