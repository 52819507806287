import React, { useState, useEffect } from 'react';
import {
  Pill,
  useAuth,
  Currency,
  Percentage,
  useDynamoPagination,
  DynamoPaginator,
} from '@driverup/ui';
import { ROUTES } from '@driverup/constants';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getLoanAppsForDealer } from '@du/services';
import { util } from '@du/business-logic';
import { Center, HStack, Text, VStack } from '@chakra-ui/layout';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { Button } from '@chakra-ui/button';

/* eslint-disable-next-line */
export interface ApprovedBuyersProps {}

type BuyerRowProps = util.GetLoanAppsForDealerResponse;

const BuyerRow = ({
  id,
  status,
  amountFinanced,
  cashDown,
  contractRate,
  buyer,
}: BuyerRowProps) => {
  const history = useHistory();
  return (
    <Tr>
      {/* <Td><Image src={image} alt="car" className="rounded" /></Td> */}
      <Td>
        {buyer.firstName} {buyer.lastName}
      </Td>
      <Td>
        <Currency value={parseInt(amountFinanced, 10)} />
      </Td>
      <Td>
        <Currency value={parseInt(cashDown, 10)} />
      </Td>
      <Td>
        <Percentage value={parseFloat(contractRate) / 100} />
      </Td>
      <Td>
        <Pill>{status}</Pill>
      </Td>
      <Td>
        <HStack>
          <Button
            colorScheme={status === 'DEALER_APPROVAL_PENDING' ? 'blue' : null}
            onClick={() =>
              history.push(`${ROUTES.DEALER.APPROVED_BUYERS}/${id}`)
            }
          >
            Details
          </Button>
        </HStack>
      </Td>
    </Tr>
  );
};

export const ApprovedBuyers: React.FC = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const {
    nextToken,
    nextNextToken,
    previousTokens,
    fetchNextPage,
    fetchPreviousPage,
    handleQuerySuccess,
  } = useDynamoPagination();

  const payload = {
    dealerId: user.username,
    limit: 10,
    nextToken,
  };

  useEffect(() => {
    queryClient.invalidateQueries('loan_applications_for_dealer');
  }, [queryClient, nextToken]);

  const query = useQuery(
    ['loan_applications_for_dealer', nextToken],
    () => getLoanAppsForDealer(payload),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: true,
      onSettled: handleQuerySuccess,
    }
  );

  const { isLoading, isError, data } = query;

  const loanApps: util.GetLoanAppsForDealerResponse[] = data?.items;

  if (isLoading) {
    return (
      <Center h="50vh">
        <VStack>
          <Spinner size="xl" color="blue.500" />
          <Text>Getting loan applications...</Text>
        </VStack>
      </Center>
    );
  }

  if (isError) {
    return <div>Something went wrong...</div>;
  }

  return (
    <div className="container mx-auto">
      <div className="shadow rounded p-6">
        <Table>
          <Thead>
            <Tr>
              <Th>Customer</Th>
              <Th>Amount Financed</Th>
              <Th>Cash Down</Th>
              <Th>Rate</Th>
              <Th>Status</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {loanApps?.map((loanApp) => {
              const id = loanApp.id;

              return (
                <BuyerRow
                  key={id} // TODO: Find better ID
                  {...loanApp}
                />
              );
            })}
          </Tbody>
        </Table>
        <DynamoPaginator
          nextNextToken={nextNextToken}
          previousTokens={previousTokens}
          fetchNextPage={fetchNextPage}
          fetchPreviousPage={fetchPreviousPage}
        />
      </div>
    </div>
  );
};

export default ApprovedBuyers;
