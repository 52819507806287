import React from 'react';
import classNames from 'classnames';
import { FiCheck as CheckIcon } from 'react-icons/fi';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Text,
  Button,
  Icon,
  VStack,
  Box,
} from '@chakra-ui/react';
import { FaPen as PencilIcon, FaPhone } from 'react-icons/fa';

interface ApprovalDetail {
  label: string;
  value: string;
}
/* eslint-disable-next-line */
export interface LoanApplicationResultsProps {
  data: {
    defiApplicationDecision: string;
    contractUrl: string;
    status: string;
    approvalDetails: ApprovalDetail[];
  };
}

/**
  AD (Auto Decline) / Anything else 
  Sorry unable to approve you for what you have requested online. Please contact the dealer for more options.

  AA (Auto Approved)
  Congratulations! You are approved.
  Here is your amount financed, rate, term, and payment. All you have to do is e-sign, and you are done! We mean it, done!
 
 
  Sample App Loan Response

  const loanAppResponse = {
    defiApplicationId: '1067',
    defiApplicationDecision: 'AA',
    status: 'BUYER_SIGNATURE_PENDING',
    contractRate: '7.5',
    amountFinanced: '15000.00',
    term: '50',
    payment: '350.24',
    dealerSalesPrice: '25000.00',
    cashDown: '12000.00',
    tradeDown: '12000.00',
    salesTax: '1500.00',
    registrationFee: '500.00',
    participationFee: '150.00',
    contractUrl:
      'https://na1.lightico.com/Default.aspx?mt=self&sid=f1ccacaa-cf8a-4d19-9169-8ef4d86d9814',
  };

 
  */

export const LoanApplicationResults: React.FC<LoanApplicationResultsProps> = ({
  data,
}) => {
  const baseCongratsCss = 'text-xl text-gray-700 text-center';
  const approvalDetails = data?.approvalDetails;
  const isLoanDefiApproved = data?.defiApplicationDecision === 'AA';
  const isContractReadyForBuyerSignature =
    data?.status === 'BUYER_SIGNATURE_PENDING' && !!data?.contractUrl; // TODO: If buyer signature is pending, there MUST be a contract. Handle lightico error somehow.

  return (
    <div className="container mx-auto flex justify-center items-center max-w-lg">
      {isLoanDefiApproved ? (
        <div className="flex flex-col items-center shadow-lg p-10 rounded-lg">
          <CheckIcon className="text-6xl text-green-600" />
          <div className={classNames(baseCongratsCss, 'mb-2 font-bold')}>
            Congratulations!
          </div>
          <div className={baseCongratsCss}>
            <p className="mb-6 text-base">
              You are approved. Here are your approval details:
            </p>

            <Table variant="simple">
              <Tbody>
                {approvalDetails.map(({ label, value }) => {
                  return (
                    <Tr key={label}>
                      <Td>{label}</Td>
                      <Td isNumeric>{value}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>

          <Box mt="7">
            {isContractReadyForBuyerSignature ? (
              <VStack spacing="5">
                <Text align="center">
                  All you have to do is e-sign, and you are done! We mean it,
                  done!
                </Text>
                <Button
                  as="a"
                  href={data.contractUrl}
                  isFullWidth
                  leftIcon={<Icon as={PencilIcon} />}
                >
                  Sign Contract
                </Button>
              </VStack>
            ) : (
              <Text>
                The dealer is reviewing your application. Once that is complete,
                you will be able to proceed with the next steps.
              </Text>
            )}
          </Box>
        </div>
      ) : (
        <VStack spacing={5}>
          <Text>
            Sorry we are unable to approve you for what you have requested
            online. However, the dealer has other options that may work. Please
            contact them directly!
          </Text>
          {/* TODO: Get Dealer Info component here */}
          {/* <Button variant="outline" leftIcon={<Icon as={FaPhone} />}>
            Contact Dealer
          </Button> */}
        </VStack>
      )}
    </div>
  );
};

export default LoanApplicationResults;
