import React from 'react';
// import CurrentRefinements from '../../components/CurrentRefinements';
// import SearchBox from "../../components/SearchBox";
// import CarFilters from "../../components/CarFilters";
import {
  Hits,
  SearchBox,
  CurrentRefinements,
  CarPaginator,
} from '@driverup/ui';
import { connectStateResults, Configure } from 'react-instantsearch-dom';
// import CustomAutocomplete from "./Autocomplete";

/* eslint-disable-next-line */
export interface CarSearchResultsProps {}

const Results = connectStateResults(
  ({ searchState, searchResults, children }) => {
    // console.log({ searchResults, searchState });
    return searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div>
        {searchState.query &&
          `No results have been found for "${searchState.query}"`}
        {/* <ClearRefinements clearsQuery /> */}
      </div>
    );
  }
);

const alignmentWithCarlistXAxisSpacing = 'mx-2'; // TODO: Figure out how to eliminate the added space for x-axis padding/spacing for CardList

export const CarSearchResults = (props: CarSearchResultsProps) => {
  return (
    <div className="h-screen container mx-auto">
      {/* <Col lg={2}>
          <CarFilters facets={["make", "model", "year"]} />
        </Col> */}
      <p
        className={`uppercase font-bold text-xs mb-2 text-gray-600 ${alignmentWithCarlistXAxisSpacing}`}
      >
        Used Cars
      </p>
      <div className={`mb-6 ${alignmentWithCarlistXAxisSpacing}`}>
        <SearchBox />
        <Configure hitsPerPage={12} />
      </div>
      {/* <LoadingIndicator /> */}
      {/* <CurrentRefinements /> */}
      {/* <Hits /> */}
      <Results>
        <Hits />
      </Results>
      <CarPaginator />
    </div>
  );
};

export default CarSearchResults;
