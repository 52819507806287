import React from 'react';
import { Button, Footer } from '@driverup/ui';
import { ROUTES } from '@driverup/constants';
import Logo from './undraw_4.svg';

// function DriverUpLogo() {
//   return (
//     <img
//       src="//img1.wsimg.com/isteam/ip/6df124e1-7f72-4cbe-b784-bb0dcf725872/DriverUp_colorWhite.png/:/rs=h:184/qt=q:95"
//       srcset="//img1.wsim,g.com/isteam/ip/6df124e1-7f72-4cbe-b784-bb0dcf725872/DriverUp_colorWhite.png/:/rs=h:184/qt=q:95,
// //img1.wsimg.com/isteam/ip/6df124e1-7f72-4cbe-b784-bb0dcf725872/DriverUp_colorWhite.png/:/rs=h:276/qt=q:95 1.5x,
// //img1.wsimg.com/isteam/ip/6df124e1-7f72-4cbe-b784-bb0dcf725872/DriverUp_colorWhite.png/:/rs=h:368/qt=q:95 2x,
// //img1.wsimg.com/isteam/ip/6df124e1-7f72-4cbe-b784-bb0dcf725872/DriverUp_colorWhite.png/:/rs=h:552/qt=q:95 3x"
//       alt="Driverup"
//       data-ux="ImageLogo"
//       data-aid="HEADER_LOGO_IMAGE_RENDERED"
//       id="n-73172"
//       data-field-id="logo"
//       data-field-route="/logo"
//       class="x-el x-el-img c1-1 c1-2 c1-t c1-2m c1-p c1-q c1-14 c1-15 c1-2n c1-2o c1-2p c1-2q c1-2r c1-2s c1-2t c1-2u c1-2v c1-1t c1-2w c1-b c1-c c1-2x c1-2y c1-2z c1-30 c1-31 c1-32 c1-33 c1-34 c1-35 c1-36 c1-37 c1-38 c1-39 c1-3a c1-d c1-3b c1-3c c1-3d c1-e c1-f c1-g x-d-ux x-d-aid x-d-field-id x-d-field-route"
//     />
//   );
// }

function LightLanding() {
  return (
    <main className="bg-white font-montserrat">
      <div className="container mx-auto px-6 sm:px-12 flex flex-col-reverse sm:flex-row items-center">
        <div className="sm:w-2/5 flex flex-col items-start mt-8 sm:mt-0">
          <h1 className="text-4xl lg:text-6xl leading-none mb-4">
            <strong className="font-black">Find your car</strong> online
          </h1>
          <p className="lg:text-lg mb-4 sm:mb-12">
            Buy a car online. Have it delivered. Contact-free.
          </p>
          <Button size="large" to={ROUTES.CARS}>
            Search All Cars
          </Button>
        </div>
        <div className="sm:w-3/5">
          <img src={Logo} alt="Logo" />
        </div>
      </div>

      <Footer />
    </main>
  );
}

export const Landing = () => {
  return <LightLanding />;
};

export default Landing;
