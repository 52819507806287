import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import Auth from './Auth';
import { ROUTES } from '@driverup/constants';

interface LocationState {
  from: string;
}

export function LoginPage(): JSX.Element {
  const history = useHistory<LocationState>();
  // Access prev page props https://stackoverflow.com/a/63276679/2213882
  const prevPath = history?.location?.state?.from || ROUTES.HOME;
  return <Auth component={<Redirect to={prevPath} />} />;
}
//   return <AmplifyLoginPage />;
