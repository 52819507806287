import React, { ReactElement } from 'react';

type PercentageProps = {
  value: number;
  locale?: string;
  maximumFractionDigits?: number;
};

const Percentage = ({
  value,
  locale = 'en-US',
  maximumFractionDigits = 2,
}: PercentageProps): ReactElement => {
  const formatted = new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits,
  }).format(value);
  return isNaN(value) ? <span>N/A</span> : <span>{formatted}</span>;
};

export default Percentage;
