// import { createSlice } from '@reduxjs/toolkit';
import { OptionData } from '@driverup/ui';
import {
  // calculateLoanMonthlyPayment,
  getMonthlyInterestRate,
} from '@driverup/util';
// import { lengthOfLoanData } from '@driverup/constants';

// CONSTANTS
export const creditScores: OptionData[] = [
  {
    // value: getMonthlyInterestRate(5.6), // TEST. REPLACE WITH 7!
    value: getMonthlyInterestRate(7.5), // APR
    text: 'Excellent - 780',
  },
  {
    value: getMonthlyInterestRate(11),
    text: 'Good - 680',
  },
  {
    value: getMonthlyInterestRate(14),
    text: 'Average - 630',
  },
  {
    value: getMonthlyInterestRate(17),
    text: 'Below Average - 580',
  },
];

// // STATE MANAGEMENT
// type InitialStateOptions = {
//   totalPrice?: number;
// };

// type InitialState = {
//   totalPrice: number;
//   cashDownStep: number;
//   estimatedFinancedAmount: number;
//   monthlyPaymentInputValue: number;
//   cashDown: number;
//   interest: number;
//   numberOfMonthsInLoan: number;
// };
// export function getInitialState({
//   totalPrice,
// }: InitialStateOptions = {}): InitialState {
//   const interest = Number(creditScores[0].value);
//   const numberOfPeriods = Number(lengthOfLoanData[0].value);
//   const monthlyInterestRate = interest;

//   const monthlyPaymentInputValue = Math.ceil(
//     calculateLoanMonthlyPayment({
//       principal: totalPrice,
//       interestRatePerPeriod: monthlyInterestRate,
//       numberOfPeriods,
//     })
//   );
//
//   return {
//     totalPrice,
//     cashDownStep: 1,
//     estimatedFinancedAmount: totalPrice,
//     monthlyPaymentInputValue,
//     cashDown: 0,
//     interest,
//     numberOfMonthsInLoan: numberOfPeriods,
//   };
// }

// Utility
// function resetFinanceAmount(state) {
//   state.estimatedFinancedAmount = state.totalPrice;
//   state.cashDown = 0;
// }

// // SLICE
// const slice = createSlice({
//   name: 'PriceEstimator',
//   initialState: getInitialState(), // useReducer takes its initial state, so this won't be used there. Added just to prevent TS error.
//   reducers: {
//     changeCashDown(state, action) {
//       const cashDown = action.payload;
//       const estimatedFinancedAmount = state.totalPrice - cashDown;

//       const monthlyPaymentInputValue = Math.ceil(
//         calculateLoanMonthlyPayment({
//           principal: estimatedFinancedAmount,
//           interestRatePerPeriod: state.interest,
//           numberOfPeriods: state.numberOfMonthsInLoan,
//         })
//       );
//       state.monthlyPaymentInputValue = monthlyPaymentInputValue;
//       state.estimatedFinancedAmount = estimatedFinancedAmount;
//       state.cashDown = cashDown;
//     },
//     changeCreditScore(state, action) {
//       const interest = action.payload;
//       // Ensure the "Cash Down" section reflects the latest computed values
//       const monthlyPaymentInputValue = Math.ceil(
//         calculateLoanMonthlyPayment({
//           principal: state.totalPrice,
//           interestRatePerPeriod: interest,
//           numberOfPeriods: state.numberOfMonthsInLoan,
//         })
//       );
//       state.monthlyPaymentInputValue = monthlyPaymentInputValue;
//       resetFinanceAmount(state);
//       state.interest = interest;
//     },
//     changeNumberOfMonthsInLoan(state, action) {
//       const numberOfMonthsInLoan = action.payload;
//       // Ensure the "Monthly Payment" section reflects the latest computed values
//       const monthlyPaymentInputValue = Math.ceil(
//         calculateLoanMonthlyPayment({
//           principal: state.totalPrice,
//           interestRatePerPeriod: state.interest,
//           numberOfPeriods: numberOfMonthsInLoan,
//         })
//       );
//       state.monthlyPaymentInputValue = monthlyPaymentInputValue;
//       resetFinanceAmount(state);
//       state.numberOfMonthsInLoan = numberOfMonthsInLoan;
//     },
//   },
// });

// export const reducer = slice.reducer;
// export const actions = slice.actions;
