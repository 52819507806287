import React from 'react';
import { LoginPage, NavBar, ProtectedRoute, useDealerApp } from '@driverup/ui';
import { ROUTES } from '@driverup/constants';
import {
  Landing,
  CarSearchResults,
  CarDetails,
  LoanApplication,
  ApprovedBuyers,
  Transactions,
  CreditApplication,
  ApplicationDetail,
} from '@driverup/buyer/pages';
import { Switch, Route } from 'react-router-dom';
import { InstantSearch } from 'react-instantsearch-dom';
import { appSearchClient } from '@driverup/util';

function Routes() {
  const { isDealerApp } = useDealerApp();

  return isDealerApp ? (
    <Switch>
      <ProtectedRoute
        exact
        path={ROUTES.DEALER.HOME}
        component={ApprovedBuyers}
      />
      <ProtectedRoute
        exact
        path={ROUTES.DEALER.APPROVED_BUYERS}
        component={ApprovedBuyers}
      />
      <ProtectedRoute
        exact
        path={`${ROUTES.DEALER.APPROVED_BUYERS}/:id`}
        component={ApplicationDetail}
      />
    </Switch>
  ) : (
    <Switch>
      <Route exact path={ROUTES.HOME} component={Landing} />
      <Route exact path={ROUTES.LOGIN} component={LoginPage} />
      <Route exact path={ROUTES.CARS} component={CarSearchResults} />
      <ProtectedRoute
        exact
        path={ROUTES.TRANSACTIONS}
        component={Transactions}
      />
      <Route exact path={`${ROUTES.CARS}/:id`} component={CarDetails} />
      <Route
        exact
        path={ROUTES.CREDIT_APPLICATION}
        component={CreditApplication}
      />
      <ProtectedRoute
        exact
        path={`${ROUTES.PRE_QUALIFY_APPLICATION}/:carId`}
        component={LoanApplication}
      />
      {/* Fallback Route - Redirect all 404's to home
       */}
      {/* <Redirect to="/" /> */}
    </Switch>
  );
}

export const App: React.FC = () => {
  return (
    <InstantSearch
      searchClient={appSearchClient}
      indexName="cars_dev"
      stalledSearchDelay={500}
    >
      <NavBar />
      <Routes />
    </InstantSearch>
  );
};

export default App;
