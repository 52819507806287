// import React from 'react';
import { useLocation } from 'react-router-dom';

type UseDealerAppReturnObject = {
  isDealerApp: boolean;
};

export function useDealerApp(): UseDealerAppReturnObject {
  const location = useLocation(); // How come this causes re-render, but this doesn't? // const location = window.location;
  const isDealerApp = location?.pathname?.includes('dealer');

  return { isDealerApp };
}
