import React from 'react';
import { connectHits } from 'react-instantsearch-dom';

import { CarCardList } from '@driverup/ui';
import { formatCars } from '@driverup/util';

const CustomHits = ({ hits }) => {
  const cars = formatCars(hits);
  return <CarCardList cars={cars} />;
};

export const Hits = connectHits(CustomHits);
export default Hits;
