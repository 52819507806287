import React from 'react';
import classNames from 'classnames';
import { isEmpty } from '@driverup/util';
import { Image, Pill } from '@driverup/ui';
import { ROUTES, Car } from '@driverup/constants';
import Button from '../button/button';
import { Header, SmallText, Price, Miles, Pills } from './CarCardList';

export type CarCardVariant = 'Order' | 'Hit';
type CarCardProps = Car & {
  variant: CarCardVariant;
  onClick(): void;
  pills?: string[];
};

export const CarCard = ({
  onClick,
  variant = 'Hit',
  makeAndYear = '',
  price = '',
  model = '',
  trim = '',
  miles = '',
  status = 'Pending',
  pills = [],
  photos,
}: CarCardProps) => {
  const isOrder = variant === 'Order';
  const hasDealerApproved = status === 'Accepted';
  const baseHitCss = !isOrder ? 'cursor-pointer hover:shadow-2xl' : '';
  const slideUpCss = !isOrder ? 'transform hover:-translate-y-2' : '';
  const borderCss = !isOrder
    ? 'hover:border-primary-300 border border-transparent'
    : '';
  const image = photos?.[0];

  return (
    <div
      onClick={onClick}
      className={classNames(
        'w-full rounded-lg overflow-hidden shadow-lg',
        baseHitCss,
        borderCss,
        slideUpCss
      )}
    >
      <Image
        alt={'car'}
        src={image} // use normal <img> attributes as props
      />
      <div className="px-6 py-4">
        {isOrder && !hasDealerApproved && (
          <Pill className="mb-4 font-semibold">Awaiting Dealer approval</Pill>
        )}
        <Header>{makeAndYear}</Header>
        <Header>{model}</Header>
        <SmallText>{trim}</SmallText>
        <div className="flex mt-1 align-baseline items-baseline justify-between">
          <Price>{price}</Price>
          <Miles>{miles}</Miles>
        </div>
        {!isEmpty(pills) && (
          <div className="flex flex-wrap mt-3">
            <Pills data={pills} />
          </div>
        )}
        {isOrder && hasDealerApproved && (
          <Button to={ROUTES.CREDIT_APPLICATION} className="mt-2">
            Finish Application
          </Button>
        )}
      </div>
    </div>
  );
};
