import { privateRequest } from '../amplify-api';
import * as queries from '../queries';
import * as Types from '../types';

// Client-side to back-end
export async function submitLoanApplication(
  variables
): Promise<Types.LoanApplication> {
  const res: Types.SubmitLoanApplicationMutation = await privateRequest({
    query: queries.submitLoanApplication,
    variables,
  });

  const loanApp = res.submitLoanApplication.loanApplication;
  if (!loanApp) {
    throw new Error('loanApplication is null. Failed req.');
  }
  return loanApp;
}

export async function getLoanAppsForDealer(variables) {
  const res: Types.GetLoanAppsByDealerIdQuery = await privateRequest({
    query: queries.getLoanAppsByDealerId,
    variables,
  });

  if (!res?.getLoanAppsByDealerId?.items) {
    throw new Error('Failed to fetch loan apps.');
  }
  return res.getLoanAppsByDealerId;
}

export async function getLoanAppsForBuyer(variables) {
  const res: Types.GetLoanAppsByBuyerIdQuery = await privateRequest({
    query: queries.getLoanAppsByBuyerId,
    variables,
  });

  if (!res?.getLoanAppsByBuyerId?.items) {
    throw new Error('Failed to fetch loan apps.');
  }
  return res.getLoanAppsByBuyerId;
}

export async function getLoanAppDetail(variables) {
  const res = await privateRequest({
    query: queries.getLoanAppDetail,
    variables,
  });

  if (!res?.getLoanApplication) {
    throw new Error('Failed to fetch loan apps.');
  }
  return res.getLoanApplication;
}

export async function dealerDecision(variables) {
  const res: Types.DealerDecisionMutation = await privateRequest({
    query: queries.dealerDecision,
    variables,
  });

  const info = res?.dealerDecision?.loanApplication;
  if (!info) {
    throw new Error('Failed dealerDecision');
  }
  return info;
}
export type buyerDecisionInput = {
  input: {
    shouldApproveLoan: boolean;
    loanAppId: string;
  };
};
export type deliveryDecisionInput = {
  input: {
    deliveryDecision: string;
    loanAppId: string;
  };
};
export async function buyerDecision(variables: buyerDecisionInput) {
  const res: Types.BuyerDecisionMutation = await privateRequest({
    query: queries.buyerDecision,
    variables,
  });

  const info = res?.buyerDecision?.loanApplication;
  if (!info) {
    throw new Error('Failed dealerDecision');
  }
  return info;
}

export async function deliveryDecision(variables: deliveryDecisionInput) {
  const res: Types.DeliverDecisionMutation = await privateRequest({
    query: queries.deliveryDecision,
    variables,
  });
  const info = res?.deliveryDecision?.loanApplication;
  if (!info) {
    throw new Error('Failed deliveryDecision');
  }
  return info;
}
