// import { OptionData } from '@driverup/ui';
export type OptionData = {
  value: number;
  text?: string;
};

const NUMBER_OF_MONTHS = 84;
function getLengthOfLoanData(): OptionData[] {
  const data = [];
  for (let i = NUMBER_OF_MONTHS; i >= 12; i -= 6) {
    const optionData: OptionData = { value: i, text: `${i} Months` };
    data.push(optionData);
  }
  return data;
}
export const lengthOfLoanData = getLengthOfLoanData();
