import { ROUTES } from '@driverup/constants';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type FooterRouteConfig = {
  to?: string;
  text?: string;
};

type FooterColumnProps = {
  header: string;
  routes: FooterRouteConfig[];
};

function FooterColumn({ routes, header }: FooterColumnProps): ReactElement {
  return (
    <div className="p-5 w-1/2 sm:w-4/12 md:w-3/12">
      <div className="text-xs uppercase text-gray-400 font-medium mb-6">
        {header}
      </div>
      {routes?.map(({ to, text }) => {
        const css =
          'my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium focus:outline-none';
        return (
          <Link key={text} to={to} className={css}>
            {text}
          </Link>
        );
      })}
    </div>
  );
}

export function Footer(): ReactElement {
  const routesCol1 = [
    { text: 'Dealer Site', to: ROUTES.DEALER.APPROVED_BUYERS },
  ];

  return (
    <footer className="bg-gray-800 pt-10 sm:mt-10">
      {/* <div className="max-w-6xl m-auto text-gray-800 flex flex-wrap justify-left">
        <FooterColumn routes={routesCol1} header="Affiliates" />
      </div> */}

      <div className="pt-2">
        <div
          className="flex pb-5 px-3 m-auto pt-5 
            border-t border-gray-500 text-gray-400 text-sm 
            flex-col md:flex-row max-w-6xl"
        >
          <div className="mt-2">© Copyright 2021. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
