export const ROUTES = {
  HOME: '/',
  CARS: '/cars',
  LOGIN: '/login',
  PRE_QUALIFY_APPLICATION: '/pre-qualify',
  PRE_QUALIFY_APPLICATION_RESULTS: '/pre-qualify-results',
  CREDIT_APPLICATION: '/credit-application',
  CREDIT_APPLICATION_RESULTS: '/credit-application-results',
  TRANSACTIONS: '/my-account/transactions',
  // USER_PROFILE: '/user',
  DEALER: {
    // Dealer links
    HOME: makeDealerRoute(),
    USER_PROFILE: makeDealerRoute('user'),
    APPROVED_BUYERS: makeDealerRoute('approved-buyers'),
  },
};

/**
 * Takes path e.g. "example-path", and converts it to "/dealer/example-path"
 * @param path
 */
function makeDealerRoute(path = '') {
  return `/dealer/${path}`;
}
