import React, { SyntheticEvent } from 'react';
import styles from './RangeSlider.module.css';

/* eslint-disable-next-line */
export interface RangeSliderProps {
  onChange?(e: SyntheticEvent): void;
  min?: number;
  max?: number;
  value?: number;
  step?: number;
}

export const RangeSlider = ({
  onChange,
  min,
  max,
  value,
  step,
}: RangeSliderProps) => {
  return (
    <div className={styles['slidecontainer']}>
      <input
        onChange={onChange}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        className={styles['range-slider']}
        id="myRange"
      />
    </div>
  );
};

export default RangeSlider;
