import React from 'react';
import { useHistory } from 'react-router-dom';
import { Pill } from '@driverup/ui';
import { Car } from '@driverup/constants';
import { CarCard, CarCardVariant } from './CarCard';

export const Header = ({ children }) => (
  <div className="text-primary-900 font-bold uppercase text-sm tracking-wide truncate">
    {children}
  </div>
);
export const SmallText = ({ children }) => (
  <p className="text-gray-500 text-xs truncate">{children}</p>
);
export const Price = ({ children }) => (
  <div>
    <span className="text-primary-500">$</span>
    <span className="text-primary-600 text-2xl">{children}</span>
  </div>
);
export const Miles = ({ children }) => <SmallText>{children}</SmallText>;
export const Pills = ({ data }) =>
  data?.map((text) => <Pill key={text}>{text}</Pill>);

type CarCardListProps = {
  cars: Car[];
  variant?: CarCardVariant;
};
export const CarCardList = ({
  cars = [],
  variant = 'Hit',
}: CarCardListProps) => {
  const history = useHistory();
  const isHit = variant === 'Hit';
  const goToCarDetailsPage = (carId: string) => () =>
    history.push(`/cars/${carId}`);

  return (
    <div className="flex flex-wrap">
      {cars?.map((car: Car) => (
        <div
          key={car?.id}
          className="flex w-full xs:w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2"
        >
          <CarCard
            {...car}
            variant={variant}
            onClick={isHit ? goToCarDetailsPage(car.id) : null}
          />
        </div>
      ))}
    </div>
  );
};

export default CarCardList;
