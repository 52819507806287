import React from 'react';
import { ROUTES } from '@driverup/constants';
import { useHistory } from 'react-router-dom';
import { useDealerApp } from '../hooks/useDealerApp';

/* eslint-disable-next-line */
export interface DriverupLogoProps {
  onClick?(): void;
}

export function DriverupLogo({ onClick }: DriverupLogoProps): JSX.Element {
  const history = useHistory();
  const { isDealerApp } = useDealerApp();
  const homeRoute = isDealerApp ? ROUTES.DEALER.HOME : ROUTES.HOME;

  return (
    <div
      className="text-black font-black text-xl sm:text-2xl flex items-center cursor-pointer"
      onClick={() => {
        if (onClick) {
          onClick();
        }
        history.push(homeRoute);
      }}
    >
      {/* <AiOutlineCar className="text-primary-700 mr-1" /> DriverUp */}
      DriverUp
    </div>
  );
}

export default DriverupLogo;
