import React, { useState, ReactText, useEffect } from 'react';
import { Select } from '@driverup/ui';
import { creditScores } from './util';
import { formatMoney, calculateLoanMonthlyPayment } from '@driverup/util';
import { Car, lengthOfLoanData } from '@driverup/constants';
import { RangeSelectorSection } from './RangeSelectorSection';
import { LoanSummaryTable } from './LoanSummaryTable';

function getAPRfromMonthlyRate(monthlyRate: ReactText) {
  return `${(Number(monthlyRate) * 12 * 100).toFixed(1)}%`;
}
type PriceEstimatorProps = {
  car: Car;
  onGetStartedClick(term: number, cashDown: number): void;
};

/**
 * Lower credit score? Higher monthly down
 * Higher cash down? lower monthly down
 */
export const PriceEstimator: React.FC<PriceEstimatorProps> = ({
  car,
  onGetStartedClick,
}) => {
  const carPriceInt = car?.priceInt;
  const totalPrice = carPriceInt; // + dealer fees, taxes e.t.c.

  // Inputs
  const [cashDown, setCashDown] = useState(1000);
  const [interest, setInterest] = useState(creditScores[0].value);
  const [lengthOfLoan, setLengthOfLoan] = useState(lengthOfLoanData[0].value);
  // Outputs
  const [estimatedFinancedAmount, setEstimatedFinancedAmount] = useState(0);
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  // When the car loads, or when any of the 3 changable inputs
  //  change, recalculate the total financed amount and the
  //  monthly payment
  useEffect(() => {
    const estimatedFinancedAmount = totalPrice - cashDown;
    const monthlyPaymentInputValue = Math.ceil(
      calculateLoanMonthlyPayment({
        principal: estimatedFinancedAmount,
        interestRatePerPeriod: interest,
        numberOfPeriods: lengthOfLoan,
      })
    );
    setEstimatedFinancedAmount(estimatedFinancedAmount);
    setMonthlyPayment(monthlyPaymentInputValue);
  }, [totalPrice, cashDown, interest, lengthOfLoan]);

  const zeroDecimalOption = {
    minimumFractionDigits: 0,
  };

  const carPrice = formatMoney(carPriceInt, zeroDecimalOption);
  const apr = getAPRfromMonthlyRate(interest);
  const monthlyPaymentMax = Math.ceil(
    calculateLoanMonthlyPayment({
      principal: totalPrice,
      interestRatePerPeriod: interest,
      numberOfPeriods: lengthOfLoan,
    })
  );
  // console.log({ monthlyPaymentMax, monthlyPaymentInputValue });
  const estimatedFinancedAmountFormatted = formatMoney(
    estimatedFinancedAmount,
    zeroDecimalOption
  );
  const cashDownMoneyFormat = formatMoney(cashDown, zeroDecimalOption);
  const negetiveCashDownFormat = `(${cashDownMoneyFormat})`;
  const estimatedMonthlyPaymentSummaryHeader = formatMoney(
    monthlyPayment,
    zeroDecimalOption
  );

  const handleGetStarted = () => {
    onGetStartedClick(lengthOfLoan, cashDown);
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="lg:w-8/12 lg:mr-6">
        <div className="flex flex-col md:flex-row">
          <div className="w-full mb-4">
            <Select
              label="Length of Loan"
              value={lengthOfLoan}
              optionData={lengthOfLoanData}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setLengthOfLoan(Number(e.target.value));
              }}
            />
          </div>
          <div className="w-full md:ml-3">
            <Select
              label="Credit Score"
              value={interest}
              optionData={creditScores}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setInterest(Number(e.target.value))
              }
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="my-4">
            <RangeSelectorSection
              inputValue={monthlyPayment}
              headerText="Monthly Payment"
              min={0}
              max={monthlyPaymentMax}
              displayRangeSlider={false}
            />
          </div>
          <RangeSelectorSection
            inputValue={cashDown}
            onChangeRangeSlider={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCashDown(Number(e.target.value))
            }
            headerText="Cash Down"
            step={50}
            min={0}
            max={carPriceInt}
          />
        </div>
      </div>
      {/* Right side */}
      <LoanSummaryTable
        {...{
          onGetStartedClick: handleGetStarted,
          estimatedMonthlyPaymentSummaryHeader,
          cashDownMoneyFormat,
          carPrice,
          negetiveCashDownFormat,
          apr,
          estimatedFinancedAmountFormatted,
        }}
      />
    </div>
  );
};

export default PriceEstimator;
