export const getDealer = /* GraphQL */ `
  query getDealer($id: ID = "") {
    getDealer(id: $id) {
      email
      defiDealerId
      id
      name
      phoneNumber
      address {
        addressLine1
        city
        state
        zip
      }
      website
    }
  }
`;

export const listLoanApplications = /* GraphQL */ `
  query ListLoanApplications($limit: Int, $nextToken: String) {
    listLoanApplications(limit: $limit, nextToken: $nextToken) {
      items {
        id
        defiApplicationDecision
        contractRate
        amountFinanced
        term
        payment
        cashDown
        status
        contractUrl
      }
      nextToken
    }
  }
`;

// GraphQL Mutation from Client to the Back-end.
//  Input is defined in schema.graphql, but not sure how it ends up here.
export const submitLoanApplication = /* GraphQL */ `
  mutation SubmitLoanApplication($input: SubmitLoanApplicationInput) {
    submitLoanApplication(input: $input) {
      message
      loanApplication {
        id
        defiApplicationId
        defiApplicationDecision
        contractRate
        amountFinanced
        term
        payment
        dealerSalesPrice
        cashDown
        tradeDown
        salesTax
        registrationFee
        participationFee
        acquisitionFee
        contractUrl
        buyerId
        dealerId
        carId
        status
      }
    }
  }
`;

export const getLoanAppsByDealerId = /* GraphQL */ `
  query GetLoanAppsByDealerId(
    $dealerId: String
    $sortDirection: ModelSortDirection
    $filter: ModelLoanApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLoanAppsByDealerId(
      dealerId: $dealerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        defiApplicationDecision
        contractRate
        amountFinanced
        term
        payment
        cashDown
        status
        buyer {
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const dealerDecision = /* GraphQL */ `
  mutation DealerDecision($input: DealerDecisionInput) {
    dealerDecision(input: $input) {
      message
      loanApplication {
        id
        defiApplicationDecision
        contractRate
        amountFinanced
        term
        payment
        cashDown
        status
      }
    }
  }
`;

export const buyerDecision = /* GraphQL */ `
  mutation BuyerDecision($input: BuyerDecisionInput) {
    buyerDecision(input: $input) {
      message
      loanApplication {
        id
        defiApplicationDecision
        contractRate
        amountFinanced
        term
        payment
        cashDown
        status
      }
    }
  }
`;

export const deliveryDecision = /* GraphQL */ `
  mutation DeliveryDecision($input: DeliveryDecisionInput) {
    deliveryDecision(input: $input) {
      message
      loanApplication {
        id
        defiApplicationDecision
        contractRate
        amountFinanced
        term
        payment
        cashDown
        status
      }
    }
  }
`;

export const getLoanAppsByBuyerId = /* GraphQL */ `
  query GetLoanAppsByBuyerId(
    $buyerId: String
    $sortDirection: ModelSortDirection
    $filter: ModelLoanApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLoanAppsByBuyerId(
      buyerId: $buyerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dealerId
        dealer {
          name
          email
          phoneNumber
          address {
            addressLine1
            city
            state
            zip
          }
        }
        carId
        defiApplicationDecision
        contractRate
        amountFinanced
        term
        payment
        cashDown
        status
        contractUrl
      }
      nextToken
    }
  }
`;

export const getLoanAppDetail = /* GraphQL */ `
  query GetLoanAppDetail($id: ID!) {
    getLoanApplication(id: $id) {
      id
      status
      cashDown
      tradeDown
      amountFinanced
      salesTax
      registrationFee
      dealerSalesPrice
      participationFee
      acquisitionFee
      buyer {
        firstName
        lastName
        address {
          addressLine1
          streetNumber
          streetName
          state
          city
          zip
        }
      }
      car {
        make
        model
        year
        price
      }
    }
  }
`;
