import React, { BaseSyntheticEvent } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Input } from '@driverup/ui';
// import Input from '../Input/Input.tsx'; // TODO: See if above circular ref can be resolved.
import { FiSearch } from 'react-icons/fi';

type SearchBoxProps = {
  currentRefinement: string;
  refine(string): void;
  delay: number;
};

class CustomSearchBox extends React.Component<SearchBoxProps> {
  timerId = null;

  state = {
    value: this.props.currentRefinement,
  };

  onChangeDebounced = (event: BaseSyntheticEvent) => {
    const { refine, delay = 500 } = this.props;
    const value = event.currentTarget.value;

    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => refine(value), delay);

    this.setState(() => ({
      value,
    }));
  };

  render() {
    const { value } = this.state;

    return (
      <Input
        placeholder="Search for car..."
        startIcon={<FiSearch />}
        value={value}
        onChange={this.onChangeDebounced}
      />
    );
  }
}
export const SearchBox = connectSearchBox(CustomSearchBox);
export default SearchBox;
