import React, { ReactElement } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Auth, useAuth } from '../Auth';

interface ProtectedRouteProps extends RouteProps {
  component: any;
}

export function ProtectedRoute({
  component,
  ...args
}: ProtectedRouteProps): ReactElement {
  return <Auth component={<Route exact component={component} {...args} />} />;
}

export default ProtectedRoute;
