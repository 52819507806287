import React from 'react';
import Button from '../button/button';
import { ROUTES } from '@driverup/constants';
import { TableRow } from './TableRow';
import { SummaryHeaderItem } from './SummaryHeaderItem';

type LoanSummaryTableProps = {
  estimatedMonthlyPaymentSummaryHeader: string;
  cashDownMoneyFormat: string;
  carPrice: string;
  negetiveCashDownFormat: string;
  apr: string;
  estimatedFinancedAmountFormatted: string;
  onGetStartedClick(): void;
};

export function LoanSummaryTable({
  onGetStartedClick,
  estimatedMonthlyPaymentSummaryHeader,
  cashDownMoneyFormat,
  carPrice,
  negetiveCashDownFormat,
  apr,
  estimatedFinancedAmountFormatted,
}: LoanSummaryTableProps): React.ReactElement {
  return (
    <div className="flex-1 shadow-lg rounded-lg p-6">
      <div className="bg-indigo-900 rounded p-5 flex">
        <SummaryHeaderItem
          amount={estimatedMonthlyPaymentSummaryHeader}
          label="Estimated Monthly Payment"
        />
        <SummaryHeaderItem amount={cashDownMoneyFormat} label="Cash Down" />
      </div>
      <table className="w-full my-3">
        <tbody>
          <TableRow left="Vehicle Price" right={carPrice} />
          <TableRow left="Dealer Fees" right="None" />
          <TableRow left="Cash Down" right={negetiveCashDownFormat} />
          <TableRow left="APR" right={apr} />
          <TableRow
            last={true}
            left="You could borrow"
            right={estimatedFinancedAmountFormatted}
          />
        </tbody>
      </table>
      <Button size="large" className="w-full" onClick={onGetStartedClick}>
        Get Started
      </Button>
    </div>
  );
}
