import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
export { Calendar } from 'react-date-range';

// import 'react-datepicker/dist/react-datepicker.css';
// import ReactDatePicker from 'react-datepicker';

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

// const Example = () => {
//   const [startDate, setStartDate] = useState(new Date());
//   return (
//     <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
//   );
// };

// export const DatePicker = ReactDatePicker;
// export const DatePicker = () => <p>WH</p>;
