/**
 * Takes APR (e.g. 5.6) and returns monthly interest rate
 * @param apr - 5.6
 * @returns 5.6 / 100 / 12 = 0.004666666666666666
 */
export function getMonthlyInterestRate(apr: string | number): number {
  return Number(apr) / 12 / 100;
}

// https://www.kasasa.com/blog/how-to-calculate-loan-payments-in-3-easy-steps
/**
 * Amortizing Loan formulas
 * Loan Payment (P) = Amount (A) / Discount Factor (D)
 *
 * A = Total loan amount
 * D = {[(1 + r)n] - 1} / [r(1 + r)n]
 * Periodic Interest Rate (r) = Annual rate (converted to decimal figure) divided by number of payment periods
 * Number of Periodic Payments (n) = Payments per year multiplied by number of years
 */
export type LoanOptions = {
  principal: number;
  interestRatePerPeriod: number | string;
  numberOfPeriods: number;
};

function getDiscountFactor(
  interestRatePerPeriod: string | number,
  numberOfPeriods: number
) {
  const interest = Number(interestRatePerPeriod);
  const C = Math.pow(1 + interest, numberOfPeriods);
  const D = (C - 1) / (interest * C);
  return D;
}

export function calculateLoanMonthlyPayment({
  principal,
  interestRatePerPeriod,
  numberOfPeriods,
}: LoanOptions): number {
  const D = getDiscountFactor(interestRatePerPeriod, numberOfPeriods);
  const monthlyLoanPayment = principal / D;
  return Number.parseFloat(monthlyLoanPayment.toFixed(2));
}

type MoneyOptions = {
  minimumFractionDigits?: number;
  locale?: string;
  currency?: string;
};

/**
 * Converts 1234 to $1,234
 * @param num
 * @param options
 */
export function formatMoney(
  num: number | string,
  {
    minimumFractionDigits = 2,
    locale = 'en-US',
    currency = 'USD',
  }: MoneyOptions = {}
): string {
  if (typeof num === 'string') {
    num = Number(num);
  }

  if (isNaN(num)) {
    num = 0;
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits,
  }).format(num);
}

type NumberOptions = {
  minimumFractionDigits?: number;
  locale?: string;
};

/**
 * Converts 1234 to 1,234
 * @param num
 */
export function formatNumber(
  num: number,
  { minimumFractionDigits = 0, locale = 'en-US' }: NumberOptions = {}
): string {
  return new Intl.NumberFormat(locale, { minimumFractionDigits }).format(num);
}
