import React from 'react';
import { HStack } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import {
  FiChevronLeft as BackIcon,
  FiChevronRight as ForwardIcon,
} from 'react-icons/fi';

type DynamoPaginatorProps = {
  nextNextToken: string | undefined;
  previousTokens: string[];
  fetchNextPage(): void;
  fetchPreviousPage(): void;
};
const DynamoPaginator = ({
  nextNextToken,
  previousTokens,
  fetchNextPage,
  fetchPreviousPage,
}: DynamoPaginatorProps) => {
  return (
    <HStack mt={5} justify="space-between">
      <Button
        colorScheme={previousTokens.length ? 'blue' : null}
        disabled={previousTokens.length === 0}
        onClick={fetchPreviousPage}
      >
        <BackIcon />
      </Button>

      <Button
        colorScheme={nextNextToken ? 'blue' : null}
        disabled={!nextNextToken}
        onClick={fetchNextPage}
      >
        <ForwardIcon />
      </Button>
    </HStack>
  );
};

export { DynamoPaginator, DynamoPaginatorProps };
