export const hits = [
  {
    objectID: '295720490246342146',
    dealerId: '23703',
    vin: 'WBAJE5C38HG914103',
    year: 2018,
    miles: 48691,
    trim: '540i',
    price: 32180,
    make: 'BMW',
    model: '5 Series',
    // image:
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_ZMRfirnAI5gTQN7V.jpg',
    bodyType: 'Sedan',
    transmission: 'Automatic',
    engine: '3.0L Turbo I6 335hp 332ft. lbs.',
    // hiresPhotos: [
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_EVfnUszdp6lM7C0c.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_ezDTPwkIoncOyj9x.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_gRKhWfk3Zl96zN8L.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_xGwJjK0Ed2UXDY5v.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_aTU6ZcQRzxnvkm05.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_GY0nQIqxtbD1P75S.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_vqFW2f3mIzsNXh4O.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_qSadwzDOnRExPgKC.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_tMgE82wZTGqOna07.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_Hem0MOXysQAnl4vi.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_TibS7gRjBIaQn59X.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_jWY8Q6xt4Mq31nky.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_PJ0Io6n1wUM7QagX.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_TRqSKkhUvdFMCIQb.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_oXDh0kEI9wpz7y1i.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_4MlEaLS7sVwo10Fz.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_P3CF0DL78GBxprYS.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_i049TuEbIFw15mHW.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_nXDUTagCkWRASJzm.jpg',
    //   'https://i.fyu.se/group/idsidtolvejsr4tr/vfhxw5q96l2z4/snaps/img_Tjl7FoIdxtey6EcZ.jpg',
    // ],
    extColor: 'White',
    driveType: 'RWD',
    intColor: 'Black',
    optionalFeatures:
      'Premium Package,Stability Control,Adjustable Steering Wheel,Auto Climate Control,Heated Mirrors,Heated Seats,Leather Seats,Leather Trimmed Interior,Power Locks,Power Mirrors,Power Seat (Dual),Power Steering,Power Windows,Steering Wheel Controls,Sun/Moonroof,Apple Carplay,Air Conditioning,Alloy Wheels,AM/FM,CD (Single Disc),Cruise Control,Daytime Running Lights,Fog Lights,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear-View Camera,Tire Pressure Monitoring System,19 Inch Wheels',
    fuelType: 'Gasoline',
  },
];

// BACKEND ONLY
const loanApplicationEntity = {
  defiApplicationId: '1084',
  defiApplicationDecision: 'AA',
  contractRate: '7.5',
  amountFinanced: '22771.70',
  term: '65',
  payment: '427.38',
  dealerSalesPrice: '32180.00',
  cashDown: '12000.00',
  tradeDown: '12000.00',
  salesTax: '2091.70',
  registrationFee: '500.00',
  participationFee: '80.00',
  status: 'BUYER_SIGNATURE_PENDING',
  buyerId: 'somto@mailinator.com',
  carId: '295720490246342146',
  dealerId: '296226031699755524',
  contractUrl:
    'https://na1.lightico.com/Default.aspx?mt=self&sid=c59dfbd9-4563-460e-a803-08ccdd10a9eb',
};

const loapAppsByBuyerId = {
  data: [
    {
      id: '295576947626344966',
      contractRate: '7.5',
      term: '60',
      payment: '1300',
      dealerSalesPrice: '34000.00',
      cashDown: '10000.00',
      tradeDown: '10000.00',
      salesTax: '5000.00',
      registrationFee: '2000.00',
      participationFee: '80.00',
      contractUrl:
        'https://na1.lightico.com/Default.aspx?mt=self&sid=f1ccacaa-cf8a-4d19-9169-8ef4d86d9814',
      buyerId: 'somto@mailinator.com',
      dealerId: '296226031699755524',
      defiApplicationId: '1035',
      defiApplicationDecision: 'AA',
    },
    {
      id: '296273338815218178',
      defiApplicationId: '1084',
      defiApplicationDecision: 'AA',
      contractRate: '7.5',
      amountFinanced: '22771.70',
      term: '65',
      payment: '427.38',
      dealerSalesPrice: '32180.00',
      cashDown: '12000.00',
      tradeDown: '12000.00',
      salesTax: '2091.70',
      registrationFee: '500.00',
      participationFee: '80.00',
      status: 'BUYER_SIGNATURE_PENDING',
      buyerId: 'somto@mailinator.com',
      carId: '295720490246342146',
      dealerId: '296226031699755524',
      contractUrl:
        'https://na1.lightico.com/Default.aspx?mt=self&sid=c59dfbd9-4563-460e-a803-08ccdd10a9eb',
    },
  ],
  pagination: { afterCursorId: '296357620784038406' },
};

const loanApps = [
  {
    id: '296273338815218178',
    defiApplicationDecision: 'AA',
    contractRate: '7.5',
    amountFinanced: '22771.70',
    term: '65',
    payment: '427.38',
    cashDown: '12000.00',
    status: 'REJECTED_BY_DEALER',
    __typename: 'LoanApplication',
  },
  {
    id: '296357620784038406',
    defiApplicationDecision: 'AA',
    contractRate: '7.5',
    amountFinanced: '29771.70',
    term: '45',
    payment: '761.04',
    cashDown: '5000.00',
    status: 'DEALER_APPROVAL_PENDING',
    __typename: 'LoanApplication',
  },
  {
    id: '296457688659788290',
    defiApplicationDecision: 'AA',
    contractRate: '7.5',
    amountFinanced: '31271.70',
    term: '65',
    payment: '586.91',
    cashDown: '3500.00',
    status: 'BUYER_SIGNATURE_PENDING',
    __typename: 'LoanApplication',
  },
  {
    id: '296488845130596867',
    defiApplicationDecision: 'AA',
    contractRate: '7.5',
    amountFinanced: '10771.70',
    term: '45',
    payment: '275.35',
    cashDown: '24000.00',
    status: 'REJECTED_BY_DEALER',
    __typename: 'LoanApplication',
  },
  {
    id: '296490394203456002',
    defiApplicationDecision: 'AA',
    contractRate: '7.5',
    amountFinanced: '29771.70',
    term: '50',
    payment: '695.15',
    cashDown: '5000.00',
    status: 'REJECTED_BY_DEALER',
    __typename: 'LoanApplication',
  },
];

// export const hits = [
//   {
//     objectID: '1',
//     vin: 'WBA3V7C50G5A25878',
//     year: 2016,
//     miles: 48794,
//     trim: '428i',
//     price: 24230,
//     make: 'BMW',
//     model: '4 Series',
//     image:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_irxme8PVaEslSk7b.jpg',
//     bodyType: 'Convertible',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_DS2dVOq9GH3xpLsJ.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_irxme8PVaEslSk7b.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_irxme8PVaEslSk7b.jpg',
//     subjectLine: '2016 BMW 4 Series',
//     warrantyRemaining: 'Coverage expired',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_DS2dVOq9GH3xpLsJ.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Turbo I4 240hp 255ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_DS2dVOq9GH3xpLsJ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_56WeDYq2OyQ70MG8.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_oDK4wUueyPSfalhs.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_XfsCyjiQlprkJL4E.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_UBaGtqLMJYk4s3iV.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_zNHtpxAksEG3SDCr.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_uY6AVC3k9roSO2Bh.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_twgpibcs67eW5B0z.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_JhnLdS2sbHczE8No.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_5csYbC91OTwD4BZg.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_6b1yUu2RBNoKVCqS.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_HuV3kM6XLYPd4pTB.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_4Zw59xvLuY21c6Rq.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_lwhUtRVP5Mb7X4OJ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_iOg0yjlsPpY2FnBd.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_nWUH73KGxrCpJ9oc.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_rsh2pRH4xWjStnIV.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '4-series',
//     extColor: 'Alpine White',
//     text:
//       '2016 BMW 4 Series WBA3V7C50G5A25878 10429867 A25878 G5A25878 White Alpine White Luxuary Sedan Convertible    ',
//     engId: 2,
//     bodyId: 2041,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 2,
//     roof: 0,
//     nav: 0,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_irxme8PVaEslSk7b.jpg',
//     style: '',
//     optionalFeatures:
//       '4-Wheel Disc Brakes,Anti-Lock Brakes,High Intensity Discharge Headlights,Stability Control,Adjustable Steering Wheel,Auto Climate Control,Dual-Zone Climate Control,Heated Mirrors,Power Mirrors,Power Steering,Power Windows,Rain-Sensing Wipers,Remote Keyless Entry,Seat Memory,Steering Wheel Controls,Vanity Mirror/Light,Air Conditioning,Alloy Wheels,AM/FM,Anti-Theft System,Auto-Dimming Mirror,Automatic Headlights,Braking Assist,CD (Single Disc),Cruise Control,Fog Lights,Handsfree/Bluetooth Integration,Hard Top,HD Radio,Integrated Garage Door Opener,Leather Steering Wheel,Super Ultra Low Emissions Vehicle,Tire Pressure Monitoring System,Traction Control,Turn Signal Mirrors',
//     zone: 'Hotshot!',
//     zoneID: 5959,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_DS2dVOq9GH3xpLsJ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_56WeDYq2OyQ70MG8.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_oDK4wUueyPSfalhs.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_XfsCyjiQlprkJL4E.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_UBaGtqLMJYk4s3iV.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_zNHtpxAksEG3SDCr.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_uY6AVC3k9roSO2Bh.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_twgpibcs67eW5B0z.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_JhnLdS2sbHczE8No.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_5csYbC91OTwD4BZg.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_6b1yUu2RBNoKVCqS.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_HuV3kM6XLYPd4pTB.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_4Zw59xvLuY21c6Rq.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_lwhUtRVP5Mb7X4OJ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_iOg0yjlsPpY2FnBd.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_nWUH73KGxrCpJ9oc.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_rsh2pRH4xWjStnIV.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_Go36xYD58s1UqgLr.jpg',
//         defectType: 'Scratch',
//         location: 'Back Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_q36mOZ7CBuwdFNQH.jpg',
//         defectType: 'Scratch',
//         location: 'Tire Rim',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_MqZTSanlKRtgkbjL.jpg',
//         defectType: 'Scratch',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_6JKRBEt9V4yzQpmP.jpg',
//         defectType: 'Scratch',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/byhgs98mo1puq/snaps/img_Ps0ocFVlEdtzUCaQ.jpg',
//         defectType: 'Scratch',
//         location: 'Tire Rim',
//       },
//     ],
//     ownerCount: 2,
//     cityMpg: 23,
//     highwayMpg: 34,
//     combinedMpg: 27,
//     inventoryId: 10429867,
//     fyusionId: '',
//     frontTrackWidth: 60.8,
//     rearTrackWidth: 62.8,
//     wheelBase: 110.6,
//     width: 79.4,
//     length: 182.6,
//     groundClearance: 0,
//     height: 54.2,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/25383/2/4/color_1280_032/MY2016/10593/10593_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     objectID: '3',
//     vin: 'WBS3R9C58GK339014',
//     year: 2016,
//     miles: 10133,
//     trim: 'Base',
//     price: 44420,
//     make: 'BMW',
//     model: 'M4',
//     image:
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_qlRHdxumODTjWs9F.jpg',
//     bodyType: 'Coupe',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Ewb7IMrpdjlJG9TZ.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_qlRHdxumODTjWs9F.jpg',
//     color: 'Orange',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_qlRHdxumODTjWs9F.jpg',
//     subjectLine: '2016 BMW M4',
//     warrantyRemaining: 'Coverage expired',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Ewb7IMrpdjlJG9TZ.jpg',
//     dvd: 0,
//     transmission: 'Manual',
//     engine: '3.0L Twin Turbo I6 425hp 406ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Ewb7IMrpdjlJG9TZ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_q6WRbapeLsO8iKcH.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_wsMQK1IjPOhrxcb7.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_1MK4dUEmwgJvHk0G.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_QrNk02e3uRJ7M6Ft.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_CP9icb5LRDJa2z1k.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_C95kiV4SAv2MylQj.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_dQBs8OtS4YLGlZzJ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_nMeXysr9HwxkJ4U5.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_DAwnCelcrsSi4aRZ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_RZB1VIqESiv43trp.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_c1L4dCWEpwr8O3nj.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Of7nau1QDSU2w849.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Q4hOZi7GmR0DNvyu.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_GHfeIShtq5WgJNBA.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_uhFy4R1NDnEBlmTZ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_tBnbvCXAYHuZo20R.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_20C5mAVeQJpNWxR3.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_74y8nWPdrfFJpUVQ.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'm4',
//     extColor: 'Orange',
//     text:
//       '2016 BMW M4 WBS3R9C58GK339014 10429578 339014 GK339014 Orange Orange Sedan Coupe   nav navigation ',
//     engId: 4,
//     bodyId: 2048,
//     makeSlug: 'bmw',
//     vehicleType: 'Sedan',
//     doorCount: 2,
//     roof: 0,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_qlRHdxumODTjWs9F.jpg',
//     style: '',
//     optionalFeatures:
//       '4-Wheel Disc Brakes,Anti-Lock Brakes,Stability Control,Adjustable Steering Wheel,Dual-Zone Climate Control,Heated Mirrors,Heated Seats,Leatherette Seats,Power Locks,Power Mirrors,Power Steering,Power Windows,Seat Memory,Steering Wheel Controls,Harman/Kardon Sound System,Navigation System,Air Conditioning,Alloy Wheels,AM/FM,Anti-Theft System,Auto-Dimming Mirror,Automatic Headlights,Braking Assist,CD (Single Disc),Cruise Control,Handsfree/Bluetooth Integration,Leather Steering Wheel,Power Seat (Driver),Rear Parking Sensors,Rear-View Camera,Tire Pressure Monitoring System,Traction Control',
//     zone: 'RA Orlando SI',
//     zoneID: 80293,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Ewb7IMrpdjlJG9TZ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_q6WRbapeLsO8iKcH.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_wsMQK1IjPOhrxcb7.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_1MK4dUEmwgJvHk0G.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_QrNk02e3uRJ7M6Ft.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_CP9icb5LRDJa2z1k.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_C95kiV4SAv2MylQj.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_dQBs8OtS4YLGlZzJ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_nMeXysr9HwxkJ4U5.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_DAwnCelcrsSi4aRZ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_RZB1VIqESiv43trp.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_c1L4dCWEpwr8O3nj.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Of7nau1QDSU2w849.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_Q4hOZi7GmR0DNvyu.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_GHfeIShtq5WgJNBA.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_uhFy4R1NDnEBlmTZ.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_tBnbvCXAYHuZo20R.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_20C5mAVeQJpNWxR3.jpg',
//       'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_74y8nWPdrfFJpUVQ.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_1aTdR9n4xMDBFhYk.jpg',
//         defectType: 'Chip',
//         location: 'Front Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/0e8fnp23g97si8m0/dk7ue5zmbv4to/snaps/img_ZxfBDwhAJHGpVk91.jpg',
//         defectType: 'Scratch',
//         location: 'Driver Side',
//       },
//     ],
//     ownerCount: 4,
//     cityMpg: 0,
//     highwayMpg: 0,
//     combinedMpg: 0,
//     inventoryId: 10429578,
//     fyusionId: '',
//     frontTrackWidth: 62.2,
//     rearTrackWidth: 62.2,
//     wheelBase: 110.7,
//     width: 79.3,
//     length: 183.9,
//     groundClearance: 4.8,
//     height: 54.4,
//     stockLeadFlagPhotoUrl: '',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '28.5542,-81.5557',
//     location: 'RA Orlando',
//   },
//   {
//     objectID: '4',
//     vin: 'WBAJA5C51KWW13750',
//     year: 2019,
//     miles: 38586,
//     trim: '530i',
//     price: 31500,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_0B1egHfszq2Rm3iD.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_RQMK9iTbU8Jr30N7.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_0B1egHfszq2Rm3iD.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_0B1egHfszq2Rm3iD.jpg',
//     subjectLine: '2019 BMW 5 Series',
//     warrantyRemaining: '30 months or 11,414 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_RQMK9iTbU8Jr30N7.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Turbo I4 248hp 258ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_RQMK9iTbU8Jr30N7.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_4UCROnjx9WFvcqQa.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_fO9bFkWHQ1r7546i.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_UEDim8Cs4Q5Y1jVK.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_k95EtQ0jXewihqCa.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_j5u183CF2kBPLnlI.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_9QxGXOe4plks1V6B.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_Wd7ZKBomef2QinYD.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_hAG5pE2DilexNgu1.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_EvGV9gjOsuTlxy15.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_U6elw204qGmHRJSy.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_IVyEgNJuHBkf75AQ.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_R95NcIs73uVKQL6r.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_WoC2ZcdLyJpbRSu3.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_06o8QGPVEvBADHS9.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_mCLyY6hiDv79ERa1.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_IFGr8bAxeocVydpN.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_dLOEn7wt3axJCQcT.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_cAdkJZSeCNuRiPGW.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_pHPxv2kgTBJCc1iY.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'White',
//     text:
//       '2019 BMW 5 Series WBAJA5C51KWW13750 10429486 W13750 KWW13750 White White Sedan Sedan   nav navigation ',
//     engId: 2,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Tan',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_0B1egHfszq2Rm3iD.jpg',
//     style: '',
//     optionalFeatures:
//       'Convenience Package,Stability Control,Auto Climate Control,Heated Seats,Leatherette Seats,Parking Sensors,Power Locks,Power Mirrors,Power Seat (Dual),Power Sun/Moonroof,Power Tailgate,Power Windows,Remote Trunk Release,Sport Seats,Steering Wheel Controls,Apple Carplay,Navigation System,18 Inch Wheels,Air Conditioning,Alloy Wheels,CD (Single Disc),Cruise Control,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear-View Camera',
//     zone: 'RA Fontana SI',
//     zoneID: 80291,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_RQMK9iTbU8Jr30N7.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_4UCROnjx9WFvcqQa.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_fO9bFkWHQ1r7546i.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_UEDim8Cs4Q5Y1jVK.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_k95EtQ0jXewihqCa.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_j5u183CF2kBPLnlI.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_9QxGXOe4plks1V6B.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_Wd7ZKBomef2QinYD.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_hAG5pE2DilexNgu1.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_EvGV9gjOsuTlxy15.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_U6elw204qGmHRJSy.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_IVyEgNJuHBkf75AQ.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_R95NcIs73uVKQL6r.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_WoC2ZcdLyJpbRSu3.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_06o8QGPVEvBADHS9.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_mCLyY6hiDv79ERa1.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_IFGr8bAxeocVydpN.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_dLOEn7wt3axJCQcT.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_cAdkJZSeCNuRiPGW.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_pHPxv2kgTBJCc1iY.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_Ks9icFmvMjVWB4we.jpg',
//         defectType: 'Scratch',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_ag8XQsk9hWTZblFi.jpg',
//         defectType: 'Chip',
//         location: 'Front Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_X6hYoEZ7UuzlHJn3.jpg',
//         defectType: 'Chip',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_GsPW31ztQidY5Tfc.jpg',
//         defectType: 'Scratch',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/8nwcz9pi6bvlm/snaps/img_q71muejDFIHxJ6lv.jpg',
//         defectType: 'Scratch',
//         location: 'Passenger Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 24,
//     highwayMpg: 34,
//     combinedMpg: 27,
//     inventoryId: 10429486,
//     fyusionId: '',
//     frontTrackWidth: 63,
//     rearTrackWidth: 64.2,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 194.6,
//     groundClearance: 5.7,
//     height: 58.2,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/28233/2/4/color_1280_032/MY2019/13389/13389_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '34.0453,-117.5539',
//     location: 'RA Fontana',
//   },
//   {
//     vin: 'WBAJE5C39HG914014',
//     year: 2017,
//     miles: 46229,
//     trim: '540i',
//     price: 35100,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_JOaiuDPhMw9tZdqX.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_YQ1iMgmEZN5DOfTG.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_JOaiuDPhMw9tZdqX.jpg',
//     color: 'Grey',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_JOaiuDPhMw9tZdqX.jpg',
//     subjectLine: '2017 BMW 5 Series',
//     warrantyRemaining: '10 months or 3,771 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_YQ1iMgmEZN5DOfTG.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 335hp 332ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_YQ1iMgmEZN5DOfTG.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_ReHuF7aKkUgDyvJA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_vx18ZrpUHQ0f473L.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_x4pVeOUGlLrdHo8C.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_vUWt5R0sdQMhoeLa.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_7XMRFc0mnHlLQPxE.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_LaAhWR9XFcf6ICrZ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_bKavT02OWc8yDZQY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_c3FvtN0bGZS1XDp4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_DGs5mM3fWSbic7TZ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_Z2YqpsXHdygTjEu1.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_ZtfFXHRPGv7BJaUk.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_2SI6ZiHAWOnryzEx.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_wD1YJoyHXaEmpQt2.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_KjnrwhPIMdfx52Z8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_EGKLhgtYdkVbF9cZ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_HNV5E1PMdXLBgzAh.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_NYw9EpTflOd3Gyih.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_zDCiUn9WOqRHGxs4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_cLTVIFYvOK75bXo6.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'Grey',
//     text:
//       '2017 BMW 5 Series WBAJE5C39HG914014 10429318 914014 HG914014 Grey Grey Luxuary Sedan Sedan   nav navigation ',
//     engId: 4,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_JOaiuDPhMw9tZdqX.jpg',
//     style: '',
//     optionalFeatures:
//       'M Sport Package,Auto Climate Control,Dual-Zone Climate Control,Heated Seats,Heated Steering Wheel,Leather Seats,Power Locks,Power Mirrors,Power Seat (Dual),Power Sun/Moonroof,Power Windows,Steering Wheel Controls,Wood Trimmed Interior,Navigation System,Alloy Wheels,AM/FM,CD (Single Disc),Cruise Control,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear Parking Sensors,Rear-View Camera,Paddle Shifter',
//     zone: 'RA San Francisco SI',
//     zoneID: 80294,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_YQ1iMgmEZN5DOfTG.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_ReHuF7aKkUgDyvJA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_vx18ZrpUHQ0f473L.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_x4pVeOUGlLrdHo8C.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_vUWt5R0sdQMhoeLa.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_7XMRFc0mnHlLQPxE.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_LaAhWR9XFcf6ICrZ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_bKavT02OWc8yDZQY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_c3FvtN0bGZS1XDp4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_DGs5mM3fWSbic7TZ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_Z2YqpsXHdygTjEu1.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_ZtfFXHRPGv7BJaUk.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_2SI6ZiHAWOnryzEx.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_wD1YJoyHXaEmpQt2.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_KjnrwhPIMdfx52Z8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_EGKLhgtYdkVbF9cZ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_HNV5E1PMdXLBgzAh.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_NYw9EpTflOd3Gyih.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_zDCiUn9WOqRHGxs4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_cLTVIFYvOK75bXo6.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_U8lm1VQGPqDyajHB.jpg',
//         defectType: 'Chip',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_DQ47Pqfgz192dTmY.jpg',
//         defectType: 'Scratch',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_7kRlnTdDhCwUJ6MF.jpg',
//         defectType: 'Scratch',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/7p6uaykjot4qc/snaps/img_MNkdpKE4U80J2wxQ.jpg',
//         defectType: 'Dent',
//         location: 'Back Bumper',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 20,
//     highwayMpg: 30,
//     combinedMpg: 24,
//     inventoryId: 10429318,
//     fyusionId: '',
//     frontTrackWidth: 63,
//     rearTrackWidth: 63.9,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 194.6,
//     groundClearance: 5.7,
//     height: 58.2,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26862/2/4/color_1280_032/MY2017/11982/11982_cc1280_032_A90.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '37.6153,-122.1389',
//     location: 'RA San Francisco',
//   },
//   {
//     vin: 'WBAJA9C50JG622942',
//     year: 2018,
//     miles: 24070,
//     trim: '530e iPerformance',
//     price: 35770,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_L43QT9O0AlEYqfGZ.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_YQ23ZU9hMqBbEaz0.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_L43QT9O0AlEYqfGZ.jpg',
//     color: 'Grey',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_L43QT9O0AlEYqfGZ.jpg',
//     subjectLine: '2018 BMW 5 Series',
//     warrantyRemaining: '11 months or 25,930 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_YQ23ZU9hMqBbEaz0.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Plug-in Hybrid Turbo I4 248hp 310ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_YQ23ZU9hMqBbEaz0.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_nNWFYcTzm1SqJAKQ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_WDBpMc2hoTus8ZCk.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_5x0wabWRd7EcfIY8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_Mcf6DXbtROHZPnFY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_y5sIFUMh07X1rZKS.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_xPel6nBvmVQouKwb.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_fgKFPZh2znp8rcVB.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_XlNo93DbGJaQUpFx.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_y03Kiu6xrQpNBCLD.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_KRGXBQZlJF0awq64.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_2ZTbAMBwuC7ra0LY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_qzEeF9y0UL34lOKW.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_NRxdLCqEmr9hMVU4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_FuhyEC79nmrM5HIU.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_i2zk7bamtJEBl3u1.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_ZS68RjgcmIXVYPnr.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_PX4N81s9GdMtryUl.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_uHGwagUTntoKMX8R.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_dq50fsrJPCYARwED.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'Grey',
//     text:
//       '2018 BMW 5 Series WBAJA9C50JG622942 10429314 622942 JG622942 Grey Grey Sedan Sedan   nav navigation ',
//     engId: 2,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Plug-in Hybrid',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_L43QT9O0AlEYqfGZ.jpg',
//     style: '',
//     optionalFeatures:
//       'Driver Assistance Package,Premium Package,4-Wheel Disc Brakes,Adaptive Cruise Control,Anti-Lock Brakes,Blind Spot Monitor,Cross Traffic Alert,Stability Control,Adjustable Steering Wheel,Bucket Seats,Dual-Zone Climate Control,Heated Seats,Parking Assist System,Parking Sensors,Power Locks,Power Mirrors,Power Steering,Power Tailgate,Power Windows,Steering Wheel Controls,Sun/Moonroof,Harman/Kardon Sound System,Navigation System,Air Conditioning,Alloy Wheels,AM/FM,Automatic Headlights,Braking Assist,Daytime Running Lights,Fog Lights,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Power Seat (Driver),Rear-View Camera,Tire Pressure Monitoring System',
//     zone: 'RA San Francisco SI',
//     zoneID: 80294,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_YQ23ZU9hMqBbEaz0.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_nNWFYcTzm1SqJAKQ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_WDBpMc2hoTus8ZCk.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_5x0wabWRd7EcfIY8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_Mcf6DXbtROHZPnFY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_y5sIFUMh07X1rZKS.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_xPel6nBvmVQouKwb.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_fgKFPZh2znp8rcVB.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_XlNo93DbGJaQUpFx.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_y03Kiu6xrQpNBCLD.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_KRGXBQZlJF0awq64.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_2ZTbAMBwuC7ra0LY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_qzEeF9y0UL34lOKW.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_NRxdLCqEmr9hMVU4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_FuhyEC79nmrM5HIU.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_i2zk7bamtJEBl3u1.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_ZS68RjgcmIXVYPnr.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_PX4N81s9GdMtryUl.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_uHGwagUTntoKMX8R.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_dq50fsrJPCYARwED.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_U2MymwVin5kzNqvI.jpg',
//         defectType: 'Chip',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_HWkuFawK7evTEO90.jpg',
//         defectType: 'Chip',
//         location: 'Front Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_DcnyfKTCaPO5Yt3w.jpg',
//         defectType: 'Dent',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_B5MkWCmfroqF1GHt.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/kcs5i836zjme4/snaps/img_UbBtkcHrDEIP1RFL.jpg',
//         defectType: 'Chip',
//         location: 'Back Bumper',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 0,
//     highwayMpg: 0,
//     combinedMpg: 0,
//     inventoryId: 10429314,
//     fyusionId: '',
//     frontTrackWidth: 62.9,
//     rearTrackWidth: 64.2,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 194.6,
//     groundClearance: 5.9,
//     height: 58.4,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26968/2/4/color_1280_032/MY2018/12049/12049_cc1280_032_B65.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '37.6153,-122.1389',
//     location: 'RA San Francisco',
//   },
//   {
//     vin: 'WBALL5C51GP557944',
//     year: 2016,
//     miles: 6191,
//     trim: 'sDrive28i',
//     price: 30080,
//     make: 'BMW',
//     model: 'Z4',
//     image:
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_3ix28HpLPcKb1RzO.jpg',
//     bodyType: 'Convertible',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_PADJ75qWLhtYdVev.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_3ix28HpLPcKb1RzO.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_3ix28HpLPcKb1RzO.jpg',
//     subjectLine: '2016 BMW Z4',
//     warrantyRemaining: 'Coverage expired',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_PADJ75qWLhtYdVev.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Turbo I4 240hp 260ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_PADJ75qWLhtYdVev.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_SQqbv4l0hYdaKfX7.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_5UPQw6XETcYbyARG.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_IkEJayRVMO3Fe2vX.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_KSAGvZxFq3LohPue.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_f6zdY2r4N39sVpSF.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_XzSFOo3fYMDLweyg.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_qBAuzXg8MrKJ5OUS.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_WmUQqZkl2GoCyYEF.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_lMbhsLiwxrWtSB1q.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_ip6wBT0cER2L79r5.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_KgXtWFYjCB8SAMRs.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_LXQjJCPzRfVNZTk0.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_8ArukyzHRYdDCwM7.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_3ALxR5ijlksMrUw9.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_VUqIdcXBe8bJW6nl.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'z4',
//     extColor: 'White',
//     text:
//       '2016 BMW Z4 WBALL5C51GP557944 10429213 557944 GP557944 White White Luxuary Sedan Convertible   nav navigation ',
//     engId: 2,
//     bodyId: 2041,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 2,
//     roof: 0,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Tan',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_3ix28HpLPcKb1RzO.jpg',
//     style: '',
//     optionalFeatures:
//       'Technology Package,High Intensity Discharge Headlights,Heated Seats,Leatherette Seats,Power Seat (Dual),Seat Memory,Sport Steering Wheel,Aux Audio Adapter,Navigation System,Voice-Activated Command System,Alloy Wheels,Automatic Headlights,CD (Single Disc),Handsfree/Bluetooth Integration,Hard Top,HD Radio,Leather Steering Wheel,Satellite Radio,USB Adapter,Paddle Shifter',
//     zone: 'RA Denver SI',
//     zoneID: 80295,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_PADJ75qWLhtYdVev.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_SQqbv4l0hYdaKfX7.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_5UPQw6XETcYbyARG.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_IkEJayRVMO3Fe2vX.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_KSAGvZxFq3LohPue.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_f6zdY2r4N39sVpSF.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_XzSFOo3fYMDLweyg.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_qBAuzXg8MrKJ5OUS.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_WmUQqZkl2GoCyYEF.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_lMbhsLiwxrWtSB1q.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_ip6wBT0cER2L79r5.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_KgXtWFYjCB8SAMRs.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_LXQjJCPzRfVNZTk0.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_8ArukyzHRYdDCwM7.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_3ALxR5ijlksMrUw9.jpg',
//       'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_VUqIdcXBe8bJW6nl.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_i7aU8H0yljmeYcAq.jpg',
//         defectType: 'Dent',
//         location: 'Back Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/4nj2qgwqumj13bbl/8vasymcr5n2df/snaps/img_WxwdOC4Ys7L8KeaE.jpg',
//         defectType: 'Scratch',
//         location: 'Front Bumper',
//       },
//     ],
//     ownerCount: 3,
//     cityMpg: 0,
//     highwayMpg: 0,
//     combinedMpg: 0,
//     inventoryId: 10429213,
//     fyusionId: '',
//     frontTrackWidth: 59.5,
//     rearTrackWidth: 61.4,
//     wheelBase: 98.3,
//     width: 76.8,
//     length: 167,
//     groundClearance: 0,
//     height: 50.8,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/25334/2/4/color_1280_032/MY2016/10565/10565_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '39.7586,-104.7859',
//     location: 'RA Denver',
//   },
//   {
//     vin: 'WBY1Z4C53FV505016',
//     year: 2015,
//     miles: 38040,
//     trim: 'Base',
//     price: 14980,
//     make: 'BMW',
//     model: 'i3',
//     image:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_oAkbwcSvMlmi2fLZ.jpg',
//     bodyType: 'Hatchback',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_17dBC0yqnIkPN8il.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_oAkbwcSvMlmi2fLZ.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_oAkbwcSvMlmi2fLZ.jpg',
//     subjectLine: '2015 BMW i3',
//     warrantyRemaining: 'Coverage expired',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_17dBC0yqnIkPN8il.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: 'Range-Extended Electric 170hp 184ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_17dBC0yqnIkPN8il.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_f5qgSQvoUwiNZCxB.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_UnFKOsWk2fN8MiSI.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_V6URfQFbHrTXh1Dc.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_rT57dvGKH0kNiByw.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_DT4qezNsipbPKy7U.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_DHB1uWTveGAKEloq.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_iHckBgKEY8ywAfLj.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_ihXLE2FQdBcN4P9M.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_QCNpS9VIBZMtow0r.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_J5bRQo6HI1hTcuxX.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_mflHRQdOc0JWKkpB.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_Sp3GmJuKyj1WHbwE.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_Dl3yzkP9vNAewF46.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_pweQbva5SuoxlDE6.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_qmVGBsKbFpnHeNfE.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_p3Fr4JlYSNxX8qzW.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'i3',
//     extColor: 'White',
//     text:
//       '2015 BMW i3 WBY1Z4C53FV505016 10429136 505016 FV505016 White White Luxuary Sedan Hatchback   nav navigation ',
//     engId: 0,
//     bodyId: 10174,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 4,
//     roof: 0,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Gray',
//     cylinders: 2,
//     awd: 0,
//     fuelType: 'Plug-in Hybrid',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_oAkbwcSvMlmi2fLZ.jpg',
//     style: '',
//     optionalFeatures:
//       'Mega World Package,Anti-Lock Brakes,LED Lights - Low Beam,LED Tail Lights,Stability Control,Auto Climate Control,Heated Seats,Leatherette Seats,Power Mirrors,Power Windows,Rain-Sensing Wipers,Rear Window Wiper,Steering Wheel Controls,Aux Audio Adapter,Navigation System,AM/FM,Automatic Headlights,Cloth Seats,Cruise Control,Daytime Running Lights,Handsfree/Bluetooth Integration,HD Radio,Rear Parking Sensors,Satellite Radio,Tire Pressure Monitoring System,Traction Control,Turn Signal Mirrors,USB Adapter,19 Inch Wheels,Rear Spoiler',
//     zone: 'R - Service Drive',
//     zoneID: 80207,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_17dBC0yqnIkPN8il.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_f5qgSQvoUwiNZCxB.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_UnFKOsWk2fN8MiSI.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_V6URfQFbHrTXh1Dc.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_rT57dvGKH0kNiByw.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_DT4qezNsipbPKy7U.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_DHB1uWTveGAKEloq.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_iHckBgKEY8ywAfLj.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_ihXLE2FQdBcN4P9M.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_QCNpS9VIBZMtow0r.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_J5bRQo6HI1hTcuxX.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_mflHRQdOc0JWKkpB.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_Sp3GmJuKyj1WHbwE.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_Dl3yzkP9vNAewF46.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_pweQbva5SuoxlDE6.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_qmVGBsKbFpnHeNfE.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_p3Fr4JlYSNxX8qzW.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_jk80cuxHtqCwy16Y.jpg',
//         defectType: 'Chip',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/iq8jw4ovn2xfa9if/89zsra7ucg4hx/snaps/img_YH1Fl3kGECyqpArt.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//     ],
//     ownerCount: 2,
//     cityMpg: 0,
//     highwayMpg: 0,
//     combinedMpg: 0,
//     inventoryId: 10429136,
//     fyusionId: '',
//     frontTrackWidth: 61.8,
//     rearTrackWidth: 61.3,
//     wheelBase: 101.2,
//     width: 70,
//     length: 157.8,
//     groundClearance: 5.5,
//     height: 62.1,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/25101/2/4/color_1280_032/MY2015/10365/10365_cc1280_032_B85.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: '5UXKR0C58F0P03519',
//     year: 2015,
//     miles: 39821,
//     trim: 'xDrive35i',
//     price: 27470,
//     make: 'BMW',
//     model: 'X5',
//     image:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_3AsYq7PQTgCV4Jfy.jpg',
//     bodyType: 'SUV',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_vfnsAqyemiEl9FZY.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_3AsYq7PQTgCV4Jfy.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_3AsYq7PQTgCV4Jfy.jpg',
//     subjectLine: '2015 BMW X5',
//     warrantyRemaining: 'Coverage expired',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_vfnsAqyemiEl9FZY.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Twin Turbo I6 300hp 295ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_vfnsAqyemiEl9FZY.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_Zru6k4PetGzXChcU.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_XtokMQaFg9d7vxwn.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_V2rm6GDIstxdNbyJ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_Qxruz6c5f8KIbmk7.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_YgGqcd9R1rAFO0P8.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_JoqvtxeSHCjPBnmZ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_6wtlPvJDunxbQkym.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_OrDHTyKxm0gRUAQh.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_yQNu5dwsWnVAqrH3.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_Mkg7aRFvhsBDLn3t.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_F1r8MRP96NSoY2Xx.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_wBFSKusACd72gV9D.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_1BmnXEtj46xvfRLu.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_6UXOS24rvNcpLYqa.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_jYy5mbdMHvxiseT9.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_M5ebEJPFdK79izR3.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_mK92BcPyUNEYF6dZ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_w4UnBeHZjE7RoMVA.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_QoGl0VmM62JiuUth.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'x5',
//     extColor: 'Alpine White',
//     text:
//       '2015 BMW X5 5UXKR0C58F0P03519 10429100 P03519 F0P03519 White Alpine White Large SUV SUV   nav navigation ',
//     engId: 4,
//     bodyId: 2042,
//     makeSlug: 'bmw',
//     vehicleType: 'Large SUV',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_3AsYq7PQTgCV4Jfy.jpg',
//     style: '',
//     optionalFeatures:
//       'Start-Stop System,Ambient Lighting,Auto Climate Control,Dual-Pane Panoramic Sunroof,Dual-Zone Climate Control,Front Parking Sensors,Heated Seats,Leatherette Seats,Parking Sensors,Power Adjustable Steering Wheel,Power Locks,Power Mirrors,Power Windows,Rear Air Ducts,Remote Keyless Entry,Steering Wheel Controls,Navigation System,18 Inch Wheels,CD (Single Disc),Cruise Control,Fog Lights,Handsfree/Bluetooth Integration,Keyless Ignition,Leather Steering Wheel,Rear Parking Sensors,Rear-View Camera,Roof Rails,Dual Exhaust,Turbocharger',
//     zone: 'Hotshot!',
//     zoneID: 5959,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_vfnsAqyemiEl9FZY.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_Zru6k4PetGzXChcU.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_XtokMQaFg9d7vxwn.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_V2rm6GDIstxdNbyJ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_Qxruz6c5f8KIbmk7.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_YgGqcd9R1rAFO0P8.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_JoqvtxeSHCjPBnmZ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_6wtlPvJDunxbQkym.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_OrDHTyKxm0gRUAQh.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_yQNu5dwsWnVAqrH3.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_Mkg7aRFvhsBDLn3t.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_F1r8MRP96NSoY2Xx.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_wBFSKusACd72gV9D.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_1BmnXEtj46xvfRLu.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_6UXOS24rvNcpLYqa.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_jYy5mbdMHvxiseT9.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_M5ebEJPFdK79izR3.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_mK92BcPyUNEYF6dZ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_w4UnBeHZjE7RoMVA.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_QoGl0VmM62JiuUth.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_6Za80zOQtRqh3LY4.jpg',
//         defectType: 'Dent',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_WhGq7lIPSCdvLjax.jpg',
//         defectType: 'Chip',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_B5l6eX1ndHfOVJRQ.jpg',
//         defectType: 'Scratch',
//         location: 'Back Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_XpdmQbEKwH6k2g39.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/rytbsg4o350eq/snaps/img_8LEX14ngiUFtW3ZJ.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//     ],
//     ownerCount: 2,
//     cityMpg: 18,
//     highwayMpg: 27,
//     combinedMpg: 21,
//     inventoryId: 10429100,
//     fyusionId: '',
//     frontTrackWidth: 64.7,
//     rearTrackWidth: 65,
//     wheelBase: 115.5,
//     width: 86.5,
//     length: 191.1,
//     groundClearance: 8.3,
//     height: 69.9,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/24606/2/4/color_1280_032/MY2015/10039/10039_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: 'WBA4U1C54H5A16087',
//     year: 2017,
//     miles: 25931,
//     trim: '440i xDrive',
//     price: 41480,
//     make: 'BMW',
//     model: '4 Series',
//     image:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_I6r5kxhDOwuSPj9E.jpg',
//     bodyType: 'Convertible',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_kKp0sqY1MAJPzVIy.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_I6r5kxhDOwuSPj9E.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_I6r5kxhDOwuSPj9E.jpg',
//     subjectLine: '2017 BMW 4 Series',
//     warrantyRemaining: '5 months or 24,069 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_kKp0sqY1MAJPzVIy.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 320hp 330ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_kKp0sqY1MAJPzVIy.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_1Wn6Hm7raR8DsUNF.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_faVmys7HnxvUoJlM.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_5ezurqmJXGc2iRBs.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_iRv7zyF039Htpl8o.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_w9uMdHBb5Up1OitW.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_tUd0xT6uM2LVnJSI.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_cnUHdz6oIeYf5mWb.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_86czGUJkXouD9LRB.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_SBs3a9rkw7RdN1h5.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_ZQIvENxRasim1eT0.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_LO316UASEp4cebXj.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_Kj54agod9LXyTzle.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_aAhOdKGTNQeBXJpt.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_9ima6oNTsbtCUXcL.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_wcRqXo9u5Q2EOZeW.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_s7cHJNKjZUk4XmRA.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_O7ZswKJPbrljikT8.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '4-series',
//     extColor: 'Alpine White',
//     text:
//       '2017 BMW 4 Series WBA4U1C54H5A16087 10429091 A16087 H5A16087 White Alpine White Luxuary Sedan Convertible   nav navigation ',
//     engId: 4,
//     bodyId: 2041,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 2,
//     roof: 0,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'White',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_I6r5kxhDOwuSPj9E.jpg',
//     style: '',
//     optionalFeatures:
//       'Driver Assistance Plus Package,M Sport Package,Adaptive Headlights,Blind Spot Monitor,Emergency Braking Preparation,Headlight Washers,High Intensity Discharge Headlights,Lane Departure Warning,Precrash System,Rear Cross Traffic Alert,Start-Stop System,Traffic Sign Recognition,Adjustable Lumbar Support,Auto Climate Control,Body Colored Door Handles,Color Coded Mirrors,Dual-Zone Climate Control,Handsfree Power Liftgate,Heated Seats,Heated Steering Wheel,Leather Seats,Parking Sensors,Power Locks,Power Mirrors,Power Seat (Dual),Power Top,Power Windows,Seat Memory,Steering Wheel Controls,Wood Trimmed Interior,Aux Audio Adapter,Harman/Kardon Sound System,Navigation System,Alloy Wheels,Auto-Dimming Mirror,CD (Single Disc),Cruise Control,Fog Lights,Handsfree/Bluetooth Integration,HD Radio,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Satellite Radio,Turn Signal Mirrors,USB Adapter,19 Inch Wheels,Paddle Shifter,Sport Suspension',
//     zone: 'EZ-13',
//     zoneID: 774783,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_kKp0sqY1MAJPzVIy.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_1Wn6Hm7raR8DsUNF.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_faVmys7HnxvUoJlM.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_5ezurqmJXGc2iRBs.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_iRv7zyF039Htpl8o.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_w9uMdHBb5Up1OitW.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_tUd0xT6uM2LVnJSI.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_cnUHdz6oIeYf5mWb.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_86czGUJkXouD9LRB.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_SBs3a9rkw7RdN1h5.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_ZQIvENxRasim1eT0.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_LO316UASEp4cebXj.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_Kj54agod9LXyTzle.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_aAhOdKGTNQeBXJpt.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_9ima6oNTsbtCUXcL.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_wcRqXo9u5Q2EOZeW.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_s7cHJNKjZUk4XmRA.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/kh90q5oceznwf/snaps/img_O7ZswKJPbrljikT8.jpg',
//     ],
//     defectPhotos: null,
//     ownerCount: 1,
//     cityMpg: 20,
//     highwayMpg: 30,
//     combinedMpg: 24,
//     inventoryId: 10429091,
//     fyusionId: '',
//     frontTrackWidth: 60.8,
//     rearTrackWidth: 62.8,
//     wheelBase: 110.6,
//     width: 79.4,
//     length: 182.6,
//     groundClearance: 0,
//     height: 54.5,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26288/2/4/color_1280_032/MY2017/11457/11457_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: '5UXKT0C39H0V97651',
//     year: 2017,
//     miles: 42013,
//     trim: 'xDrive40e iPerformance',
//     price: 36610,
//     make: 'BMW',
//     model: 'X5',
//     image:
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_VOexaCLv02i9KXNt.jpg',
//     bodyType: 'SUV',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_xPA8m6Zr7YOGgkIF.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_VOexaCLv02i9KXNt.jpg',
//     color: 'Black',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_VOexaCLv02i9KXNt.jpg',
//     subjectLine: '2017 BMW X5',
//     warrantyRemaining: '15 months or 7,987 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_xPA8m6Zr7YOGgkIF.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Plug-in Hybrid Turbo I4 308hp 332ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_xPA8m6Zr7YOGgkIF.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_i3YWvKq40yNUpf5z.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_Vaeyoci7h8wkG1sF.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_qO8nQwFZ3jurykLS.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_hpG8WXOfynsmgHlw.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_blmgC4DRBToa2sw6.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_M0rF8Uhl6mYdoOGE.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_qEvdpo6J2KVGjOkx.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_gpBjt5whRDOxv31Y.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_9fatHlQzn04yAkEh.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_VW5tK8Q4EphJFZbN.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_UbSc6x4V9vZLnfY5.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_ZbIBr4Anhe7TJq6f.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_HsK0tghxz2a7FuYB.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_V0IZ9JEK8oWiAsDk.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_ZUrNGd4IR52npqc6.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_kSqtb4ZAKGgwViF7.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_gG7YI3V9fQoyUHzF.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_fspXtrn31zEyKoZ2.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_o78LCnPHFy6kSpAZ.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'x5',
//     extColor: 'Black',
//     text:
//       '2017 BMW X5 5UXKT0C39H0V97651 10428985 V97651 H0V97651 Black Black Large SUV SUV   nav navigation ',
//     engId: 2,
//     bodyId: 2042,
//     makeSlug: 'bmw',
//     vehicleType: 'Large SUV',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Black',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Plug-in Hybrid',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_VOexaCLv02i9KXNt.jpg',
//     style: '',
//     optionalFeatures:
//       'Cold Weather Package,M Sport Package,Ambient Lighting,Auto Climate Control,Dual-Pane Panoramic Sunroof,Front Parking Sensors,Heated Seats,Heated Steering Wheel,Parking Sensors,Power Adjustable Steering Wheel,Power Seat (Dual),Rear Air Ducts,Rear Heated Seats,Remote Keyless Entry,Seat Memory,Steering Wheel Controls,Aux Audio Adapter,Navigation System,Automatic Headlights,Cargo Cover,CD (Single Disc),Cruise Control,Fog Lights,Handsfree/Bluetooth Integration,Keyless Ignition,Leather Steering Wheel,Rear Parking Sensors,Rear-View Camera,Roof Rails,USB Adapter,Dual Exhaust,Paddle Shifter',
//     zone: 'RA Pennsylvania SI',
//     zoneID: 80338,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_xPA8m6Zr7YOGgkIF.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_i3YWvKq40yNUpf5z.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_Vaeyoci7h8wkG1sF.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_qO8nQwFZ3jurykLS.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_hpG8WXOfynsmgHlw.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_blmgC4DRBToa2sw6.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_M0rF8Uhl6mYdoOGE.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_qEvdpo6J2KVGjOkx.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_gpBjt5whRDOxv31Y.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_9fatHlQzn04yAkEh.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_VW5tK8Q4EphJFZbN.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_UbSc6x4V9vZLnfY5.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_ZbIBr4Anhe7TJq6f.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_HsK0tghxz2a7FuYB.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_V0IZ9JEK8oWiAsDk.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_ZUrNGd4IR52npqc6.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_kSqtb4ZAKGgwViF7.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_gG7YI3V9fQoyUHzF.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_fspXtrn31zEyKoZ2.jpg',
//       'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_o78LCnPHFy6kSpAZ.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_J5VlHhcXi3eGFnIv.jpg',
//         defectType: 'Dent',
//         location: 'Front Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/18rcj86uyobd7gct/v0bp761toqz8a/snaps/img_GH1WrnUpXRwyftFk.jpg',
//         defectType: 'Scratch',
//         location: 'Trunk',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 0,
//     highwayMpg: 0,
//     combinedMpg: 0,
//     inventoryId: 10428985,
//     fyusionId: '',
//     frontTrackWidth: 64.6,
//     rearTrackWidth: 64.7,
//     wheelBase: 115.5,
//     width: 86,
//     length: 193.2,
//     groundClearance: 8.2,
//     height: 69.4,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26629/2/4/color_1280_032/MY2017/11763/11763_cc1280_032_668.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '40.1432,-76.3878',
//     location: 'RA Pennsylvania',
//   },
//   {
//     vin: 'WBA8A3C58GK689032',
//     year: 2016,
//     miles: 47098,
//     trim: '320i xDrive',
//     price: 17370,
//     make: 'BMW',
//     model: '3 Series',
//     image:
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_gX2ZBN4QSriK3pml.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_OuNbayGVCp7i09Xf.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_gX2ZBN4QSriK3pml.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_gX2ZBN4QSriK3pml.jpg',
//     subjectLine: '2016 BMW 3 Series',
//     warrantyRemaining: 'Coverage expired',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_OuNbayGVCp7i09Xf.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Turbo I4 180hp 200ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_OuNbayGVCp7i09Xf.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_RXnxJrzM7E29KAgD.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_LyXw1vSKdfj2NIqR.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_zPBupHSIg9UeD3Tx.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_Fvq9IaXpc7sy3YRS.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_L34IXAUKuoE2ZaC9.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_wJ3QDi1a459dmkpb.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_5Q2wNlBFMrG1Tybs.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_TfIQ9UvqKh8EuVoL.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_wxS3geT1XF7tnikK.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_Fd7y5GEvfsenuO1x.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_3RI9yS4xnUB2dFYV.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_v3sYwyb2nGBSJFma.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_vDneHpP3yRw4FCrf.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_ZngEByTr6v48fcsI.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_0Ovy4xademu5VX1n.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_L3VvzoysATU6ONdM.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_tg5Pskcrh3AFbf8p.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_sHMZ74iLBc5lNjI6.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '3-series',
//     extColor: 'Alpine White',
//     text:
//       '2016 BMW 3 Series WBA8A3C58GK689032 10428858 689032 GK689032 White Alpine White Luxuary Sedan Sedan    ',
//     engId: 2,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 0,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'White',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_gX2ZBN4QSriK3pml.jpg',
//     style: '',
//     optionalFeatures:
//       'Adjustable Steering Wheel,Auto Climate Control,Dual-Zone Climate Control,Heated Seats,Leatherette Seats,Power Locks,Power Mirrors,Power Sun/Moonroof,Power Windows,Rain-Sensing Wipers,Rear Air Ducts,Rear Center Armrest,Remote Keyless Entry,Steering Wheel Controls,Aux Audio Adapter,Alloy Wheels,Automatic Headlights,CD (Single Disc),Cruise Control,Fog Lights,Handsfree/Bluetooth Integration,HD Radio,Keyless Ignition,Leather Steering Wheel,USB Adapter,17 Inch Wheels',
//     zone: 'RA Pennsylvania SI',
//     zoneID: 80338,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_OuNbayGVCp7i09Xf.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_RXnxJrzM7E29KAgD.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_LyXw1vSKdfj2NIqR.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_zPBupHSIg9UeD3Tx.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_Fvq9IaXpc7sy3YRS.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_L34IXAUKuoE2ZaC9.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_wJ3QDi1a459dmkpb.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_5Q2wNlBFMrG1Tybs.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_TfIQ9UvqKh8EuVoL.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_wxS3geT1XF7tnikK.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_Fd7y5GEvfsenuO1x.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_3RI9yS4xnUB2dFYV.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_v3sYwyb2nGBSJFma.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_vDneHpP3yRw4FCrf.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_ZngEByTr6v48fcsI.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_0Ovy4xademu5VX1n.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_L3VvzoysATU6ONdM.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_tg5Pskcrh3AFbf8p.jpg',
//       'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_sHMZ74iLBc5lNjI6.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_FpDiX3MYQPW9NU2a.jpg',
//         defectType: 'Dent',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/qqedq3r26p2vomsx/kdio3a6wfx4y5/snaps/img_3vAYRhgfQVTE0lm2.jpg',
//         defectType: 'Scratch',
//         location: 'Passenger Side',
//       },
//     ],
//     ownerCount: 2,
//     cityMpg: 23,
//     highwayMpg: 35,
//     combinedMpg: 27,
//     inventoryId: 10428858,
//     fyusionId: '',
//     frontTrackWidth: 60.7,
//     rearTrackWidth: 61.9,
//     wheelBase: 110.6,
//     width: 80,
//     length: 182.5,
//     groundClearance: 0,
//     height: 56.5,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/25420/2/4/color_1280_032/MY2016/10632/10632_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '40.1432,-76.3878',
//     location: 'RA Pennsylvania',
//   },
//   {
//     vin: 'WBS1H9C56HV786662',
//     year: 2017,
//     miles: 22909,
//     trim: 'Base',
//     price: 42230,
//     make: 'BMW',
//     model: 'M2',
//     image:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_3EB6WVgk8T0ijqHx.jpg',
//     bodyType: 'Coupe',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_SsaETR9M8ICmwvOk.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_3EB6WVgk8T0ijqHx.jpg',
//     color: 'Black',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_3EB6WVgk8T0ijqHx.jpg',
//     subjectLine: '2017 BMW M2',
//     warrantyRemaining: '4 months or 27,091 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_SsaETR9M8ICmwvOk.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 365hp 343ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_SsaETR9M8ICmwvOk.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_7QnOwN2vrSX6YiED.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_QnbKzBHEy41xmwiA.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_8GwaFWoEbcxKZi9g.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_fjeD5EGtX1KJ4hWc.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_qCGUxaJjzW6dR7w3.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_yq7x8YdaN2VLp9Rz.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_xEhSkReBjIY4Kzco.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_V2pqiXQDGUu9ES4Y.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_Bq7Jxpb82izkLnFN.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_eVpWzMjuohLEx0JT.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_of5stqSKmd8aeC0j.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_N4fs6EviLKP5Cqkm.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_RLF7hl0vEdrzjPNk.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_Ee86D4t0UPHYfTgo.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_UXRdPhnl1uq72K50.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_n87CBPk6gI3buyNO.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_oJNGq6wlOQMeBESK.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'm2',
//     extColor: 'Black',
//     text:
//       '2017 BMW M2 WBS1H9C56HV786662 10428854 786662 HV786662 Black Black Luxuary Sedan Coupe   nav navigation ',
//     engId: 4,
//     bodyId: 2048,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 2,
//     roof: 0,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_3EB6WVgk8T0ijqHx.jpg',
//     style: '',
//     optionalFeatures:
//       'Auto Climate Control,Dual-Zone Climate Control,Heated Seats,Leather Seats,Power Locks,Power Mirrors,Power Windows,Steering Wheel Controls,Harman/Kardon Sound System,Navigation System,AM/FM,CD (Single Disc),Cruise Control,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear Parking Sensors,Rear-View Camera,19 Inch Wheels,Dual Exhaust,Paddle Shifter',
//     zone: 'Showroom',
//     zoneID: 9494,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_SsaETR9M8ICmwvOk.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_7QnOwN2vrSX6YiED.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_QnbKzBHEy41xmwiA.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_8GwaFWoEbcxKZi9g.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_fjeD5EGtX1KJ4hWc.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_qCGUxaJjzW6dR7w3.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_yq7x8YdaN2VLp9Rz.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_xEhSkReBjIY4Kzco.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_V2pqiXQDGUu9ES4Y.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_Bq7Jxpb82izkLnFN.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_eVpWzMjuohLEx0JT.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_of5stqSKmd8aeC0j.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_N4fs6EviLKP5Cqkm.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_RLF7hl0vEdrzjPNk.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_Ee86D4t0UPHYfTgo.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_UXRdPhnl1uq72K50.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_n87CBPk6gI3buyNO.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_oJNGq6wlOQMeBESK.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_7uZOlxqk4cyQGmMJ.jpg',
//         defectType: 'Chip',
//         location: 'Front Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_1M7f4HhXUYrL9bWm.jpg',
//         defectType: 'Dent',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_gntDorGuMmjZyN3P.jpg',
//         defectType: 'Dent',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/fwg71k65zxshm/snaps/img_Fix8DJWnfLw2CKgQ.jpg',
//         defectType: 'Scratch',
//         location: 'Passenger Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 0,
//     highwayMpg: 0,
//     combinedMpg: 0,
//     inventoryId: 10428854,
//     fyusionId: '',
//     frontTrackWidth: 62.2,
//     rearTrackWidth: 63,
//     wheelBase: 106,
//     width: 78.1,
//     length: 176.2,
//     groundClearance: 4.8,
//     height: 55.5,
//     stockLeadFlagPhotoUrl: '',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: 'WBAJB9C5XJB035562',
//     year: 2018,
//     miles: 46111,
//     trim: 'M550i xDrive',
//     price: 46180,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_dtslLU4ajnXH3hMq.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_5jbhxAUG2Yoks6Cn.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_dtslLU4ajnXH3hMq.jpg',
//     color: 'Black',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_dtslLU4ajnXH3hMq.jpg',
//     subjectLine: '2018 BMW 5 Series',
//     warrantyRemaining: '15 months or 3,889 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_5jbhxAUG2Yoks6Cn.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '4.4L Twin Turbo V8 456hp 480ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_5jbhxAUG2Yoks6Cn.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_rJz1EsLZe48iQwg6.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_nFSkoT9ZgHmja8wp.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_gOhS9eWwHC0DrVcp.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_UEwIh6qYDZM5xHXA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_gQWspAUZFVmecd3f.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_4ZJsACTX1hd2ENPK.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_MYxq6gPWKpnulcRt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_uodjH4A8QOZKMWvt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_A2qsLvlxDuHEN5wO.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_oMK2NSP0UdtLjYxy.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_BNHckeWnpLT9Csqo.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_FV5S7AnshTlfeq92.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_NXimVD5tPHU9Movb.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_SjU2hmdq0Jv5CQoI.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_z6U4YlQEM3tcmNsi.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_a5dOK3z1RBDvTZcb.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_Rb2EiMCvP609cfnD.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_aeBR5EX6DGCLknAv.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_EoavS5DVHrLw3cG4.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'Black',
//     text:
//       '2018 BMW 5 Series WBAJB9C5XJB035562 10428689 035562 JB035562 Black Black Luxuary Sedan Sedan   nav navigation ',
//     engId: 5,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Black',
//     cylinders: 8,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_dtslLU4ajnXH3hMq.jpg',
//     style: '',
//     optionalFeatures:
//       'Heated Seats,Heated Steering Wheel,Leather Seats,Power Locks,Power Mirrors,Power Seat (Dual),Power Sun/Moonroof,Power Windows,Side and Top View Camera,Steering Wheel Controls,Ventilated Seats,Wood Trimmed Interior,Navigation System,Alloy Wheels,AM/FM,CD (Single Disc),Cruise Control,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear Parking Sensors,Rear-View Camera,Dual Exhaust,Paddle Shifter',
//     zone: 'RA San Francisco SI',
//     zoneID: 80294,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_5jbhxAUG2Yoks6Cn.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_rJz1EsLZe48iQwg6.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_nFSkoT9ZgHmja8wp.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_gOhS9eWwHC0DrVcp.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_UEwIh6qYDZM5xHXA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_gQWspAUZFVmecd3f.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_4ZJsACTX1hd2ENPK.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_MYxq6gPWKpnulcRt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_uodjH4A8QOZKMWvt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_A2qsLvlxDuHEN5wO.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_oMK2NSP0UdtLjYxy.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_BNHckeWnpLT9Csqo.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_FV5S7AnshTlfeq92.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_NXimVD5tPHU9Movb.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_SjU2hmdq0Jv5CQoI.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_z6U4YlQEM3tcmNsi.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_a5dOK3z1RBDvTZcb.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_Rb2EiMCvP609cfnD.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_aeBR5EX6DGCLknAv.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_EoavS5DVHrLw3cG4.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_uv1qgG8z7ALnFw5Q.jpg',
//         defectType: 'Chip',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_j1mAWThSgXxiqYwb.jpg',
//         defectType: 'Scratch',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/foe1hs87r6bqn/snaps/img_dLE5r7mXTx4Yt6HV.jpg',
//         defectType: 'Scratch',
//         location: 'Driver Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 16,
//     highwayMpg: 25,
//     combinedMpg: 19,
//     inventoryId: 10428689,
//     fyusionId: '',
//     frontTrackWidth: 63,
//     rearTrackWidth: 62.9,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 195.4,
//     groundClearance: 5.2,
//     height: 57.8,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26836/2/4/color_1280_032/MY2018/12054/12054_cc1280_032_668.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '37.6153,-122.1389',
//     location: 'RA San Francisco',
//   },
//   {
//     vin: 'WBAJE5C34HG915345',
//     year: 2017,
//     miles: 46803,
//     trim: '540i',
//     price: 35130,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_6LUrqcgSNGFObAKw.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_EiVUmCaknz9IRQZ5.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_6LUrqcgSNGFObAKw.jpg',
//     color: 'Black',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_6LUrqcgSNGFObAKw.jpg',
//     subjectLine: '2017 BMW 5 Series',
//     warrantyRemaining: '13 months or 3,197 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_EiVUmCaknz9IRQZ5.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 335hp 332ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_EiVUmCaknz9IRQZ5.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_vOqwtNenhTsc8F4x.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_zjFIfYWxowH5rGJ9.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_WyVRhM1X8eOptYP3.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_2H95Bpt13PAEuToI.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_kegDNsnEWbZXGwzh.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_GeCm51h8rszHDMk0.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_vVuAZrIgX4ljsPFO.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_7ay50xFrKz96TOAe.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_KEs6ShH9JQzjxCBP.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_ypwLWjEhm3YZNPS4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_x6R0XwQsViFMlOam.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_nCSbtDhrdgXk7UzW.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_qilpfNJc4SARvHYd.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_Oj90sq5YuQVESHXg.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_RZivue65Nbrdp9YA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_PNV9wFOjbsr8fpvJ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_ErIf0sTMZmLzqNeU.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_j6PRVbNywHc2pETS.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_xsTyZjbpvt8fXznY.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'Black',
//     text:
//       '2017 BMW 5 Series WBAJE5C34HG915345 10428686 915345 HG915345 Black Black Sedan Sedan   nav navigation ',
//     engId: 4,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_6LUrqcgSNGFObAKw.jpg',
//     style: '',
//     optionalFeatures:
//       'Cold Weather Package,Driver Assistance Package,M Sport Package,4-Wheel Disc Brakes,Anti-Lock Brakes,Blind Spot Monitor,Stability Control,Adjustable Steering Wheel,Bucket Seats,Head-Up Display,Heated Mirrors,Heated Seats,Heated Steering Wheel,Leather Seats,Parking Assist System,Parking Sensors,Power Locks,Power Mirrors,Power Steering,Power Windows,Rear Heated Seats,Side and Top View Camera,Steering Wheel Controls,Sun/Moonroof,Harman/Kardon Sound System,Navigation System,Air Conditioning,AM/FM,Anti-Theft System,Auto-Dimming Mirror,Automatic Headlights,Braking Assist,Cruise Control,Daytime Running Lights,Fog Lights,Keyless Entry,Keyless Ignition,Leather Steering Wheel,LED Lights,Power Seat (Driver),Rear-View Camera,Tire Pressure Monitoring System,19 Inch Wheels',
//     zone: 'RA San Francisco SI',
//     zoneID: 80294,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_EiVUmCaknz9IRQZ5.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_vOqwtNenhTsc8F4x.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_zjFIfYWxowH5rGJ9.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_WyVRhM1X8eOptYP3.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_2H95Bpt13PAEuToI.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_kegDNsnEWbZXGwzh.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_GeCm51h8rszHDMk0.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_vVuAZrIgX4ljsPFO.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_7ay50xFrKz96TOAe.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_KEs6ShH9JQzjxCBP.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_ypwLWjEhm3YZNPS4.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_x6R0XwQsViFMlOam.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_nCSbtDhrdgXk7UzW.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_qilpfNJc4SARvHYd.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_Oj90sq5YuQVESHXg.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_RZivue65Nbrdp9YA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_PNV9wFOjbsr8fpvJ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_ErIf0sTMZmLzqNeU.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_j6PRVbNywHc2pETS.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_xsTyZjbpvt8fXznY.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_VfScQhR98J0M4yui.jpg',
//         defectType: 'Chip',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_bw5hBES4y8vZQJWU.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_T2w6yXpr0oeMvmnD.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/hjuk1tvgzw7dn/snaps/img_thFavNrqIAguQ4T3.jpg',
//         defectType: 'Chip',
//         location: 'Back Bumper',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 20,
//     highwayMpg: 30,
//     combinedMpg: 24,
//     inventoryId: 10428686,
//     fyusionId: '',
//     frontTrackWidth: 63,
//     rearTrackWidth: 63.9,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 194.6,
//     groundClearance: 5.7,
//     height: 58.2,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26862/2/4/color_1280_032/MY2017/11982/11982_cc1280_032_416.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '37.6153,-122.1389',
//     location: 'RA San Francisco',
//   },
//   {
//     vin: '5UXTR7C53KLF32946',
//     year: 2019,
//     miles: 8564,
//     trim: 'sDrive30i',
//     price: 37780,
//     make: 'BMW',
//     model: 'X3',
//     image:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_hKk7zGfLbxcRJ85I.jpg',
//     bodyType: 'SUV',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_uF6XrgVmHOGwqUxM.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_hKk7zGfLbxcRJ85I.jpg',
//     color: 'Blue',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_hKk7zGfLbxcRJ85I.jpg',
//     subjectLine: '2019 BMW X3',
//     warrantyRemaining: '34 months or 41,436 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_uF6XrgVmHOGwqUxM.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Turbo I4 248hp 258ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_uF6XrgVmHOGwqUxM.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_5hWrHMfKPwbdXpzA.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_TrYlNLMEwSCvKP1o.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_CMOsQYgerkuPjDdV.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_Tw8aM0AvVIOUqLeJ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_iYT8aI5fQCPSwbGp.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_l4FPDgvija2VIumS.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_3XWU5tD4jnwOrkIN.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_m3GeOpQKt2VbAY61.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_cWsSr8gvyiZalAV1.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_qHsiZp5kn9BhlFAR.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_RG4QuCUXkS3W2AFT.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_IU2hgdzDLsvNl9JA.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_T6ALNfJ9xdej4SZ5.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_3agOK7pUAmunzrSP.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_6OfvjJWyHAdDQrYg.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_ZwDBPhGaJMztcuEV.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_tbYET2qV4NMaiBrD.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_yxICecaFP6EpOq5h.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_hSGX6HPVDyzRnZA1.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'x3',
//     extColor: 'Blue',
//     text:
//       '2019 BMW X3 5UXTR7C53KLF32946 10428610 F32946 KLF32946 Blue Blue Large SUV SUV    ',
//     engId: 2,
//     bodyId: 2042,
//     makeSlug: 'bmw',
//     vehicleType: 'Large SUV',
//     doorCount: 4,
//     roof: 1,
//     nav: 0,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_hKk7zGfLbxcRJ85I.jpg',
//     style: '',
//     optionalFeatures:
//       'Blind Spot Monitor,LED Fog Lights,Rear Cross Traffic Alert,Start-Stop System,Auto Climate Control,Dual-Pane Panoramic Sunroof,Front Parking Sensors,Heated Seats,Leatherette Seats,Power Liftgate,Rear Air Ducts,Seat Memory,Sport Seats,Vinyl Seats,Fog Lights,Handsfree/Bluetooth Integration,HD Radio,Keyless Ignition,LED Lights,Rear Parking Sensors,Roof Rails,Turn Signal Mirrors,19 Inch Wheels,Dual Exhaust,Paddle Shifter,Rear Spoiler,Turbocharger',
//     zone: 'RDA',
//     zoneID: 774946,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_uF6XrgVmHOGwqUxM.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_5hWrHMfKPwbdXpzA.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_TrYlNLMEwSCvKP1o.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_CMOsQYgerkuPjDdV.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_Tw8aM0AvVIOUqLeJ.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_iYT8aI5fQCPSwbGp.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_l4FPDgvija2VIumS.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_3XWU5tD4jnwOrkIN.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_m3GeOpQKt2VbAY61.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_cWsSr8gvyiZalAV1.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_qHsiZp5kn9BhlFAR.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_RG4QuCUXkS3W2AFT.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_IU2hgdzDLsvNl9JA.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_T6ALNfJ9xdej4SZ5.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_3agOK7pUAmunzrSP.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_6OfvjJWyHAdDQrYg.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_ZwDBPhGaJMztcuEV.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_tbYET2qV4NMaiBrD.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_yxICecaFP6EpOq5h.jpg',
//       'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_hSGX6HPVDyzRnZA1.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_eLPEucmGYowvtNJU.jpg',
//         defectType: 'Chip',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_altGwAbHYV0hdOyo.jpg',
//         defectType: 'Scratch',
//         location: 'Back Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/60c93rv8bresrgvq/w69lnf820hdcq/snaps/img_NrBaM7EkCXmuiwqF.jpg',
//         defectType: 'Chip',
//         location: 'Passenger Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 23,
//     highwayMpg: 30,
//     combinedMpg: 26,
//     inventoryId: 10428610,
//     fyusionId: '',
//     frontTrackWidth: 63.8,
//     rearTrackWidth: 64.4,
//     wheelBase: 112.8,
//     width: 84.2,
//     length: 185.9,
//     groundClearance: 8,
//     height: 66,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/27635/2/4/color_1280_032/MY2019/12808/12808_cc1280_032_475.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: '5UXKR6C33H0U13337',
//     year: 2017,
//     miles: 48978,
//     trim: 'xDrive50i',
//     price: 40070,
//     make: 'BMW',
//     model: 'X5',
//     image:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_3GItYd5ob1PV6xN7.jpg',
//     bodyType: 'SUV',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_iSE0yTntPXx8JWBk.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_3GItYd5ob1PV6xN7.jpg',
//     color: 'Black',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_3GItYd5ob1PV6xN7.jpg',
//     subjectLine: '2017 BMW X5',
//     warrantyRemaining: '12 months or 1,022 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_iSE0yTntPXx8JWBk.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '4.4L Twin Turbo V8 445hp 480ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_iSE0yTntPXx8JWBk.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_BTkfvwRt43U7QMgm.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_Z6gtl8rG14AsBhum.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_V6ARuO8qcY0nTfyH.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_xwf1CZG5RHheT28K.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_lAwKeWc8GXPzdorQ.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_X5zd7OcsbogPhTKW.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_Cj7u0b5S1sTLMPOW.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_9y64dbKP8zRvCwVx.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_i7cZ3BuvKG1mgN8x.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_3l5wv1XkU2Cyna4L.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_bEQBH8lLFJ2iv6p0.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_xr7FSPWCTwhz8ens.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_sEmPFjVq8rnOdeyB.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_JFoCaTUAp85wbNLV.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_yS26ehHdO0CG5kKI.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_Xaj4yQWoe7RTBKxp.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_L68jpOZF2mBWSRJl.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_iYFb7eVTk2wLC3Kd.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_w6AXr5kzFs91Mlf4.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'x5',
//     extColor: 'Black',
//     text:
//       '2017 BMW X5 5UXKR6C33H0U13337 10428298 U13337 H0U13337 Black Black Large SUV SUV   nav navigation ',
//     engId: 5,
//     bodyId: 2042,
//     makeSlug: 'bmw',
//     vehicleType: 'Large SUV',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Black',
//     cylinders: 8,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_3GItYd5ob1PV6xN7.jpg',
//     style: '',
//     optionalFeatures:
//       'M Sport Package,Adaptive Headlights,Blind Spot Monitor,LED Tail Lights,Adjustable Headrests,Adjustable Steering Wheel,Dual-Zone Climate Control,Front One-Touch Power Windows,Heated Seats,Leather Seats,Power Folding Mirrors,Power Liftgate,Power Locks,Power Mirrors,Power Seat (Dual),Power Sun/Moonroof,Power Tailgate,Remote Keyless Entry,Remote Trunk Release,Sport Steering Wheel,Steering Wheel Controls,Ventilated Seats,Apple Carplay,Harman/Kardon Sound System,Navigation System,Real-Time Traffic,Smart Device Integration,Adjustable Seats,Air Conditioning,Alloy Wheels,AM/FM,Auto-Dimming Mirror,Automatic Headlights,Body Colored Bumpers,Braking Assist,CD (Single Disc),Cruise Control,Daytime Running Lights,Fog Lights,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear-View Camera,Roof Rails,Split/Folding Seats,19 Inch Wheels,Spoiler',
//     zone: 'RA Fontana SI',
//     zoneID: 80291,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_iSE0yTntPXx8JWBk.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_BTkfvwRt43U7QMgm.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_Z6gtl8rG14AsBhum.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_V6ARuO8qcY0nTfyH.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_xwf1CZG5RHheT28K.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_lAwKeWc8GXPzdorQ.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_X5zd7OcsbogPhTKW.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_Cj7u0b5S1sTLMPOW.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_9y64dbKP8zRvCwVx.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_i7cZ3BuvKG1mgN8x.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_3l5wv1XkU2Cyna4L.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_bEQBH8lLFJ2iv6p0.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_xr7FSPWCTwhz8ens.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_sEmPFjVq8rnOdeyB.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_JFoCaTUAp85wbNLV.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_yS26ehHdO0CG5kKI.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_Xaj4yQWoe7RTBKxp.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_L68jpOZF2mBWSRJl.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_iYFb7eVTk2wLC3Kd.jpg',
//       'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_w6AXr5kzFs91Mlf4.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_veDW5jdCHyQiL6of.jpg',
//         defectType: 'Chip',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_ItZEeVO0BlCfK8iu.jpg',
//         defectType: 'Scratch',
//         location: 'Trunk',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_Q2xWEKwzsYFNLRr6.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/ns0021v9tc9r2dbv/1qewh95gzdjiu/snaps/img_BmVh81zPUFlwcOWY.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 15,
//     highwayMpg: 21,
//     combinedMpg: 17,
//     inventoryId: 10428298,
//     fyusionId: '',
//     frontTrackWidth: 64.6,
//     rearTrackWidth: 64.8,
//     wheelBase: 115.5,
//     width: 86,
//     length: 193.2,
//     groundClearance: 8.2,
//     height: 69.4,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26387/2/4/color_1280_032/MY2017/11594/11594_cc1280_032_S34.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '34.0453,-117.5539',
//     location: 'RA Fontana',
//   },
//   {
//     vin: 'WBAJE7C31HG889461',
//     year: 2017,
//     miles: 37753,
//     trim: '540i xDrive',
//     price: 38320,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_TPZ68LNADgncpo4s.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_uosgBIi7xzmhKp82.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_TPZ68LNADgncpo4s.jpg',
//     color: 'Black',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_TPZ68LNADgncpo4s.jpg',
//     subjectLine: '2017 BMW 5 Series',
//     warrantyRemaining: '12 months or 12,247 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_uosgBIi7xzmhKp82.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 335hp 332ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_uosgBIi7xzmhKp82.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_wgeOJitLQX7ydY9U.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_I1MBkw3m6RxfEtnh.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_woYDR93STBA7EeLG.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_ln3pXq06jo1uKcM2.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_1av2YqORWtNkoIg9.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_VrBcMnxbt15SWFis.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_oP8hqt6jMJbKnH1k.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_1AB08P5lLNZtbkM4.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_I9Z2uonK5cWVYROA.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_uDEZr0MNpIk9SlHz.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_rI1oapmLtOnlEexN.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_Mvk8e7C5gZ9d2NAI.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_wCTybg0sQleFXDP9.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_nPjBkXyquYt3ixVg.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_DnZvNymYGbThcBP5.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_SfqCzO4cwZ51edb3.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_RPVtU1xJ7cHuKv2e.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_2DV9XHaylbnvS6oU.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_CX4PlO89IMkUzDJZ.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'Carbon Black Metallic',
//     text:
//       '2017 BMW 5 Series WBAJE7C31HG889461 10428296 889461 HG889461 Black Carbon Black Metallic Luxuary Sedan Sedan   nav navigation ',
//     engId: 4,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Brown',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_TPZ68LNADgncpo4s.jpg',
//     style: '',
//     optionalFeatures:
//       'Cold Weather Package,Driver Assistance Plus Package,M Sport Package,Adaptive Cruise Control,LED Fog Lights,LED Lights - Low Beam,Start-Stop System,Ambient Lighting,Auto Climate Control,Dual-Zone Climate Control,Front Parking Sensors,Head-Up Display,Heated Seats,Heated Steering Wheel,Leather Seats,Parking Assist System,Power Locks,Power Mirrors,Power Seat (Dual),Power Sun/Moonroof,Power Windows,Rear Air Ducts,Rear Heated Seats,Remote Keyless Entry,Seat Memory,Side and Top View Camera,Sport Steering Wheel,Steering Wheel Controls,Ventilated Seats,Apple Carplay,Harman/Kardon Sound System,Navigation System,CD (Single Disc),Cruise Control,Daytime Running Lights,Keyless Ignition,Leather Steering Wheel,Rear Parking Sensors,Rear-View Camera,Satellite Radio,19 Inch Wheels,Dual Exhaust,Paddle Shifter,Rear Spoiler,Sport Suspension,Turbocharger',
//     zone: 'R - TDAC - Bay 1',
//     zoneID: 80190,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_uosgBIi7xzmhKp82.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_wgeOJitLQX7ydY9U.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_I1MBkw3m6RxfEtnh.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_woYDR93STBA7EeLG.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_ln3pXq06jo1uKcM2.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_1av2YqORWtNkoIg9.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_VrBcMnxbt15SWFis.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_oP8hqt6jMJbKnH1k.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_1AB08P5lLNZtbkM4.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_I9Z2uonK5cWVYROA.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_uDEZr0MNpIk9SlHz.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_rI1oapmLtOnlEexN.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_Mvk8e7C5gZ9d2NAI.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_wCTybg0sQleFXDP9.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_nPjBkXyquYt3ixVg.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_DnZvNymYGbThcBP5.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_SfqCzO4cwZ51edb3.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_RPVtU1xJ7cHuKv2e.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_2DV9XHaylbnvS6oU.jpg',
//       'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_CX4PlO89IMkUzDJZ.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_aEGrlKpNeDoJPbWj.jpg',
//         defectType: 'Chip',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/w72vcswe1e58ygme/zteg1wjdk0qih/snaps/img_xiBgMWmtJNbElaQ6.jpg',
//         defectType: 'Chip',
//         location: 'Hood',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 20,
//     highwayMpg: 29,
//     combinedMpg: 23,
//     inventoryId: 10428296,
//     fyusionId: '',
//     frontTrackWidth: 63,
//     rearTrackWidth: 63.9,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 194.6,
//     groundClearance: 5.5,
//     height: 58.2,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26738/2/4/color_1280_032/MY2017/11854/11854_cc1280_032_668.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: '5UXKR0C35H0V81980',
//     year: 2017,
//     miles: 47675,
//     trim: 'xDrive35i',
//     price: 34240,
//     make: 'BMW',
//     model: 'X5',
//     image:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_na4OBfUQgAJPG1tV.jpg',
//     bodyType: 'SUV',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_B4YUADTMzNfcVO5s.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_na4OBfUQgAJPG1tV.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_na4OBfUQgAJPG1tV.jpg',
//     subjectLine: '2017 BMW X5',
//     warrantyRemaining: '13 months or 2,325 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_B4YUADTMzNfcVO5s.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 300hp 300ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_B4YUADTMzNfcVO5s.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_IOJ61aBnh5Pyjz30.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_t5ciUdhAnMGwsJvz.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_mV1oarCb8uHKS97F.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_acBlk8gmIhreLpFi.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_eJHM6smvldZPYQSa.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_LgrKPzcH0s4fmhDy.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_UkNnQFb1J6hA3Oip.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_InUol51XOSkGYzpJ.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_SnKBIp4UOg3l2Vv5.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_swUQJdZYutmbMpf6.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_EXQxs9m4UhAGCi3T.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_V9mCqv5RYE23AbuN.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_CpLBgs9ikePRXonJ.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_Cfb3iXzDIK1UmvRP.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_J1LgKlNn7vtCPsx5.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_PRBDk2ETMiCJbAte.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_kDVsJE1qemLfyQhl.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_xTofQnNzSV7tl6g4.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_P3Syd4FtXjRnz6Um.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'x5',
//     extColor: 'White',
//     text:
//       '2017 BMW X5 5UXKR0C35H0V81980 10428295 V81980 H0V81980 White White Large SUV SUV   nav navigation ',
//     engId: 4,
//     bodyId: 2042,
//     makeSlug: 'bmw',
//     vehicleType: 'Large SUV',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_na4OBfUQgAJPG1tV.jpg',
//     style: '',
//     optionalFeatures:
//       '4-Wheel Disc Brakes,Anti-Lock Brakes,Child Safety Locks,Stability Control,Adjustable Steering Wheel,Bucket Seats,Dual Sun/Moonroof,Heated Seats,Power Folding Mirrors,Power Liftgate,Power Locks,Power Mirrors,Power Steering,Power Windows,Remote Trunk Release,Steering Wheel Controls,Vinyl Seats,Navigation System,Smart Device Integration,Air Conditioning,Alloy Wheels,AM/FM,Automatic Headlights,Braking Assist,CD (Single Disc),Cruise Control,Daytime Running Lights,Fog Lights,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Power Seat (Driver),Rear Parking Sensors,Tire Pressure Monitoring System,Traction Control,Turn Signal Mirrors,19 Inch Wheels,Rear Spoiler',
//     zone: 'YEG',
//     zoneID: 774960,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_B4YUADTMzNfcVO5s.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_IOJ61aBnh5Pyjz30.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_t5ciUdhAnMGwsJvz.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_mV1oarCb8uHKS97F.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_acBlk8gmIhreLpFi.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_eJHM6smvldZPYQSa.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_LgrKPzcH0s4fmhDy.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_UkNnQFb1J6hA3Oip.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_InUol51XOSkGYzpJ.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_SnKBIp4UOg3l2Vv5.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_swUQJdZYutmbMpf6.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_EXQxs9m4UhAGCi3T.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_V9mCqv5RYE23AbuN.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_CpLBgs9ikePRXonJ.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_Cfb3iXzDIK1UmvRP.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_J1LgKlNn7vtCPsx5.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_PRBDk2ETMiCJbAte.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_kDVsJE1qemLfyQhl.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_xTofQnNzSV7tl6g4.jpg',
//       'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_P3Syd4FtXjRnz6Um.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_eF81Rdk7LGCSwDUz.jpg',
//         defectType: 'Chip',
//         location: 'Front Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_8kHov57tjbYQdZ2y.jpg',
//         defectType: 'Chip',
//         location: 'Back Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_i6eK2FWQTLfJdzpE.jpg',
//         defectType: 'Scratch',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/iq8jw4ovn2xfa9if/2t7s96eoavd35/snaps/img_TPkh2FtB0QlAm3N7.jpg',
//         defectType: 'Scratch',
//         location: 'Passenger Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 18,
//     highwayMpg: 24,
//     combinedMpg: 20,
//     inventoryId: 10428295,
//     fyusionId: '',
//     frontTrackWidth: 64.6,
//     rearTrackWidth: 64.8,
//     wheelBase: 115.5,
//     width: 86,
//     length: 193.2,
//     groundClearance: 8.2,
//     height: 69.4,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26387/2/4/color_1280_032/MY2017/11594/11594_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: '5UXKR0C51J0Y02790',
//     year: 2018,
//     miles: 24748,
//     trim: 'xDrive35i',
//     price: 42480,
//     make: 'BMW',
//     model: 'X5',
//     image:
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_K3WkztwrEbR2sx4p.jpg',
//     bodyType: 'SUV',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_HYwID1fWqlcQsAph.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_K3WkztwrEbR2sx4p.jpg',
//     color: 'Grey',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_K3WkztwrEbR2sx4p.jpg',
//     subjectLine: '2018 BMW X5',
//     warrantyRemaining: '27 months or 25,252 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_HYwID1fWqlcQsAph.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 300hp 300ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_HYwID1fWqlcQsAph.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_vbKWkRUDg7taAyG1.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_s2RpwK4YIXgCQl6D.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_2M0CUREkherTlj97.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_rSihtzIeanB7jx4l.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_OMxcPIs9mgwyWfhS.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_raBZgV02j5QmKxJq.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_TkD1Ri7AdV45QgSt.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_sfEobCIUc7jnuKdM.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_e6LtEluyTx3b7OpX.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_PJdhH81N6eQUYScv.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_qkoIxUy0GdB8VFPl.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_84p6DXdNCb1ZexJf.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_RuyWUlcSDfd7AjsZ.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_H3FuDEJe8hIAXQBO.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_SLWzNE5eGjBsKXCP.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_vaIXr2tKReS3JqgA.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_iFg8ItTEc7Dl4aAN.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_64EHBTnMkWfOdVmv.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_hqAEBW5kUZaLQHfv.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'x5',
//     extColor: 'Dark Graphite Metallic',
//     text:
//       '2018 BMW X5 5UXKR0C51J0Y02790 10428271 Y02790 J0Y02790 Grey Dark Graphite Metallic Large SUV SUV   nav navigation ',
//     engId: 4,
//     bodyId: 2042,
//     makeSlug: 'bmw',
//     vehicleType: 'Large SUV',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_K3WkztwrEbR2sx4p.jpg',
//     style: '',
//     optionalFeatures:
//       'Driver Assistance Package,Premium Package,xLine Package,Adaptive Headlights,Blind Spot Monitor,High Intensity Discharge Headlights,LED Fog Lights,LED Tail Lights,Auto Climate Control,Four-Zone Climate Control,Heated Mirrors,Heated Seats,Panorama Sunroof,Parking Sensors,Power Folding Mirrors,Power Liftgate,Power Locks,Power Mirrors,Power Windows,Rain-Sensing Wipers,Rear Center Armrest,Rear Heated Seats,Running Boards,Steering Wheel Controls,Harman/Kardon Sound System,Navigation System,Alloy Wheels,Auto-Dimming Mirror,Cargo Cover,CD (Single Disc),Cruise Control,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear-View Camera,Roof Rails,Satellite Radio,19 Inch Wheels,Dual Exhaust,Paddle Shifter,Rear Spoiler,Turbocharger',
//     zone: 'EZ-23',
//     zoneID: 774793,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_HYwID1fWqlcQsAph.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_vbKWkRUDg7taAyG1.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_s2RpwK4YIXgCQl6D.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_2M0CUREkherTlj97.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_rSihtzIeanB7jx4l.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_OMxcPIs9mgwyWfhS.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_raBZgV02j5QmKxJq.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_TkD1Ri7AdV45QgSt.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_sfEobCIUc7jnuKdM.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_e6LtEluyTx3b7OpX.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_PJdhH81N6eQUYScv.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_qkoIxUy0GdB8VFPl.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_84p6DXdNCb1ZexJf.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_RuyWUlcSDfd7AjsZ.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_H3FuDEJe8hIAXQBO.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_SLWzNE5eGjBsKXCP.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_vaIXr2tKReS3JqgA.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_iFg8ItTEc7Dl4aAN.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_64EHBTnMkWfOdVmv.jpg',
//       'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_hqAEBW5kUZaLQHfv.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_kB9Wx8qyFrDHowXO.jpg',
//         defectType: 'Dent',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_J8y3kEo7vfCnQb5s.jpg',
//         defectType: 'Chip',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_3MsKTWpZxAq6OnDm.jpg',
//         defectType: 'Scratch',
//         location: 'Passenger Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/651q1odclfoopnuw/e2jgq9a4ox1pr/snaps/img_cq8fpCGx39JBkmet.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 18,
//     highwayMpg: 24,
//     combinedMpg: 20,
//     inventoryId: 10428271,
//     fyusionId: '',
//     frontTrackWidth: 64.6,
//     rearTrackWidth: 64.8,
//     wheelBase: 115.5,
//     width: 86,
//     length: 193.2,
//     groundClearance: 8.2,
//     height: 69.4,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/27274/2/4/color_1280_032/MY2018/12463/12463_cc1280_032_A90.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: 'WBAJA5C55KBX49158',
//     year: 2019,
//     miles: 14243,
//     trim: '530i',
//     price: 36980,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_24MHe10nVL3usibp.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_m7aMsXd5IwVTqKZS.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_24MHe10nVL3usibp.jpg',
//     color: 'Grey',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_24MHe10nVL3usibp.jpg',
//     subjectLine: '2019 BMW 5 Series',
//     warrantyRemaining: '38 months or 35,757 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_m7aMsXd5IwVTqKZS.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Turbo I4 248hp 258ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_m7aMsXd5IwVTqKZS.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_z7jA3dHgDcPwp8sG.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_NWy0MGw4scnjlkm8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_DdA41xKVechMf8Jt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_7lGCw29PNnyWk4A8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_T8AKVoRwszWb5Yf2.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_MdWUpBajFEgZuc6q.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_PAqylYiISMn7Hk9z.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_lzHTLArKIYN3psJD.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_KHktsE5yIald6JDY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_iVbwGM3UXD94oOqB.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_tpvGV6wPrR0A9FaY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_hO0ztkZwrAuKjSc6.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_SjXoMFwKrcCJL2ue.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_lkaZGBbEX2RtNSid.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_9bhrzPiJIFwZKDtG.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_ZmN7jk8Dge3SpRPh.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_jBMQqWF65lZ1GnxN.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_93RMhLaP6SKbgcYF.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_tYPhV0UKjmGCRHfo.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'Grey',
//     text:
//       '2019 BMW 5 Series WBAJA5C55KBX49158 10428102 X49158 KBX49158 Grey Grey Luxuary Sedan Sedan    ',
//     engId: 2,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 0,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_24MHe10nVL3usibp.jpg',
//     style: '',
//     optionalFeatures:
//       'Front Parking Sensors,Heated Seats,Leatherette Seats,Power Locks,Power Mirrors,Power Sun/Moonroof,Power Windows,Steering Wheel Controls,Wood Trimmed Interior,Alloy Wheels,AM/FM,CD (Single Disc),Cruise Control,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Power Seat (Driver),Rear Parking Sensors,Dual Exhaust,Paddle Shifter',
//     zone: 'RA San Francisco SI',
//     zoneID: 80294,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_m7aMsXd5IwVTqKZS.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_z7jA3dHgDcPwp8sG.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_NWy0MGw4scnjlkm8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_DdA41xKVechMf8Jt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_7lGCw29PNnyWk4A8.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_T8AKVoRwszWb5Yf2.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_MdWUpBajFEgZuc6q.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_PAqylYiISMn7Hk9z.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_lzHTLArKIYN3psJD.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_KHktsE5yIald6JDY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_iVbwGM3UXD94oOqB.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_tpvGV6wPrR0A9FaY.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_hO0ztkZwrAuKjSc6.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_SjXoMFwKrcCJL2ue.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_lkaZGBbEX2RtNSid.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_9bhrzPiJIFwZKDtG.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_ZmN7jk8Dge3SpRPh.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_jBMQqWF65lZ1GnxN.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_93RMhLaP6SKbgcYF.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_tYPhV0UKjmGCRHfo.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_ucJUGZAbhaTFsWr4.jpg',
//         defectType: 'Chip',
//         location: 'Hood',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/zk6y47hw05fnm/snaps/img_AKRwlxGFbzuhQpqN.jpg',
//         defectType: 'Chip',
//         location: 'Front Bumper',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 24,
//     highwayMpg: 34,
//     combinedMpg: 27,
//     inventoryId: 10428102,
//     fyusionId: '',
//     frontTrackWidth: 63,
//     rearTrackWidth: 64.2,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 194.6,
//     groundClearance: 5.7,
//     height: 58.2,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/28233/2/4/color_1280_032/MY2019/13389/13389_cc1280_032_A90.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '37.6153,-122.1389',
//     location: 'RA San Francisco',
//   },
//   {
//     vin: 'WBA8E1C5XJA177875',
//     year: 2018,
//     miles: 19938,
//     trim: '330e iPerformance',
//     price: 27470,
//     make: 'BMW',
//     model: '3 Series',
//     image:
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_7FWut05URMQpEwBo.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_Jr5Z1TYOR7otDAuI.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_7FWut05URMQpEwBo.jpg',
//     color: 'Black',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_7FWut05URMQpEwBo.jpg',
//     subjectLine: '2018 BMW 3 Series',
//     warrantyRemaining: '26 months or 30,062 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_Jr5Z1TYOR7otDAuI.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '2.0L Plug-in Hybrid Turbo I4 248hp 310ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_Jr5Z1TYOR7otDAuI.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_qpNCO3SMu0aUzrEo.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_xU3TqM0YC4eitdgA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_nQiSGTycDVNWzdvw.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_up0jrOgxI4dHZXyk.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_McDJjirfotwu28Th.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_w1EznO7CGHkVWADF.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_v6JlxycwY79riSGP.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_M7b1JyPcZGwmjugV.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_OkTYvFC2qwHI4iuE.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_9IGYLuHoDxhpyEVQ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_B95sUq4L6uSbYvC1.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_t4SFG8f9eoBkyaus.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_ECA3vqIzbaSYjfK6.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_f60qajpolFbkEAsQ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_izdg1VZ3k86f4Xey.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_XU8ymcCRbAGtlJ2N.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_opE2rZiOFQWJcY1V.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_unvkhxlzdQPWBXGt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_WIG4X7AqBZLNUk2d.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '3-series',
//     extColor: 'Black',
//     text:
//       '2018 BMW 3 Series WBA8E1C5XJA177875 10428037 177875 JA177875 Black Black Sedan Sedan    ',
//     engId: 2,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Sedan',
//     doorCount: 4,
//     roof: 0,
//     nav: 0,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'RWD',
//     intColor: 'Black',
//     cylinders: 4,
//     awd: 0,
//     fuelType: 'Plug-in Hybrid',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_7FWut05URMQpEwBo.jpg',
//     style: '',
//     optionalFeatures:
//       '4-Wheel Disc Brakes,Anti-Lock Brakes,Stability Control,Adjustable Steering Wheel,Auto Climate Control,Bucket Seats,Dual-Zone Climate Control,Heated Seats,Heated Steering Wheel,Parking Sensors,Power Locks,Power Mirrors,Power Seat (Dual),Power Steering,Power Windows,Remote Trunk Release,Steering Wheel Controls,18 Inch Wheels,Air Conditioning,AM/FM,Automatic Headlights,Braking Assist,CD (Single Disc),Cruise Control,Fog Lights,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear-View Camera,Tire Pressure Monitoring System,Paddle Shifter',
//     zone: 'RA San Francisco SI',
//     zoneID: 80294,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_Jr5Z1TYOR7otDAuI.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_qpNCO3SMu0aUzrEo.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_xU3TqM0YC4eitdgA.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_nQiSGTycDVNWzdvw.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_up0jrOgxI4dHZXyk.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_McDJjirfotwu28Th.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_w1EznO7CGHkVWADF.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_v6JlxycwY79riSGP.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_M7b1JyPcZGwmjugV.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_OkTYvFC2qwHI4iuE.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_9IGYLuHoDxhpyEVQ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_B95sUq4L6uSbYvC1.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_t4SFG8f9eoBkyaus.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_ECA3vqIzbaSYjfK6.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_f60qajpolFbkEAsQ.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_izdg1VZ3k86f4Xey.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_XU8ymcCRbAGtlJ2N.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_opE2rZiOFQWJcY1V.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_unvkhxlzdQPWBXGt.jpg',
//       'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_WIG4X7AqBZLNUk2d.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/es9zm7exl5633i22/906gwzxfb1yp3/snaps/img_phywxzV5HFBULYkc.jpg',
//         defectType: 'Scratch',
//         location: 'Driver Side',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 0,
//     highwayMpg: 0,
//     combinedMpg: 0,
//     inventoryId: 10428037,
//     fyusionId: '',
//     frontTrackWidth: 60.3,
//     rearTrackWidth: 61.9,
//     wheelBase: 110.6,
//     width: 80,
//     length: 182.8,
//     groundClearance: 5.5,
//     height: 56.3,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/27256/2/4/color_1280_032/MY2018/12493/12493_cc1280_032_668.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '37.6153,-122.1389',
//     location: 'RA San Francisco',
//   },
//   {
//     vin: 'WBAJE7C3XHG888101',
//     year: 2017,
//     miles: 25733,
//     trim: '540i xDrive',
//     price: 38340,
//     make: 'BMW',
//     model: '5 Series',
//     image:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_IbMRaxVefovCwD1Q.jpg',
//     bodyType: 'Sedan',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_qbsei6Zr480f9uYy.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_IbMRaxVefovCwD1Q.jpg',
//     color: 'Blue',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_IbMRaxVefovCwD1Q.jpg',
//     subjectLine: '2017 BMW 5 Series',
//     warrantyRemaining: '11 months or 24,267 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_qbsei6Zr480f9uYy.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 335hp 332ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_qbsei6Zr480f9uYy.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_eCAlhFdM4GWjI7Qk.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_NVmUOKqA0tYIL6W9.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_8Mb3dAKtFQ69N4O7.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_5rzdxcjNClmUfKVF.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_wVSGRHIWn8t4FZ17.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_ufqO0ZlMj2kNwbpc.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_COZP5q9DJjaI16Hy.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_aF38EwDLpeRkCIMv.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_FrPUmHcL5zbNKR7h.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_izu68hTFca1pOAQJ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_7WL5tE0mvPRqKF29.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_jxiBmnGzcfHV8UAr.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_aRHoyBGfJN7QqpCP.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_gfx2M6Ymk3Tsh4Jz.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_lrP2kDzTwmo6BVLQ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_L2hJgxnc1iwzECX0.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_Gq6TYkLDB7x921yK.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_s0iwOlaqLdITuNC5.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_qcBTaXFNGwkmWUvl.jpg',
//     ],
//     warranty: 1,
//     modelSlug: '5-series',
//     extColor: 'Blue',
//     text:
//       '2017 BMW 5 Series WBAJE7C3XHG888101 10428035 888101 HG888101 Blue Blue Luxuary Sedan Sedan   nav navigation ',
//     engId: 4,
//     bodyId: 2045,
//     makeSlug: 'bmw',
//     vehicleType: 'Luxuary Sedan',
//     doorCount: 4,
//     roof: 1,
//     nav: 1,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Brown',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_IbMRaxVefovCwD1Q.jpg',
//     style: '',
//     optionalFeatures:
//       'Auto Climate Control,Dual-Zone Climate Control,Heated Seats,Heated Steering Wheel,Power Locks,Power Mirrors,Power Sun/Moonroof,Power Windows,Steering Wheel Controls,Harman/Kardon Sound System,Navigation System,18 Inch Wheels,AM/FM,Cruise Control,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear-View Camera,Dual Exhaust,Paddle Shifter',
//     zone: 'EZ-23',
//     zoneID: 774793,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_qbsei6Zr480f9uYy.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_eCAlhFdM4GWjI7Qk.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_NVmUOKqA0tYIL6W9.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_8Mb3dAKtFQ69N4O7.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_5rzdxcjNClmUfKVF.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_wVSGRHIWn8t4FZ17.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_ufqO0ZlMj2kNwbpc.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_COZP5q9DJjaI16Hy.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_aF38EwDLpeRkCIMv.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_FrPUmHcL5zbNKR7h.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_izu68hTFca1pOAQJ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_7WL5tE0mvPRqKF29.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_jxiBmnGzcfHV8UAr.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_aRHoyBGfJN7QqpCP.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_gfx2M6Ymk3Tsh4Jz.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_lrP2kDzTwmo6BVLQ.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_L2hJgxnc1iwzECX0.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_Gq6TYkLDB7x921yK.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_s0iwOlaqLdITuNC5.jpg',
//       'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_qcBTaXFNGwkmWUvl.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/z5i7gxj6u2ay5eby/rh9pis4zfnybw/snaps/img_TL7DzMhyf5spgocJ.jpg',
//         defectType: 'Scratch',
//         location: 'Tire Rim',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 20,
//     highwayMpg: 29,
//     combinedMpg: 23,
//     inventoryId: 10428035,
//     fyusionId: '',
//     frontTrackWidth: 63,
//     rearTrackWidth: 63.9,
//     wheelBase: 117.1,
//     width: 83.7,
//     length: 194.6,
//     groundClearance: 5.5,
//     height: 58.2,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26738/2/4/color_1280_032/MY2017/11854/11854_cc1280_032_A89.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '29.6458,-95.572',
//     location: 'Stafford',
//   },
//   {
//     vin: '5UXKR0C34H0X81183',
//     year: 2017,
//     miles: 27404,
//     trim: 'xDrive35i',
//     price: 38000,
//     make: 'BMW',
//     model: 'X5',
//     image:
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_mXiRtZ8Ds0ehJFkj.jpg',
//     bodyType: 'SUV',
//     interiorPhotoUrl:
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_QgxEAsWafktRKj5b.jpg',
//     diesel: 0,
//     leadFlagPhotoUrl:
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_mXiRtZ8Ds0ehJFkj.jpg',
//     color: 'White',
//     leadFlagPhotoUrlHiRes:
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_mXiRtZ8Ds0ehJFkj.jpg',
//     subjectLine: '2017 BMW X5',
//     warrantyRemaining: '13 months or 22,596 miles',
//     interiorPhotoUrlHiRes:
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_QgxEAsWafktRKj5b.jpg',
//     dvd: 0,
//     transmission: 'Automatic',
//     engine: '3.0L Turbo I6 300hp 300ft. lbs.',
//     hiresPhotos: [
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_QgxEAsWafktRKj5b.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_KmnWo9ds4GZB1vUj.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_wZF2tW8f5Hau0GcS.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_IBFk3XbH2yQdsq8Z.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_VnGrHDsyljZRNYE9.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_KM6Yez0OEGvco27S.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_mozkLaQ0XE2yCDPZ.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_O0c8FMPVD51GWuC2.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_1GxDTLRXHkYePj6M.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_pB2Eb8fFcRsAeHnq.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_oc49F3vkCZplsAgL.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_fiIPy8GbqBAgunwN.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_UNqTxaWOPvcnIe9d.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_clJpSiQUL3yB0WkV.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_M451bhuTrkgqlQn9.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_SspcTohG6jRkLzmV.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_OGwN6valziUV5rsg.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_Rckvhq0DOg5ymxWf.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_BYizGHV8jJvex7dw.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_7mL4rAsBS0zRPo3g.jpg',
//     ],
//     warranty: 1,
//     modelSlug: 'x5',
//     extColor: 'White',
//     text:
//       '2017 BMW X5 5UXKR0C34H0X81183 10427909 X81183 H0X81183 White White Small SUV SUV    ',
//     engId: 4,
//     bodyId: 2042,
//     makeSlug: 'bmw',
//     vehicleType: 'Small SUV',
//     doorCount: 4,
//     roof: 1,
//     nav: 0,
//     warrantyOriginal: '48 months or 50,000 miles',
//     driveType: 'AWD',
//     intColor: 'Black',
//     cylinders: 6,
//     awd: 0,
//     fuelType: 'Gasoline',
//     leadPhotoUrlHiRes:
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_mXiRtZ8Ds0ehJFkj.jpg',
//     style: '',
//     optionalFeatures:
//       'Dual-Pane Panoramic Sunroof,Heated Seats,Leatherette Seats,Power Locks,Power Mirrors,Power Windows,Steering Wheel Controls,Alloy Wheels,AM/FM,CD (Single Disc),Cruise Control,Fog Lights,Handsfree/Bluetooth Integration,Keyless Entry,Keyless Ignition,Leather Steering Wheel,Rear Parking Sensors,Rear-View Camera',
//     zone: 'RA Statesville SI',
//     zoneID: 8312037,
//     soldStatus: 0,
//     otherPhotos: [
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_QgxEAsWafktRKj5b.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_KmnWo9ds4GZB1vUj.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_wZF2tW8f5Hau0GcS.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_IBFk3XbH2yQdsq8Z.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_VnGrHDsyljZRNYE9.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_KM6Yez0OEGvco27S.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_mozkLaQ0XE2yCDPZ.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_O0c8FMPVD51GWuC2.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_1GxDTLRXHkYePj6M.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_pB2Eb8fFcRsAeHnq.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_oc49F3vkCZplsAgL.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_fiIPy8GbqBAgunwN.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_UNqTxaWOPvcnIe9d.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_clJpSiQUL3yB0WkV.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_M451bhuTrkgqlQn9.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_SspcTohG6jRkLzmV.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_OGwN6valziUV5rsg.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_Rckvhq0DOg5ymxWf.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_BYizGHV8jJvex7dw.jpg',
//       'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_7mL4rAsBS0zRPo3g.jpg',
//     ],
//     defectPhotos: [
//       {
//         url:
//           'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_FSHBPK0RuU9tAnil.jpg',
//         defectType: 'Chip',
//         location: 'Front Bumper',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_SG0uogenqv2RZbhw.jpg',
//         defectType: 'Chip',
//         location: 'Driver Side',
//       },
//       {
//         url:
//           'https://i.fyu.se/group/tcrh05in887cz6yj/tbqokjg78hp06/snaps/img_bpW4GdkZ5QtIqPML.jpg',
//         defectType: 'Chip',
//         location: 'Trunk',
//       },
//     ],
//     ownerCount: 1,
//     cityMpg: 18,
//     highwayMpg: 24,
//     combinedMpg: 20,
//     inventoryId: 10427909,
//     fyusionId: '',
//     frontTrackWidth: 64.6,
//     rearTrackWidth: 64.8,
//     wheelBase: 115.5,
//     width: 86,
//     length: 193.2,
//     groundClearance: 8.2,
//     height: 69.4,
//     stockLeadFlagPhotoUrl:
//       'https://i.fuelapi.com/d1b3bc64dbc64d5eb2c5ec581ad2e47e/26387/2/4/color_1280_032/MY2017/11594/11594_cc1280_032_300.jpg',
//     hasStockPhotos: false,
//     consignmentPartnerId: '',
//     geolocation: '35.8499,-80.9264',
//     location: 'RA Statesville',
//   },
// ];
