import React from 'react';
import { AmplifyLoginPage } from './AmplifyLoginPage';
import { LoginPage } from './LoginPage';
// import './App.css'; // Customize the CSS
// import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { useAuth } from './useAuth';

// interface AmplifyCognitoUser {
//   username: string;
//   attributes: {
//     email: string;
//     email_verified: boolean;
//   };
// }

interface AuthProps {
  component: JSX.Element;
}

/**
 * The Auth component uses the "render prop pattern" to render passed in component.
 * It shows the passed in component only if the user isAuthenticated
 */
export const Auth: React.FC<AuthProps> = ({ component }) => {
  // const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  // console.log({ isAuthenticated });

  // React.useEffect(() => {
  //   return onAuthUIStateChange((nextAuthState, authData) => {
  //     // Set Auth user in Redux so other components are aware
  //     const user = authData as AmplifyCognitoUser;
  //     const isAuthenticated = nextAuthState === AuthState.SignedIn && !!user;

  //     const userFormatted: UserState = {
  //       email: user?.attributes?.email,
  //       username: user?.username,
  //       isAuthenticated,
  //     };
  //     dispatch(userActions.add(userFormatted));
  //   });
  // }, [dispatch]);

  return isAuthenticated ? (
    component
  ) : (
    <AmplifyLoginPage />
    // <div>Eminem test</div>
  );
};

export default Auth;
