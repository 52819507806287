import React from 'react';
import { connectPagination } from 'react-instantsearch-dom';
import { Flex } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/react';
import {
  FiChevronLeft as BackIcon,
  FiChevronRight as ForwardIcon,
} from 'react-icons/fi';

type CarPaginatorProps = {
  currentRefinement: number;
  nbPages: number;
  refine(number): void;
  createURL(number): string;
};

const CustomCarPaginator = ({
  currentRefinement,
  nbPages,
  refine,
}: CarPaginatorProps) => {
  // Control where the current page appears in the list.
  //   1, 2, then 3 until the max page appears on the right, then
  //   move it up until the last spot.
  // Show 7 pages + forward/backward button = 9 spots fits well
  //   on the narrowest screen.
  const lastPage = Math.min(currentRefinement + 4, nbPages);
  const firstPage = Math.max(lastPage - 6, 1);
  const pageCount = Math.min(nbPages, 7);

  const pages = new Array(pageCount).fill(null).map((_, index) => {
    const page = firstPage + index;
    return (
      <PagButton
        key={index}
        active={currentRefinement === page ? 'active' : null}
        onClick={() => refine(page)}
      >
        {page}
      </PagButton>
    );
  });

  return (
    <Flex p={50} w="full" alignItems="center" justifyContent="center">
      <PagButton
        disabled={currentRefinement === 1}
        onClick={() => refine(currentRefinement - 1)}
      >
        <BackIcon />
      </PagButton>
      {pages}
      <PagButton
        disabled={currentRefinement === nbPages}
        onClick={() => refine(currentRefinement + 1)}
      >
        <ForwardIcon />
      </PagButton>
    </Flex>
  );
};

const PagButton = (props) => {
  const activeStyle = {
    bg: 'brand.500',
    color: 'black',
  };
  return (
    <Button
      mx={1}
      px={4}
      py={2}
      rounded="md"
      opacity={props.disabled && 0.6}
      _hover={!props.disabled && activeStyle}
      cursor={props.disabled && 'not-allowed'}
      onClick={props.disabled ? undefined : props.onClick}
      {...(props.active && activeStyle)}
    >
      {props.children}
    </Button>
  );
};

export const CarPaginator = connectPagination(CustomCarPaginator);
export default CarPaginator;
