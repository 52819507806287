// Lazy load images app-wide
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

/* eslint-disable-next-line */
export interface ImageProps {
  src: string;
  alt: string;
  className?: string;
}

export const Image = (props: ImageProps) => {
  return <LazyLoadImage effect="blur" {...props} />;
};

export default Image;
