import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';
import classNames from 'classnames';

export type InputVariants = 'primary' | 'outline';

interface InputProps {
  icon?: React.ComponentType<React.HTMLAttributes<HTMLDivElement>>;
  onChange?(e: SyntheticEvent): void;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: InputVariants;
  placeholder: string;
  value?: React.ReactText;
  type?: string;
  name?: string;
  disabled?: boolean;
}
interface IconContainerProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

const IconContainer: React.FC<IconContainerProps> = ({
  startIcon,
  endIcon,
}) => {
  const startIconCss = startIcon && 'pl-3';
  const endIconCss = endIcon && 'right-0 pr-4';
  const renderIcon = startIcon || endIcon;
  return (
    <div
      className={classNames(
        'absolute flex inset-y-0 items-center text-2xl text-gray-400',
        startIconCss,
        endIconCss
      )}
    >
      {renderIcon}
    </div>
  );
};

export function Input({
  type = 'text',
  startIcon,
  endIcon,
  variant,
  name,
  ...props
}: InputProps): ReactElement {
  const paddingLeftCss = startIcon ? 'pl-10' : 'pl-4';
  let inputCss =
    'bg-gray-200 border border-gray-200 placeholder-gray-500 text-base text-gray-700 focus:bg-white focus:placeholder-gray-400 focus:shadow-md';

  if (variant === 'outline') {
    inputCss =
      'placeholder-gray-400 text-gray-700 bg-white text-base border border-gray-400 outline-none focus:border-primary-500';
  }

  return (
    <div className="relative w-full">
      {startIcon && <IconContainer startIcon={startIcon} />}
      <input
        className={classNames(
          'p-2 outline-none rounded-md w-full mx-auto block',
          paddingLeftCss,
          inputCss
        )}
        type={type}
        name={name}
        {...props}
      />
      {endIcon && <IconContainer endIcon={endIcon} />}
    </div>
  );
}

export default Input;
