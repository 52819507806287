// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3pV6QVqrIk0TYGGYTZ5JZQ {\n  width: 100%;\n}\n\n._1FjIrW2rRvfNpvlFhMAlmC {\n  -webkit-appearance: none;\n  width: 100%;\n  height: 8px;\n  border-radius: 5px;\n  background: #d3d3d3;\n  outline: none;\n  opacity: 0.7;\n  -webkit-transition: 0.2s;\n  transition: opacity 0.2s;\n}\n\n._1FjIrW2rRvfNpvlFhMAlmC:hover {\n  opacity: 1;\n}\n\n._1FjIrW2rRvfNpvlFhMAlmC::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  background: #3182ce;\n  cursor: pointer;\n}\n\n._1FjIrW2rRvfNpvlFhMAlmC::-moz-range-thumb {\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  background: #3182ce;\n  cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"slidecontainer": "_3pV6QVqrIk0TYGGYTZ5JZQ",
	"range-slider": "_1FjIrW2rRvfNpvlFhMAlmC"
};
module.exports = exports;
