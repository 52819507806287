import React from 'react';
import { submitLoanApplication } from '@du/services';
import LoanApplicationView from './LoanApplicationView';
import { useMutation } from 'react-query';
import { Text, VStack } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import { LoanApplicationResults } from './LoanApplicationResults';
import { formatMoney } from '@driverup/util';
import { useParams, useLocation } from 'react-router-dom';
import { ModalOverlay, Modal, ModalContent } from '@chakra-ui/modal';
import { Button, ModalBody, ModalFooter, ModalHeader } from '@chakra-ui/react';
import * as queryString from 'query-string';

/* eslint-disable-next-line */
export interface LoanApplicationProps {}

function formatLoanAppResponse(loanAppResponse) {
  return {
    defiApplicationDecision: loanAppResponse.defiApplicationDecision,
    contractUrl: loanAppResponse?.contractUrl,
    status: loanAppResponse?.status,
    approvalDetails: [
      {
        label: 'Amount Financed',
        // TODO: Perform all loan money formatting on Server. Client has to see exact terms
        value: formatMoney(loanAppResponse?.amountFinanced),
      },
      {
        label: 'Rate',
        value: `${loanAppResponse?.contractRate}%`, // TODO: Get from?
      },
      {
        label: 'Term',
        value: `${loanAppResponse?.term} months`,
      },
      {
        label: 'Payment',
        value: formatMoney(loanAppResponse?.payment),
      },
    ],
  };
}
type LoanApplicationParams = {
  carId: string;
};
export const LoanApplication: React.FC<LoanApplicationProps> = () => {
  const { carId } = useParams<LoanApplicationParams>();
  const location = useLocation();
  const queryTerms = queryString.parse(location.search);
  const term = Array.isArray(queryTerms.term)
    ? queryTerms.term[0]
    : queryTerms.term;
  const cashdown = Array.isArray(queryTerms.cashdown)
    ? queryTerms.cashdown[0]
    : queryTerms.cashdown;

  const mutation = useMutation((payload) => submitLoanApplication(payload));
  const { isLoading, isError, data: loanAppResponse } = mutation;
  const isModalOpen = isLoading || isError;

  const onClose = () => {
    // clear "isError" state
    mutation.reset();
  };

  if (loanAppResponse) {
    return (
      <LoanApplicationResults data={formatLoanAppResponse(loanAppResponse)} />
    );
  }

  return (
    <div>
      <LoanApplicationView
        carId={carId}
        term={term}
        cashDown={cashdown}
        onSubmit={(payload) => {
          mutation.mutate(payload);
        }}
      />
      <Modal isOpen={isModalOpen} isCentered size="lg" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalBody>
            {isLoading && (
              <VStack>
                <Spinner size="xl" color="blue.500" />
                <Text color="blue.500">Submitting application...</Text>
              </VStack>
            )}
            {isError && (
              <Text color="red.500" align="center">
                Something went wrong...
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            {isError && (
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default LoanApplication;
