// TODO: DELETE MODULE AND ALL ITS TRACES FROM CODEBASE
import { isEmpty, formatCar } from './util';
import {
  LOCAL_STORAGE_DEALER_APP_KEY,
  LOCAL_STORAGE_DEALER_APP_VALUE,
  LOCAL_STORAGE_CAR,
  Car,
} from '@driverup/constants';

export function isDealerApp() {
  return !isEmpty(
    localStorage.getItem(LOCAL_STORAGE_DEALER_APP_KEY) ===
      LOCAL_STORAGE_DEALER_APP_VALUE
  );
}
export function dealerAppSignIn() {
  localStorage.setItem(
    LOCAL_STORAGE_DEALER_APP_KEY,
    LOCAL_STORAGE_DEALER_APP_VALUE
  );
}
export function dealerAppSignOut() {
  localStorage.removeItem(LOCAL_STORAGE_DEALER_APP_KEY);
}

export function getCarFromLocalStorage(): Car {
  const carStr = localStorage.getItem(LOCAL_STORAGE_CAR);
  if (!isEmpty(carStr)) {
    const car: Car = JSON.parse(carStr);
    return car;
  }
}
export function saveCarToLocalStorage(car: Car) {
  localStorage.setItem(LOCAL_STORAGE_CAR, JSON.stringify(car));
}
export function acceptBuyer() {
  const car: Car = getCarFromLocalStorage();
  if (!isEmpty(car)) {
    car.status = 'Accepted';
    saveCarToLocalStorage(car);
  }
}

export function saveSelectedCarInLocalStorage(car: Car) {
  car.status = car.status ? car.status : 'Pending';
  localStorage.setItem(LOCAL_STORAGE_CAR, JSON.stringify(car));
}
